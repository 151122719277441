import { api } from '@/config/apiCliant';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

const getOrgAuth = () =>
  api.get<{ id: number; name: string; logo?: string }>('/org-auth/profile');

export const getOrgAuthQueryOptions = () => {
  const orgToken = Cookies.get('org_token');
  return queryOptions<
    AxiosResponse<{ id: number; name: string; logo?: string }>,
    AxiosError,
    { id: number; name: string; logo?: string }
  >({
    queryKey: ['orgAuthInfo', orgToken],
    queryFn: () => getOrgAuth(),
    select: (res) => res.data,
    enabled: !!orgToken,
  });
};
const useGetOrgAuthQuery = () => {
  const query = useQuery(getOrgAuthQueryOptions());

  useEffect(() => {
    if (query.error?.status === 404 || query.error?.status === 401) {
      Cookies.remove('org_token');
      window.location.reload();
    }
  }, [query.error, query.isError, query.status]);

  return query;
};

export default useGetOrgAuthQuery;
