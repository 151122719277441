import { api } from '@/config/apiCliant';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Payslip } from '../types';
import { PaginationResponse } from '@/modules/user/services/use-get-users.query';

export interface GetPayslipsParams {
  page?: number;
  limit?: number;
  search?: string;
  sort?: string;
  sortBy?: string;
}

const getPayslipsAuthUser = (params: GetPayslipsParams) =>
  api.get<PaginationResponse<Payslip>>('/payslips/me', { params });

const useGetPayslipsAuthUserQuery = (params: GetPayslipsParams) => {
  return useQuery({
    queryKey: ['payslips-auth-user'],
    queryFn: () => getPayslipsAuthUser(params),
    select: (res) => res.data,
    placeholderData: keepPreviousData,
  });
};

export default useGetPayslipsAuthUserQuery;
