import { I18nProvider } from '@react-aria/i18n';
import Calendar from './Calendar';
import { Input } from '@/components';
import { Popover } from '@ark-ui/react';
import { useEffect, useState } from 'react';

function DatePicker({
  onChange,
  onBlur,
  value,
}: {
  value?: Date | null;
  onChange: (value: Date) => void;
  onBlur?: () => void;
}) {
  const [open, setOpen] = useState(false);

  const [localStringDate, setLocalStringDate] = useState<string>('');

  useEffect(() => {
    if (value) {
      setLocalStringDate(value.toLocaleDateString('fa-IR'));
    }
  }, [value]);

  return (
    <Popover.Root
      positioning={{
        offset: { crossAxis: -20, mainAxis: -20 },
      }}
      open={open}
      onOpenChange={({ open }) => setOpen(open)}
    >
      <Popover.Trigger asChild>
        <Input
          className="text-right"
          onBlur={onBlur}
          label="تاریخ تولد"
          readOnly
          value={localStringDate}
        />
      </Popover.Trigger>
      <Popover.Positioner>
        <Popover.Content className="shadow-md rounded-md bg-base-100 p-2">
          <Popover.Arrow>
            <Popover.ArrowTip />
          </Popover.Arrow>
          <I18nProvider locale="fa-IR">
            <Calendar
              value={value}
              onCancel={() => setOpen(false)}
              onConfirm={(value) => {
                onChange(new Date(value.toString()));
                setOpen(false);
              }}
            />
          </I18nProvider>
        </Popover.Content>
      </Popover.Positioner>
    </Popover.Root>
  );
}

export default DatePicker;
