import { api } from '@/config/apiCliant';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { PayslipFieldOrder } from '../types';

const getFieldOrders = () =>
  api.get<PayslipFieldOrder[]>('/payslip-field-orders');

export const getPayslipFieldOrdersOptions = () =>
  queryOptions({
    queryKey: ['payslipFieldOrders'],
    select: (res) => res.data,
    queryFn: () => getFieldOrders(),
  });

const useGetPayslipFieldOrdersQuery = () => {
  return useQuery(getPayslipFieldOrdersOptions());
};

export default useGetPayslipFieldOrdersQuery;
