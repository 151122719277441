import { api } from '@/config/apiCliant';
import { queryClient } from '@/main';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const deletePayslip = (id: number) => api.delete(`/payslips/${id}`);

const useDeletePayslipMutation = () => {
  return useMutation({
    mutationKey: ['delete-payslip'],
    mutationFn: (id: number) => deletePayslip(id),
    onSuccess: () => {
      toast.success('فیش حقوق با موفقیت حذف شد');
      queryClient.refetchQueries({
        queryKey: ['payslips'],
      });
    },
  });
};

export default useDeletePayslipMutation;
