import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const sendOtp = (payload: { mobile: string }) =>
  api.post('/auth/otp-code', payload);

const useSendOtpMutation = () => {
  const { mutate, isPending, error, isSuccess } = useMutation({
    mutationFn: (payload: { mobile: string }) => sendOtp(payload),
    onError: () => {
      toast.error(
        'ارسال کد با مشکل مواجه شد، لطفا از صحت موبایل خود اطمینان داشته باشید.'
      );
    },
  });

  return { mutate, isPending, error, isSuccess };
};

export default useSendOtpMutation;
