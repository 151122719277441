import { createFileRoute, Link } from '@tanstack/react-router';
import {
  MdAddTask,
  MdOutlinePayments,
  MdPayments,
  MdSettings,
} from 'react-icons/md';
import ReactApexChart from 'react-apexcharts';
import { useState } from 'react';
import { ApexOptions } from 'apexcharts';
import { useGetOrganizationUserProfileQuery } from '@/modules/profile';
import {
  useGetPayslipAuthUserCount,
  useGetPayslipCountQuery,
} from '@/modules/payslip/services';
export const Route = createFileRoute('/dashboard/home')({
  component: HomePage,
});

function HomePage() {
  const { data: organizationUserAuth } = useGetOrganizationUserProfileQuery();
  const isAdmin = organizationUserAuth?.role === 'admin';

  const { data: payslipCount } = useGetPayslipCountQuery();
  const { data: payslipAuthUserCount } = useGetPayslipAuthUserCount();

  const [chartData] = useState<{
    series: ApexOptions['series'];
    options: ApexOptions;
  }>({
    series: [
      {
        name: 'پرداختی ها',
        type: 'column',
        data: [
          10000000, 12000000, 15000000, 13000000, 14000000, 16000000, 17000000,
          18000000, 19000000, 20000000, 21000000, 22000000,
        ],
      },
      {
        name: 'خالص پرداختی',
        type: 'column',
        data: [
          9000000, 11000000, 13000000, 12000000, 12500000, 14500000, 15500000,
          16500000, 17500000, 19000000, 20000000, 21000000,
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        fontFamily: 'iranSansX',
      },
      theme: {},
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1, 4],
      },
      title: {
        text: 'خلاصه پرداخت ها',
        align: 'left',
        offsetX: 110,
      },
      xaxis: {
        categories: [
          'فروردین',
          'اردیبهشت',
          'خرداد',
          'تیر',
          'مرداد',
          'شهریور',
          'مهر',
          'آبان',
          'آذر',
          'دی',
          'بهمن',
          'اسفند',
        ],
      },
      yaxis: [
        {
          seriesName: 'پرداختی ها',
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#008FFB',
          },
          labels: {
            style: {
              colors: '#008FFB',
            },
          },

          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: 'خالص پرداختی',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#00E396',
          },
          labels: {
            style: {
              colors: '#00E396',
            },
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
        style: { fontFamily: 'IranSansX' },
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40,
        fontFamily: 'IranSansX',
      },
    },
  });

  return (
    <div className="flex flex-col gap-5">
      {isAdmin && (
        <div className="flex flex-col lg:flex-row gap-5">
          <Link
            to="/dashboard/payslips/new"
            className="card bg-primary text-primary-content  lg:max-w-96 w-full shadow-xl max-w-full"
          >
            <div className="card-body flex justify-between flex-row w-full items-center">
              <span className="flex items-center gap-2  font-bold text-base">
                <MdOutlinePayments size={20} />
                ثبت فیش حقوق
              </span>

              <div className="flex relative ">
                <MdAddTask size={24} />
              </div>
            </div>
          </Link>
          <Link
            to="/dashboard/payslips/settings"
            className="card bg-secondary text-secondary-content  lg:max-w-96 w-full shadow-xl max-w-full"
          >
            <div className="card-body flex justify-between flex-row w-full items-center">
              <span className="flex items-center gap-2  font-bold text-base">
                <MdOutlinePayments size={20} />
                تنظیمات ثبت فیش حقوق
              </span>

              <div className="flex relative ">
                <MdSettings size={24} />
              </div>
            </div>
          </Link>
          <Link
            to="/dashboard/payslips"
            className="card bg-info text-info-content  lg:max-w-96 w-full shadow-xl max-w-full"
          >
            <div className="card-body flex justify-between flex-row w-full items-center">
              <span className="flex items-center gap-2  font-bold text-base">
                <MdOutlinePayments size={20} />
                فیش های حقوق
              </span>

              <div className="flex relative ">
                <MdPayments size={24} />
              </div>
            </div>
          </Link>
        </div>
      )}
      {!isAdmin && (
        <div className="flex justify-center gap-5">
          <Link
            to="/dashboard/payslips"
            search={{ me: true }}
            className="card bg-primary text-primary-content  lg:max-w-96 w-full shadow-xl max-w-full"
          >
            <div className="card-body flex justify-between flex-row w-full items-center">
              <span className="flex items-center gap-2  font-bold text-base">
                <MdOutlinePayments size={20} />
                فیش های حقوق
              </span>

              <div className="flex relative ">
                <MdPayments size={24} />
              </div>
            </div>
          </Link>
        </div>
      )}
      {isAdmin && (
        <div className="stats shadow w-full">
          <div className="stat">
            <div className="stat-figure text-primary">
              <MdPayments size={40} />
            </div>
            <div className="stat-title">تعداد فیش های حقوق ثبت شده کل</div>
            <div className="stat-value text-primary">{payslipCount || 0}</div>
          </div>
        </div>
      )}
      <div className="stats shadow w-full">
        <div className="stat">
          <div className="stat-figure text-primary">
            <MdPayments size={40} />
          </div>
          <div className="stat-title">تعداد فیش های حقوق من</div>
          <div className="stat-value text-primary">
            {payslipAuthUserCount || 0}
          </div>
        </div>
      </div>
      {isAdmin && (
        <div className="relative  top-5">
          <div className="absolute top-0 bottom-0 right-0 left-0  z-10  flex justify-center items-center">
            <span className="text-lg font-bold">به زودی ...</span>
          </div>
          <div className="blur-sm z-10">
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={350}
            />
          </div>
        </div>
      )}
    </div>
  );
}
