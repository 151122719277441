import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FieldType } from '../types';
import { queryClient } from '@/main';
import { getPayslipBaseFieldsOptions } from './use-get-payslip-base-fields.query';
import { getPayslipDeductionFieldsOptions } from './use-get-payslip-deduction-fields.query';
import { getPayslipEarningFieldsOptions } from './use-get-payslip-earning-fields.query';
import { getPayslipFieldOrdersOptions } from './use-get-payslip-field-orders.query';

const deletePayslipField = (id: number, type: FieldType) =>
  api.delete(`/payslip-${type}s/fields/${id}`);

const useDeletePayslipFieldMutation = (type: FieldType) => {
  return useMutation({
    mutationFn: (id: number) => deletePayslipField(id, type),
    mutationKey: ['delete-payslip-field', type],
    onSuccess: () => {
      toast.success('فیلد با موفقیت حذف شد');
      if (type === 'base')
        queryClient.refetchQueries(getPayslipBaseFieldsOptions());
      if (type === 'earning')
        queryClient.refetchQueries(getPayslipEarningFieldsOptions());
      if (type === 'deduction')
        queryClient.refetchQueries(getPayslipDeductionFieldsOptions());
      queryClient.refetchQueries(getPayslipFieldOrdersOptions());
    },
  });
};

export default useDeletePayslipFieldMutation;
