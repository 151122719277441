import { Button, Input } from '@/components';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import { useUpdatePasswordMutation } from '../../services';

const ChangePasswordForm = () => {
  const { mutateAsync: updatePassword } = useUpdatePasswordMutation();

  const form = useForm({
    defaultValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
    validatorAdapter: zodValidator(),
    onSubmit: ({ value }) => {
      return updatePassword(
        {
          newPassword: value.newPassword,
          password: value.password,
        },
        {
          onSuccess: () => form.reset(),
        }
      );
    },
  });
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();

        form.handleSubmit();
      }}
      noValidate
      className="h-full"
    >
      <div className="grid grid-cols-1  lg:grid-cols-2 gap-3">
        <form.Field
          name="password"
          validators={{
            onSubmit: z
              .string()
              .min(1, 'رمز عبور را وارد کنید')
              .min(8, 'رمز عبور باید حداقل 8 کاراکتر باشد'),
          }}
          children={({ state, handleBlur, handleChange }) => (
            <Input
              required
              label="رمز عبور"
              type="password"
              placeholder="رمز عبور "
              value={state.value}
              error={state.meta.errors}
              helperText={state.meta.errors[0]}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={handleBlur}
            />
          )}
        />

        <div className="col-start-1">
          <form.Field
            name="newPassword"
            validators={{
              onSubmit: z
                .string()
                .min(1, 'رمز عبور را وارد کنید')
                .min(8, 'رمز عبور باید حداقل 8 کاراکتر باشد'),
            }}
            children={({ state, handleBlur, handleChange }) => (
              <Input
                required
                label="رمز عبور جدید"
                type="password"
                placeholder="رمز عبور جدید "
                value={state.value}
                error={state.meta.errors}
                helperText={state.meta.errors[0]}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
              />
            )}
          />
        </div>
        <form.Field
          name="confirmPassword"
          validators={{
            onSubmit: z
              .string()
              .min(1, 'تکرار رمز عبور را وارد کنید')
              .min(8, 'تکرار رمز عبور باید حداقل 8 کاراکتر باشد')
              .refine((val) => val === form.getFieldValue('newPassword'), {
                message: 'رمز عبور و تکرار آن برابر نیستند',
              }),
          }}
          children={({ state, handleBlur, handleChange }) => (
            <Input
              required
              label="تکرار رمز عبور جدید"
              type="password"
              placeholder="تکرار رمز عبور جدید "
              value={state.value}
              error={state.meta.errors}
              helperText={state.meta.errors[0]}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={handleBlur}
            />
          )}
        />
      </div>
      <Button
        className="w-28 float-end mt-10"
        loading={form.state.isSubmitting}
        color="primary"
        type="submit"
        disabled={form.state.isSubmitting}
      >
        ویرایش
      </Button>
    </form>
  );
};

export default ChangePasswordForm;
