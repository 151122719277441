import { Button } from '@/components';
import { useState } from 'react';
import { Modal, Range } from 'react-daisyui';
import Cropper, { Area } from 'react-easy-crop';
import getCroppedImg from './utils';
import { IoClose } from 'react-icons/io5';

const CropAvatarDialog = ({
  image,
  onClose,
  open,
  onCrop,
}: {
  image: string;
  onClose: () => void;
  open: boolean;
  onCrop: (imageFile: File) => void;
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const showCroppedImage = async () => {
    try {
      const croppedImage = (await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )) as File;
      if (croppedImage) {
        onCrop(croppedImage);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header className="relative">
        برش تصویر پروفایل
        <form method="dialog">
          <Button
            size="sm"
            color="ghost"
            shape="circle"
            className="absolute left-0 -translate-y-1/2 top-1/2"
            type="submit"
          >
            <IoClose size={20} />
          </Button>
        </form>
      </Modal.Header>
      <Modal.Body className="crop-container">
        <div className="relative h-60 mb-5">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={4 / 4}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="rect"
            rotation={rotation}
          />
        </div>
        <label className="label form-control">
          <span className="label-text">بزرگنمایی</span>
          <Range
            dir="ltr"
            value={zoom}
            onChange={(e) => setZoom(+e.target.value)}
            min={1}
            max={3}
            step={0.1}
            size="sm"
          />
        </label>
        <label className="label form-control">
          <span className="label-text">چرخش</span>
          <Range
            dir="ltr"
            value={rotation}
            onChange={(e) => setRotation(+e.target.value)}
            min={0}
            max={360}
            size="sm"
          />
        </label>
      </Modal.Body>
      <Modal.Actions>
        <Button
          onClick={showCroppedImage}
          color="primary"
          wide
          className="mx-auto"
        >
          تایید
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CropAvatarDialog;
