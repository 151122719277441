import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

const sendResetPasswordOtp = (payload: { mobile: string }) =>
  api.post('/auth/forgot-password', payload);

const useSendResetPasswordOtpMutation = () => {
  const { mutate, isPending, error } = useMutation({
    mutationFn: (payload: { mobile: string }) => sendResetPasswordOtp(payload),
    onError: (err: AxiosError) => {
      if (err.status === 404) {
        toast.error('شماره موبایل وجود ندارد');
      } else {
        toast.error(
          'ارسال کد با مشکل مواجه شد، لطفا از صحت موبایل خود اطمینان داشته باشید.'
        );
      }
    },
  });

  return { mutate, isPending, error };
};

export default useSendResetPasswordOtpMutation;
