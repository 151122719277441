import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export interface UpdatePasswordPayload {
  password: string;
  newPassword: string;
}

const updatePassword = (data: UpdatePasswordPayload) =>
  api.post('/users/changePassword', data);

const useUpdatePasswordMutation = () => {
  return useMutation({
    mutationKey: ['update-password'],
    mutationFn: (payload: UpdatePasswordPayload) => updatePassword(payload),
    onSuccess: () => {
      toast.success('تغییر رمز با موفقیت انجام شد');
    },
    onError: (e: AxiosError<{ message?: string }>) => {
      if (e.response?.data.message === 'Wrong password')
        toast.error('رمز عبور فعلی اشتباه است');
      else toast.error('تغییر رمز با مشکل مواجه شد، لطفا دوباره تلاش کنید');
    },
  });
};

export default useUpdatePasswordMutation;
