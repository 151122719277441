import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

const register = (payload: { mobile: string; password: string }) =>
  api.post('/auth/register', payload);

const useRegisterMutation = () => {
  const { mutate, isPending, error } = useMutation({
    mutationFn: (payload: { mobile: string; password: string }) =>
      register(payload),
    onError: (error: AxiosError) => {
      if (error.status === 422) {
        toast.error('شماره موبایل تکراری است');
      }
    },
  });

  return { mutate, isPending, error };
};

export default useRegisterMutation;
