import { api } from '@/config/apiCliant';
import { Payslip } from '../types';
import { useQuery } from '@tanstack/react-query';

const getPayslip = (id: number) => api.get<Payslip>(`/payslips/${id}`);

const useGetPayslipQuery = (id?: number) => {
  return useQuery({
    queryKey: ['payslip', id],
    queryFn: () => getPayslip(id!),
    enabled: !!id,
    select: (res) => res.data,
  });
};

export default useGetPayslipQuery;
