import { Payslip } from '../../types';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { Button } from '@/components';
import { BsEye, BsTrash } from 'react-icons/bs';
import DeletePayslipPopover from './components/DeletePayslipPopover';
import { Checkbox, CheckboxProps } from 'react-daisyui';
import { useSearch } from '@tanstack/react-router';
import { useGetOrganizationUserProfileQuery } from '@/modules/profile';

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & CheckboxProps) {
  return (
    <Checkbox
      indeterminate={indeterminate && !rest.checked}
      size="sm"
      className={className}
      {...rest}
    />
  );
}

const usePayslipColumns = () => {
  const search = useSearch({ from: '/dashboard/payslips/' });
  const columnHelper = createColumnHelper<Payslip>();
  const { data: user } = useGetOrganizationUserProfileQuery();

  const isAdmin = user?.role === 'admin';
  const [selectedPayslip, setSelectedPayslip] = useState<Payslip>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: 'checkbox',
      cell: ({ row }) =>
        !search.me &&
        isAdmin && (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      header: ({ table }) =>
        !search.me &&
        isAdmin && (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
      enableSorting: false,
    }),

    columnHelper.accessor('user.firstName', {
      id: 'user.firstName',
      header: () => 'نام',
      cell: (info) => info.row.original.user.firstName,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('user.lastName', {
      id: 'user.lastName',
      header: () => 'نام خانوادگی',
      cell: (info) => info.row.original.user.lastName,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('payPeriod.year', {
      id: 'payPeriod.year',
      header: () => <span>سال</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('payPeriod.month', {
      id: 'payPeriod.month',
      header: 'ماه',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('totalDeductions', {
      header: 'کسورات',
      cell: (info) => info.getValue().toLocaleString('fa-IR'),
    }),
    columnHelper.accessor('totalEarnings', {
      header: 'اضافات',
      cell: (info) => info.getValue().toLocaleString('fa-IR'),
    }),
    columnHelper.accessor('baseSalary', {
      header: 'حقوق پایه',
      cell: (info) => info.getValue().toLocaleString('fa-IR'),
    }),

    columnHelper.accessor('netPay', {
      header: 'خالص پرداختی',
      cell: (info) => info.getValue().toLocaleString('fa-IR'),
    }),
    columnHelper.accessor((row) => row.id, {
      enableSorting: false,
      id: 'actions',
      cell: (info) => (
        <>
          <Button
            onClick={() => setSelectedPayslip(info.row.original)}
            shape="circle"
            color="ghost"
            size="xs"
          >
            <BsEye />
          </Button>
          {isAdmin && !search.me && (
            <DeletePayslipPopover id={info.row.original.id}>
              <Button shape="circle" color="ghost" size="xs">
                <BsTrash />
              </Button>
            </DeletePayslipPopover>
          )}
        </>
      ),
      header: () => '',
      footer: (info) => info.column.id,
    }),
  ];

  return {
    columns,
    selectedPayslip,
    setSelectedPayslip,
  };
};

export default usePayslipColumns;
