import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { User } from './use-get-users.query';

export interface createUserPayload {
  firstName: string;
  lastName: string;
  nationalCode: string;
  mobile: string;
  avatarId?: number;
}

const createUser = (payload: createUserPayload) =>
  api.post<{
    message: string;
    data: User;
    status: string;
  }>('/organization-users', payload);

const useCreateUserMutation = () => {
  return useMutation({
    mutationFn: (payload: createUserPayload) => createUser(payload),
    onError: (e: AxiosError<{ message?: string; messages?: string[] }>) => {
      if (e.response?.data.messages) toast.error(e.response?.data.messages[0]);
      else toast.error(e.response?.data?.message || 'مشکلی پیش آمده است');
    },
    onSuccess: () => {
      toast.success('کاربر با موفقیت ایجاد شد');
    },
  });
};

export default useCreateUserMutation;
