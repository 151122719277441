import { Breadcrumbs, Card, Loading } from 'react-daisyui';
import {
  AccountInfoForm,
  ChangePasswordForm,
  OrganizationUserInfoForm,
} from '../features';
import { useGetOrganizationUserProfileQuery } from '../services';

const ProfilePage = () => {
  const { isLoading: isGetORganizationUserProfileLoading } =
    useGetOrganizationUserProfileQuery();

  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item>پروفایل</Breadcrumbs.Item>
      </Breadcrumbs>

      <div className="mt-5 grid grid-cols-1 lg:grid-cols-2 gap-5 items-start">
        <div>
          <Card className="max-w-prose">
            <Card.Body>
              <Card.Title>اطلاعات سازمانی</Card.Title>
              {!isGetORganizationUserProfileLoading ? (
                <OrganizationUserInfoForm />
              ) : (
                <Loading />
              )}
            </Card.Body>
          </Card>
        </div>
        <div className="flex flex-col gap-5">
          <Card className="max-w-prose">
            <Card.Body>
              <Card.Title>اطلاعات حساب کاربری</Card.Title>
              <AccountInfoForm />
            </Card.Body>
          </Card>
          <Card className="max-w-prose">
            <Card.Body>
              <Card.Title>تغییر رمز عبور</Card.Title>
              <ChangePasswordForm />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
