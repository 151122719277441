import { Button } from '@/components';
import { FieldType, PayslipField } from '@/modules/payslip/types';
import { Table } from 'react-daisyui';
import { BiPencil, BiTrash } from 'react-icons/bi';
import DeleteFieldPopover from './DeleteFieldPopover';

const FieldsTable = ({
  fields = [],
  onEdit,
  type,
  loading,
}: {
  fields: Array<PayslipField>;
  onEdit: (field: PayslipField) => void;
  type: FieldType;
  loading?: boolean;
}) => {
  return (
    <Table size="xs" className="table-zebra-zebra">
      <Table.Head>
        <span>شناسه</span>
        <span>عنوان</span>
        <span>عملیات</span>
      </Table.Head>
      <tbody>
        {loading &&
          [1, 2, 3, 4].map((i) => (
            <Table.Row key={`${i}-skeleton-${type}`} className="h-10">
              <span className="skeleton" aria-colspan={2}></span>
              <span className="skeleton"></span>
              <span className="skeleton"></span>
            </Table.Row>
          ))}
        {fields.map((field) => (
          <Table.Row key={field.id}>
            <span>{field.id}</span>
            <span>{field.name}</span>
            <span className="flex gap-2">
              <Button
                onClick={() => onEdit(field)}
                color="success"
                size="sm"
                shape="circle"
              >
                <BiPencil />
              </Button>

              <DeleteFieldPopover type={type} field={field}>
                <Button color="error" size="sm" shape="circle">
                  <BiTrash />
                </Button>
              </DeleteFieldPopover>
            </span>
          </Table.Row>
        ))}
      </tbody>
    </Table>
  );
};

export default FieldsTable;
