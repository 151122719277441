import clsx from 'clsx';

interface SelectProps
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'size'> {
  label?: string;
  topAltLabel?: string;
  downAltLabel?: string;
  helperText?: string;
  error?: boolean;
  options: { value: string | number; label: string }[];
  includeEmptyOption?: boolean;
  bordered?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xs';
}

const Select = (props: SelectProps) => {
  const {
    label,
    topAltLabel,
    downAltLabel,
    helperText,
    error,
    options,
    includeEmptyOption = false,
    bordered = true,
    size,
    ...restProps
  } = props;

  return (
    <div className="form-control ">
      {label && (
        <label className="label">
          <span className="label-text">{label}</span>
          {topAltLabel && <span className="label-text-alt">{topAltLabel}</span>}
        </label>
      )}
      <select
        {...restProps}
        className={clsx('select', {
          'select-error': error,
          'select-neutral': !error,
          'select-bordered': bordered,
          'select-xs': size === 'xs',
          'select-sm': size === 'sm',
          'select-md': size === 'md',
          'select-lg': size === 'lg',
          'select-disabled': restProps.disabled,
        })}
      >
        {includeEmptyOption && <option value="">-- انتخاب کنید --</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {(downAltLabel || helperText) && (
        <label className="label">
          {downAltLabel && (
            <span className="label-text-alt">{downAltLabel}</span>
          )}
          {helperText && (
            <span className={clsx('label-text-alt', { 'text-error': error })}>
              {helperText}
            </span>
          )}
        </label>
      )}
    </div>
  );
};

export default Select;
