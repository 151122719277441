import { Loading } from 'react-daisyui';

const LoadingOverlay = () => {
  return (
    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-info opacity-50">
      <Loading color="secondary" variant="ball" size="lg" />
    </div>
  );
};

export default LoadingOverlay;
