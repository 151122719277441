import { Button, Input } from '@/components';
import { useGetAuthInfoQuery } from '@/modules/auth';
import { useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { ChangeDisplayNameDialog, ChangeMobileDialog } from './components';

const AccountInfoForm = () => {
  const { data: auth } = useGetAuthInfoQuery();

  const [openEditDisplayName, setOpenEditDisplayName] = useState(false);

  const [openEditMobile, setOpenEditMobile] = useState(false);
  return (
    <>
      <ChangeDisplayNameDialog
        open={openEditDisplayName}
        onClose={() => setOpenEditDisplayName(false)}
        defaultValue={auth?.displayName || ''}
      />
      <ChangeMobileDialog
        open={openEditMobile}
        onClose={() => setOpenEditMobile(false)}
        defaultValue={auth?.mobile || ''}
      />
      <Input
        label="نام نمایشی"
        name="displayName"
        value={auth?.displayName || ''}
        disabled
        joinAfter={
          <Button
            onClick={() => setOpenEditDisplayName(true)}
            color="secondary"
          >
            <BiEdit />
          </Button>
        }
      />

      <Input
        label="موبایل"
        name="mobile"
        placeholder="موبایل خود را وارد کنید"
        disabled
        value={auth?.mobile || ''}
        joinAfter={
          <Button onClick={() => setOpenEditMobile(true)} color="secondary">
            <BiEdit />
          </Button>
        }
      />
      {/* TODO: implement this feature in the future when we have email verification*/}
      {/* <form.Field
          name="email"
          children={({ state, handleBlur, handleChange }) => (
            <Input
              label="ایمیل"
              name="email"
              type="email"
              placeholder="ایمیل خود را وارد کنید"
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={handleBlur}
            />
          )}
        /> */}
    </>
  );
};

export default AccountInfoForm;
