import { Breadcrumbs } from 'react-daisyui';
import { PayslipsTable } from '../features';

const PayslipsPage = () => {
  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item>حقوق و دستمزد</Breadcrumbs.Item>
        <Breadcrumbs.Item>فیش های حقوق</Breadcrumbs.Item>
      </Breadcrumbs>
      <PayslipsTable />
    </div>
  );
};

export default PayslipsPage;
