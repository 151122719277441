import { Button, Dialog } from '@/components';
import Select from '@/components/Select';
import { FileUpload } from '@ark-ui/react';
import { Card, Checkbox, Loading } from 'react-daisyui';
import { CgClose } from 'react-icons/cg';
import { PayrollStatementPreviewDialog } from './components';
import { useEffect, useState } from 'react';
import {
  useCreatePayslipsByExcelMutation,
  usePreviewPayslipsMutation,
} from '../../services';
import { months, years } from './constants';
import { toast } from 'react-toastify';
import { useNavigate } from '@tanstack/react-router';
import { useGetOrganizationSettings } from '@/modules/organization-settings';

const currentMonth = new Intl.DateTimeFormat('fa-IR', {
  numberingSystem: 'latn',
  month: 'numeric',
});
const currentYear = new Intl.DateTimeFormat('fa-IR', {
  numberingSystem: 'latn',
  year: 'numeric',
});

const UploadPayslipsData = () => {
  const {
    data: organizationSettings,
    isLoading: isOrganizationSettingsLoading,
  } = useGetOrganizationSettings();

  const [openPreview, setOpenPreview] = useState(false);

  const {
    mutate: previewPayslips,
    isPending: isPreviewPayslipsLoading,
    data: payslipsPreview,
  } = usePreviewPayslipsMutation();
  const {
    mutate: createPayslips,
    isPending: isCreatePayslipsLoading,
    rowsErrors,
  } = useCreatePayslipsByExcelMutation();

  const [showErrorsDialog, setShowErrorsDialog] = useState(false);

  useEffect(() => {
    if (rowsErrors.length) setShowErrorsDialog(true);
  }, [rowsErrors]);

  const [uploadPayslipsConfig, setUploadPayslipsConfig] = useState<{
    preview: boolean;
    sms: boolean;
    updatePayslips: boolean;
    sendSmsUpdatePayslips: boolean;
  }>();

  useEffect(() => {
    if (organizationSettings) {
      setUploadPayslipsConfig({
        preview: organizationSettings.previewMode.value,
        sms: organizationSettings.sendPayslipsSms.value,
        updatePayslips: organizationSettings.updatePayslips.value,
        sendSmsUpdatePayslips: organizationSettings.sendUpdatePayslipsSms.value,
      });
    }
  }, [organizationSettings]);

  const [payPeriod, setPayPeriod] = useState({
    month: +currentMonth.format(new Date()),
    year: +currentYear.format(new Date()),
  });

  const [file, setFile] = useState<File | null>(null);

  const navigate = useNavigate();

  const createPayslipsHandler = () => {
    if (file) {
      createPayslips(
        {
          file,
          month: payPeriod.month,
          year: payPeriod.year,
          sms: uploadPayslipsConfig!.sms,
          updatePayslip: uploadPayslipsConfig!.updatePayslips,
          updateSms: uploadPayslipsConfig?.sendSmsUpdatePayslips,
        },
        {
          onSuccess: () => {
            navigate({ to: '/dashboard/payslips' });
          },
        }
      );
    }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (file) {
      if (uploadPayslipsConfig?.preview) {
        previewPayslips(file, {
          onSuccess: () => setOpenPreview(true),
        });
      } else {
        createPayslipsHandler();
      }
    } else {
      toast.error('لطفا فایل را انتخاب کنید');
    }
  };

  if (isOrganizationSettingsLoading || !uploadPayslipsConfig)
    return <Loading />;

  return (
    <>
      <Dialog
        onClose={() => setShowErrorsDialog(false)}
        open={showErrorsDialog}
        title="خطاهای فایل اکسل"
      >
        <div className="flex gap-3 flex-col">
          {rowsErrors.map(({ index, fields }) => (
            <div key={index} className="flex flex-col ">
              <div className="font-bold text-center mb-1">ردیف {index + 1}</div>
              <div className="flex flex-col gap-1">
                {fields.map((field) => (
                  <div
                    className="alert alert-error p-2 text-xs"
                    key={field.field}
                  >
                    {field.message}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Dialog>
      <PayrollStatementPreviewDialog
        payPeriod={payPeriod}
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        onSubmit={createPayslipsHandler}
        isSubmitting={isCreatePayslipsLoading || isPreviewPayslipsLoading}
        payslips={payslipsPreview?.data || []}
      />

      <form onSubmit={handleSubmit} noValidate>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-10 mt-5">
          <div className="flex flex-wrap gap-5">
            <div className="flex flex-col ">
              <div className="form-control max-w-max">
                <label className="label gap-2">
                  <Checkbox
                    checked={uploadPayslipsConfig.preview}
                    onChange={() =>
                      setUploadPayslipsConfig({
                        ...uploadPayslipsConfig,
                        preview: !uploadPayslipsConfig.preview,
                      })
                    }
                    color="primary"
                  />
                  <span className="label-text">پیش نمایش</span>
                </label>
              </div>
              <p className="text-xs text-gray-500">
                فیش های حقوق بعد از بارگذاری به صورت پیش نمایش نمایش داده می
                شود، در صورت تایید فیش های حقوق ثبت خواهد شد.
              </p>
            </div>
            <div>
              <div className="form-control max-w-max">
                <label className="label gap-2">
                  <Checkbox
                    checked={uploadPayslipsConfig.sms}
                    onChange={(e) => {
                      setUploadPayslipsConfig({
                        ...uploadPayslipsConfig,
                        sms: e.target.checked,
                      });
                    }}
                    color="primary"
                  />
                  <span className="label-text">
                    ارسال پیامک بعد از ثبت فیش حقوق
                  </span>
                </label>
              </div>
              <p className="text-xs text-gray-500">
                بعد از ثبت فیش حقوق برای کاربران مرتبط پیامک ثبت فیش حقوق ارسال
                خواهد شد
              </p>
            </div>
            <div>
              <div className="form-control max-w-max">
                <label className="label gap-2">
                  <Checkbox
                    checked={uploadPayslipsConfig.updatePayslips}
                    onChange={(e) => {
                      setUploadPayslipsConfig({
                        ...uploadPayslipsConfig,
                        updatePayslips: e.target.checked,
                      });
                    }}
                    color="primary"
                  />
                  <span className="label-text">به روزرسانی فیش حقوق</span>
                </label>
              </div>
              <p className="text-xs text-gray-500">
                در صورتی که فیش حقوق کاربری برای این تاریخ (ماه و سال) ثبت شده
                باشد، اطلاعات جدید جایگزین خواهد شد.
              </p>
            </div>
            <div>
              <div className="form-control max-w-max">
                <label className="label gap-2">
                  <Checkbox
                    disabled={!uploadPayslipsConfig.updatePayslips}
                    checked={uploadPayslipsConfig.sendSmsUpdatePayslips}
                    onChange={(e) => {
                      setUploadPayslipsConfig({
                        ...uploadPayslipsConfig,
                        sendSmsUpdatePayslips: e.target.checked,
                      });
                    }}
                    color="primary"
                  />
                  <span className="label-text">
                    ارسال پیامک بعد از به روز رسانی فیش حقوق
                  </span>
                </label>
              </div>
              <p className="text-xs text-gray-500">
                بعد از ثبت فیش حقوق برای کاربران مرتبط پیامک ثبت فیش حقوق ارسال
                خواهد شد
              </p>
            </div>
          </div>
          <Card compact className="col-span-1 shadow-xl auto-rows-min">
            <Card.Body>
              <Card.Title tag={'h5'} className="text-sm">
                بارگذاری فایل
              </Card.Title>
              <div className="grid grid-cols-2 mt-5 gap-5  ">
                <Select
                  label="سال"
                  options={years}
                  value={payPeriod.year}
                  onChange={(e) => {
                    setPayPeriod({
                      ...payPeriod,
                      year: +e.target.value,
                    });
                  }}
                />
                <Select
                  label="ماه"
                  options={months}
                  value={payPeriod.month}
                  onChange={(e) => {
                    setPayPeriod({
                      ...payPeriod,
                      month: +e.target.value,
                    });
                  }}
                />
              </div>
              <div className="w-full  flex items-center justify-center min-h-56 ">
                <FileUpload.Root
                  onFileAccept={(details) => setFile(details.files[0])}
                  accept={['.xlsx', '.xls']}
                  maxFiles={1}
                  className="w-full h-full relative flex flex-col items-center justify-center gap-2"
                >
                  <FileUpload.Dropzone className="absolute top-0 left-0 w-full h-full border-2 border-dashed flex items-center flex-col justify-center gap-5 rounded-lg "></FileUpload.Dropzone>
                  <FileUpload.Trigger className="flex flex-col gap-3" asChild>
                    <>
                      <span>فایل را اینجا بکشید</span>

                      <Button color="primary">بارگذاری فایل</Button>
                    </>
                  </FileUpload.Trigger>
                  <FileUpload.ItemGroup className="z-10">
                    <FileUpload.Context>
                      {({ acceptedFiles }) =>
                        acceptedFiles.map((file) => (
                          <FileUpload.Item
                            key={file.name}
                            file={file}
                            className="flex  gap-2"
                          >
                            <FileUpload.ItemDeleteTrigger asChild>
                              <Button shape="circle" size="xs" color="error">
                                <CgClose />
                              </Button>
                            </FileUpload.ItemDeleteTrigger>
                            <FileUpload.ItemName />
                            <FileUpload.ItemSizeText />
                          </FileUpload.Item>
                        ))
                      }
                    </FileUpload.Context>
                  </FileUpload.ItemGroup>
                  <FileUpload.HiddenInput />
                </FileUpload.Root>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="flex justify-end gap-5">
          <Button
            type="button"
            onClick={() => navigate({ to: '/dashboard/payslips' })}
          >
            انصراف
          </Button>
          <Button
            color="primary"
            type="submit"
            loading={isCreatePayslipsLoading || isPreviewPayslipsLoading}
            disabled={
              !file || isCreatePayslipsLoading || isPreviewPayslipsLoading
            }
          >
            {uploadPayslipsConfig.preview ? 'پیش نمایش' : 'ثبت اطلاعات'}
          </Button>
        </div>
      </form>
    </>
  );
};

export default UploadPayslipsData;
