import { Breadcrumbs, Card } from 'react-daisyui';
import OrganizationProfileFeat from '../features/OrganizationProfile';

const OrganizationProfilePage = () => {
  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item>پروفایل سازمان</Breadcrumbs.Item>
      </Breadcrumbs>

      <Card className="mt-5">
        <Card.Body>
          <Card.Title>اطلاعات عمومی سازمان</Card.Title>

          <div className="mt-5">
            <OrganizationProfileFeat />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default OrganizationProfilePage;
