import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

const changeAuthMobile = (mobile: string) =>
  api.post('/auth/profile/mobile', { mobile });

const useChangeAuthMobileMutation = () => {
  return useMutation({
    mutationKey: ['change-auth-mobile'],
    mutationFn: (mobile: string) => changeAuthMobile(mobile),
    onSuccess: () => {
      toast.success('کد تایید به موبایل شما ارسال شد');
    },
    onError: (e: AxiosError<{ status: string; message: string }>) => {
      if (e.response?.data.status === 'conflict')
        toast.error('شماره موبایل تکراری است');
      else toast.error('ویرایش موبایل با مشکل مواجه شد');
    },
  });
};

export default useChangeAuthMobileMutation;
