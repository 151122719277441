import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const verifyRegisterOtp = (payload: {
  verificationCode: string;
  mobile: string;
}) => api.post('/auth/verify', payload);

const useVerifyRegisterOtpMutation = () => {
  const navigate = useNavigate();
  const { mutate, isPending, error } = useMutation({
    mutationFn: (payload: { verificationCode: string; mobile: string }) =>
      verifyRegisterOtp(payload),
    onSuccess: (res) => {
      const accessToken = res.data.data.accessToken;
      Cookies.set('token', accessToken);
      navigate({ to: '/home' });
    },
    onError: (error: AxiosError<{ status: string }>) => {
      if (error.response?.data.status === 'wrong_code') {
        toast.error('کد وارد شده اشتباه است');
      }
    },
  });

  return { mutate, isPending, error };
};

export default useVerifyRegisterOtpMutation;
