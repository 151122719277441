import { Timer } from '@ark-ui/react';

export interface CountdownProps {
  time?: number;
  start?: boolean;
  onEnd?: () => void;
  onTick?: () => void;
}

const Countdown = ({ onEnd, onTick, start, time = 180000 }: CountdownProps) => {
  return (
    <Timer.Root
      countdown
      autoStart={start}
      startMs={time}
      onComplete={onEnd}
      onTick={onTick}
    >
      <Timer.Area
        dir="rtl"
        className="text-xs w-full text-center justify-center mt-5 flex"
      >
        <Timer.Item type="seconds" />
        <Timer.Separator>:</Timer.Separator>
        <Timer.Item type="minutes" />
        <span className="mx-2"> مانده تا دریافت مجدد کد</span>
      </Timer.Area>
    </Timer.Root>
  );
};

export default Countdown;
