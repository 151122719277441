import { Button, Table } from '@/components';
import {
  PaginationState,
  RowSelectionState,
  SortingState,
} from '@tanstack/react-table';
import { BiPlus, BiTrash } from 'react-icons/bi';

import {
  useDeletePayslipsMutation,
  useGetPayslipAuhUserQuery,
  useGetPayslipQuery,
  useGetPayslipsAuthUserQuery,
  useGetPayslipsQuery,
} from '../../services';
import { useState } from 'react';
import usePayslipColumns from './use-payslip-columns';
import { useSearch } from '@tanstack/react-router';
import { PayrollDialog } from './components';
import { useGetOrganizationUserProfileQuery } from '@/modules/profile';

const PayslipsTable = () => {
  const urlSearch = useSearch({ from: '/dashboard/payslips/' });
  const { data: user } = useGetOrganizationUserProfileQuery();
  const isAdmin = user?.role === 'admin';
  const { mutateAsync: deletePayslips } = useDeletePayslipsMutation();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: 'id' },
  ]);

  const {
    data: payslips,
    isFetching,
    isPlaceholderData,
    isPending,
  } = useGetPayslipsQuery(
    {
      limit: pagination.pageSize,
      page: pagination.pageIndex + 1,
      search,
      sort: sorting[0]?.desc ? 'desc' : 'asc',
      sortBy: sorting[0]?.id,
    },
    { enabled: !urlSearch.me }
  );
  const {
    data: payslipsAuthUser,
    isLoading: isPayslipsAuthUserLoading,
    isFetching: isPayslipsAuthUserFetching,
    isPlaceholderData: isPayslipsAuthUserPlaceholderData,
  } = useGetPayslipsAuthUserQuery({
    limit: pagination.pageSize,
    page: pagination.pageIndex + 1,
    search,
    sort: sorting[0]?.desc ? 'desc' : 'asc',
    sortBy: sorting[0]?.id,
  });

  const { columns, selectedPayslip, setSelectedPayslip } = usePayslipColumns();

  const { data: payslip } = useGetPayslipQuery(
    urlSearch.me ? undefined : selectedPayslip?.id
  );

  const { data: payslipAuthUser } = useGetPayslipAuhUserQuery(
    urlSearch.me ? selectedPayslip?.id : undefined
  );

  const [selectedPayslips, setSelectedPayslips] = useState<RowSelectionState>(
    {}
  );

  return (
    <>
      <PayrollDialog
        onClose={() => setSelectedPayslip(undefined)}
        open={!!selectedPayslip}
        user={selectedPayslip?.user || { firstName: '', lastName: '' }}
        payslip={urlSearch.me ? payslipAuthUser : payslip}
      />
      <Table
        enableRowSelection
        rowSelection={selectedPayslips}
        onRowSelectionChange={setSelectedPayslips}
        columnVisibility={{
          'user.firstName': !urlSearch.me,
          'user.lastName': !urlSearch.me,
        }}
        selectedActions={[
          {
            label: 'حذف',
            icon: <BiTrash />,
            onClick: () => {},
            confirmPopover: true,
            confirmPopoverProps: {
              onConfirm: () => {
                const indexes = Object.keys(selectedPayslips);
                const ids = payslips?.data
                  .filter((_, i) => indexes.includes(i.toString()))
                  ?.map((p) => p.id);
                if (!ids) return;
                return deletePayslips(ids, {
                  onSuccess: () => {
                    setSelectedPayslips({});
                  },
                });
              },
            },
          },
        ]}
        columns={columns}
        data={
          urlSearch.me ? payslipsAuthUser?.data || [] : payslips?.data || []
        }
        pagination={pagination}
        total={payslips?.meta.total}
        onPaginationChange={setPagination}
        onSearch={setSearch}
        sorting={sorting}
        onChangeSorting={setSorting}
        loading={
          !urlSearch.me
            ? isPending || (isFetching && isPlaceholderData)
            : isPayslipsAuthUserLoading ||
              (isPayslipsAuthUserFetching && isPayslipsAuthUserPlaceholderData)
        }
        actions={
          !urlSearch.me &&
          isAdmin && (
            <Button to="/dashboard/payslips/new" size="sm">
              ثبت فیش حقوق <BiPlus />
            </Button>
          )
        }
      />
    </>
  );
};

export default PayslipsTable;
