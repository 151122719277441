import { useRef } from 'react';
import { AriaCalendarCellProps, useCalendarCell } from '@react-aria/calendar';
import { mergeProps } from '@react-aria/utils';
import { useFocusRing } from '@react-aria/focus';
import styles from '../styles.module.css';
import clsx from 'clsx';
import { CalendarState } from '@react-stately/calendar';

function CalendarCell(props: AriaCalendarCellProps & { state: CalendarState }) {
  const ref = useRef<HTMLDivElement | null>(null);
  const {
    cellProps,
    buttonProps,
    formattedDate,
    isSelected,
    isDisabled,
    isOutsideVisibleRange,
  } = useCalendarCell(props, props.state, ref);

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <td {...cellProps}>
      <div
        ref={ref}
        hidden={isOutsideVisibleRange}
        {...mergeProps(buttonProps, focusProps)}
        className={clsx('cursor-pointer m-1 p-1 text-center rounded-md', {
          'bg-gray-300': isSelected,
          [styles.focusRing]: isFocusVisible,
          [styles.disabled]: isDisabled,
        })}
      >
        {formattedDate}
      </div>
    </td>
  );
}

export default CalendarCell;
