import { api } from '@/config/apiCliant';
import { queryClient } from '@/main';
import { getAuthInfoQueryOptions } from '@/modules/auth/services/use-get-auth-info.query';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const updateAuthDisplayName = async (displayName: string) =>
  api.patch('/auth/profile', { displayName });

const useUpdateAuthDisplayNameMutation = () => {
  return useMutation({
    mutationKey: ['update-auth-displayname'],
    mutationFn: (displayName: string) => updateAuthDisplayName(displayName),
    onSuccess: () => {
      toast.success('ویرایش با موفقیت انجام شد');
      queryClient.refetchQueries(getAuthInfoQueryOptions());
    },
    onError: () => {
      toast.error('ویرایش با مشکل مواجه شد');
    },
  });
};

export default useUpdateAuthDisplayNameMutation;
