import { Button } from '@/components';
import { FieldFormDialog, FieldsTable } from './components';
import { useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import {
  useGetPayslipBaseFieldsQuery,
  useGetPayslipDeductionFieldsQuery,
  useGetPayslipEarningFieldsQuery,
} from '../../services';
import { FieldType, PayslipField } from '../../types';

const PayslipFields = () => {
  const [openFieldFormDialog, setOpenFieldFormDialog] = useState(false);
  const [fieldFormMode, setFieldFormMode] = useState<'create' | 'update'>();
  const [selectedField, setSelectedField] = useState<PayslipField>();
  const [fieldType, setFieldType] = useState<FieldType>();

  const { data: baseFields, isLoading: isBaseFieldsLoading } =
    useGetPayslipBaseFieldsQuery();
  const { data: deductionFields, isLoading: isDeductionFieldsLoading } =
    useGetPayslipDeductionFieldsQuery();
  const { data: earningFields, isLoading: isEarningFieldsLoading } =
    useGetPayslipEarningFieldsQuery();

  const createHandler = (type: FieldType) => {
    setFieldType(type);
    setFieldFormMode('create');
    setOpenFieldFormDialog(true);
  };

  const editHandler = (field: PayslipField, type: FieldType) => {
    setFieldType(type);
    setFieldFormMode('update');
    setSelectedField(field);
    setOpenFieldFormDialog(true);
  };

  return (
    <>
      {fieldType && (
        <FieldFormDialog
          mode={fieldFormMode}
          field={selectedField}
          open={openFieldFormDialog}
          onClose={() => setOpenFieldFormDialog(false)}
          type={fieldType}
        />
      )}
      <div className="flex flex-col gap-5">
        <div
          tabIndex={0}
          className={
            'collapse collapse-arrow border-base-300 bg-base-200 border'
          }
        >
          <input type="checkbox" />
          <div className="collapse-title text-xl font-medium">اطلاعات پایه</div>
          <div className="collapse-content">
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm text-gray-500 mt-3">
                این فیلد برای اطلاعات پایه فیش حقوق به صورت منحصربه‌فرد می‌باشد
              </p>
              <Button
                color="secondary"
                shape="circle"
                variant="outline"
                size="sm"
                onClick={() => createHandler('base')}
              >
                <BiPlus className="text-2xl" />
              </Button>
            </div>
            <FieldsTable
              loading={isBaseFieldsLoading}
              fields={baseFields || []}
              onEdit={(field) => editHandler(field, 'base')}
              type="base"
            />
          </div>
        </div>
        <div
          tabIndex={0}
          className={
            'collapse collapse-arrow border-base-300 bg-base-200 border'
          }
        >
          <input type="checkbox" />
          <div className="collapse-title text-xl font-medium">اضافات</div>
          <div className="collapse-content">
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-500 mt-3">
                این فیلد برای اضافات فیش حقوق به صورت منحصربه‌فرد می‌باشد
              </p>
              <Button
                color="secondary"
                shape="circle"
                variant="outline"
                size="sm"
                onClick={() => createHandler('earning')}
              >
                <BiPlus className="text-2xl" />
              </Button>
            </div>

            <FieldsTable
              loading={isEarningFieldsLoading}
              fields={earningFields || []}
              onEdit={(field) => {
                setFieldType('earning');
                setFieldFormMode('update');
                setSelectedField(field);
                setOpenFieldFormDialog(true);
              }}
              type="earning"
            />
          </div>
        </div>
        <div
          tabIndex={0}
          className={
            'collapse collapse-arrow border-base-300 bg-base-200 border'
          }
        >
          <input type="checkbox" />
          <div className="collapse-title text-xl font-medium">کسورات</div>
          <div className="collapse-content">
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-500 mt-3">
                این فیلد برای اضافات فیش حقوق به صورت منحصربه‌فرد می‌باشد
              </p>
              <Button
                color="secondary"
                shape="circle"
                variant="outline"
                size="sm"
                onClick={() => createHandler('deduction')}
              >
                <BiPlus className="text-2xl" />
              </Button>
            </div>

            <FieldsTable
              loading={isDeductionFieldsLoading}
              fields={deductionFields || []}
              onEdit={(field) => editHandler(field, 'deduction')}
              type="deduction"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PayslipFields;
