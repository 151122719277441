import { Breadcrumbs, Card } from 'react-daisyui';
import { UserForm } from '../features';
import { Link } from '@tanstack/react-router';

const CreateUserPage = () => {
  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item>
          <Link to="/dashboard/users">کاربران</Link>
        </Breadcrumbs.Item>
        <Breadcrumbs.Item>ثبت کاربر</Breadcrumbs.Item>
      </Breadcrumbs>
      <div className="container ">
        <Card className="w-full lg:w-2/3  mx-auto">
          <Card.Body>
            <Card.Title>ثبت کاربر</Card.Title>
            <UserForm />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default CreateUserPage;
