import { api } from '@/config/apiCliant';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { PayslipField } from '../types';

const getPayslipDeductionFields = () =>
  api.get<PayslipField[]>('/payslip-deductions/fields');

export const getPayslipDeductionFieldsOptions = () =>
  queryOptions({
    queryKey: ['payslipDeductionFields'],
    queryFn: () => getPayslipDeductionFields(),
    select: (res) => res.data,
  });

const useGetPayslipDeductionFieldsQuery = () => {
  return useQuery(getPayslipDeductionFieldsOptions());
};

export default useGetPayslipDeductionFieldsQuery;
