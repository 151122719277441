import { Button, Input } from '@/components';
import {
  useCreatePayslipFieldMutation,
  useUpdatePayslipFieldMutation,
} from '@/modules/payslip/services';
import { FieldType, PayslipField } from '@/modules/payslip/types';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { useMemo } from 'react';
import { Modal } from 'react-daisyui';
import { IoClose } from 'react-icons/io5';
import { z } from 'zod';

const FieldFormDialog = ({
  onClose,
  open,
  type,
  mode = 'create',
  field,
}: {
  open: boolean;
  onClose: () => void;
  type: FieldType;
  mode?: 'create' | 'update';
  field?: PayslipField;
}) => {
  const { mutate: createPayslipField, isPending: isCreatePayslipFieldLoading } =
    useCreatePayslipFieldMutation(type);
  const { mutate: updatePayslipField, isPending: isUpdatePayslipFieldLoading } =
    useUpdatePayslipFieldMutation(type, field?.id || 0);

  const form = useForm({
    defaultValues: {
      name: mode === 'update' && field ? field.name : '',
    },
    validatorAdapter: zodValidator(),
    onSubmit: ({ value }) => {
      if (mode === 'update')
        updatePayslipField(value, { onSuccess: successHandler });
      else {
        createPayslipField(value, {
          onSuccess: successHandler,
        });
      }
    },
  });

  const successHandler = () => {
    form.reset();
    onClose();
  };

  const fieldType = useMemo(() => {
    switch (type) {
      case 'earning':
        return 'اضافات';
      case 'deduction':
        return 'کسورات';
      case 'base':
        return 'اطلاعات پایه';
      default:
        return '';
    }
  }, [type]);

  return (
    <Modal open={open} onClose={onClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <Modal.Header className="relative">
          <h3 className="font-bold text-lg">
            {mode === 'create' ? 'افزودن' : 'ویرایش'} فیلد {fieldType}
          </h3>
          <div className="absolute left-0 -translate-y-1/2 top-1/2">
            <Button onClick={onClose} size="sm" color="ghost" shape="circle">
              <IoClose size={20} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form.Field
            name="name"
            validators={{
              onSubmit: z
                .string()
                .min(1, 'نام فیلد را وارد کنید')
                .min(3, 'نام فیلد باید حداقل 3 کاراکتر باشد'),
            }}
            children={({ state, handleBlur, handleChange }) => (
              <Input
                label="نام فیلد"
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
                error={!!state.meta.errors.length}
                helperText={state.meta.errors[0]}
              />
            )}
          />
        </Modal.Body>
        <Modal.Actions className="gap-5">
          <Button onClick={onClose}>انصراف</Button>
          <Button
            loading={isCreatePayslipFieldLoading || isUpdatePayslipFieldLoading}
            disabled={
              isCreatePayslipFieldLoading || isUpdatePayslipFieldLoading
            }
            color="primary"
            type="submit"
          >
            {mode === 'create' ? 'افزودن' : 'ویرایش'}
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};

export default FieldFormDialog;
