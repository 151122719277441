import { api } from '@/config/apiCliant';
import { queryClient } from '@/main';
import { getAuthInfoQueryOptions } from '@/modules/auth/services/use-get-auth-info.query';
import { getOrgAuthQueryOptions } from '@/modules/auth/services/use-get-org-auth.query';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const updateOrganizationLogo = (payload: { imageId: number }) =>
  api.patch('/organization/logo', payload);

const useUpdateOrganizationLogoMutation = () => {
  return useMutation({
    mutationKey: ['update-organization-logo'],
    mutationFn: (payload: { imageId: number }) =>
      updateOrganizationLogo(payload),
    onSuccess: () => {
      toast.success('ویرایش با موفقیت انجام شد');
      queryClient.refetchQueries(getOrgAuthQueryOptions());
      queryClient.refetchQueries(getAuthInfoQueryOptions());
    },
    onError: () => {
      toast.error('ویرایش با مشکل مواجه شد');
    },
  });
};

export default useUpdateOrganizationLogoMutation;
