import { ThemContext } from '@/theme';
import {
  Outlet,
  createRootRouteWithContext,
  useRouterState,
} from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient } from '@tanstack/react-query';
import { Progress } from 'react-daisyui';

function RouterSpinner() {
  const isLoading = useRouterState({ select: (s) => s.status === 'pending' });
  return (
    isLoading && (
      <Progress
        className="absolute top-0 left-0 right-0 z-50 h-1"
        color="primary"
      ></Progress>
    )
  );
}

export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()(
  {
    component: ROOT,
  }
);

function ROOT() {
  const { theme } = useContext(ThemContext);
  return (
    <>
      {import.meta.env.DEV && <TanStackRouterDevtools />}
      <ToastContainer
        position="bottom-right"
        rtl
        toastStyle={{
          fontFamily: 'IranSansX',
        }}
        draggable
        theme={theme === 'dark' ? 'dark' : 'light'}
      />
      <RouterSpinner />
      <Outlet />
    </>
  );
}
