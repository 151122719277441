import Cookies from 'js-cookie';
import { createContext, useEffect, useState } from 'react';

const ThemContext = createContext<{
  theme: 'winter' | 'dark';
  toggleTheme: () => void;
}>({
  theme: 'winter',
  toggleTheme: () => {},
});

const ThemeProvider = ({ children }: React.PropsWithChildren) => {
  const [theme, setTheme] = useState<'winter' | 'dark'>('winter');

  useEffect(() => {
    const theme = Cookies.get('theme') as 'winter' | 'dark';

    if (theme) {
      document.documentElement.setAttribute('data-theme', theme);
      setTheme(theme);
    }
  }, []);

  const toggleTheme = () => {
    document.documentElement.setAttribute(
      'data-theme',
      theme === 'winter' ? 'dark' : 'winter'
    );

    setTheme(theme === 'winter' ? 'dark' : 'winter');
    Cookies.set('theme', theme === 'winter' ? 'dark' : 'winter');
  };

  return (
    <ThemContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemContext.Provider>
  );
};

export { ThemContext, ThemeProvider };
