import { Button, Input } from '@/components';
import { isCreateOrganizationDisable } from '@/env';
import { useRegisterOrganizationMutation } from '@/modules/organization';
import { useForm } from '@tanstack/react-form';
import {
  createFileRoute,
  Link,
  redirect,
  useNavigate,
} from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-form-adapter';
import Cookies from 'js-cookie';
import { Breadcrumbs, Card } from 'react-daisyui';
import { BiChevronRight } from 'react-icons/bi';
import { z } from 'zod';

export const Route = createFileRoute('/home/register-organization')({
  component: NewOrganization,
  beforeLoad: () => {
    if (!Cookies.get('token')) {
      return redirect({ to: '/auth/login' });
    }

    if (isCreateOrganizationDisable) {
      return redirect({ to: '/home' });
    }
  },
});

function NewOrganization() {
  const navigate = useNavigate();
  const {
    mutate: registerNewOrganization,
    isPending: isPendingRegisterNewOrganization,
  } = useRegisterOrganizationMutation();
  const form = useForm({
    defaultValues: {
      name: '',
    },
    validatorAdapter: zodValidator(),
    onSubmit: ({ value }) => {
      registerNewOrganization(value.name, {
        onSuccess: () => {
          form.reset();
          navigate({ to: '/home' });
        },
      });
    },
  });
  return (
    <div>
      <Breadcrumbs>
        <Link className="btn btn-ghost btn-circle ml-2" to="/home">
          <BiChevronRight size={24} />
        </Link>
        <Breadcrumbs.Item>
          <Link to="/home">خانه</Link>
        </Breadcrumbs.Item>
        <Breadcrumbs.Item>ثبت سازمان</Breadcrumbs.Item>
      </Breadcrumbs>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <Card className="shadow-md">
          <Card.Body>
            <Card.Title>ثبت سازمان</Card.Title>
            <form.Field
              name="name"
              validators={{
                onSubmit: z
                  .string()
                  .min(1, 'نام سازمان را وارد کنید')
                  .min(3, 'نام سازمان باید حداقل 3 کاراکتر باشد'),
              }}
              children={({ state, handleBlur, handleChange }) => (
                <Input
                  type="text"
                  placeholder="نام سازمان"
                  name="name"
                  value={state.value}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  error={state.meta.errors}
                  helperText={state.meta.errors?.[0]}
                />
              )}
            />

            <Card.Actions className="w-full flex justify-end mt-5">
              <Button
                loading={isPendingRegisterNewOrganization}
                disabled={isPendingRegisterNewOrganization}
                color="primary"
                className="float-end"
                type="submit"
                wide
              >
                ثبت
              </Button>
            </Card.Actions>
          </Card.Body>
        </Card>
      </form>
    </div>
  );
}
