import { Button, Input } from '@/components';
import { useUpdateAuthDisplayNameMutation } from '@/modules/profile/services';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { Modal } from 'react-daisyui';
import { z } from 'zod';

const ChangeDisplayNameDialog = ({
  open,
  onClose,
  defaultValue,
}: {
  open: boolean;
  onClose: () => void;
  defaultValue: string;
}) => {
  const { mutateAsync: updateAuthDisplayName } =
    useUpdateAuthDisplayNameMutation();

  const form = useForm({
    defaultValues: {
      displayName: defaultValue,
    },
    validatorAdapter: zodValidator({
      transformErrors: (errors) => errors[0].message,
    }),
    onSubmit: ({ value }) => {
      return updateAuthDisplayName(value.displayName, {
        onSuccess: () => {
          form.reset();
          onClose();
        },
      });
    },
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <Modal.Header> تغییر نام نمایشی</Modal.Header>
          <Modal.Body>
            <form.Field
              name="displayName"
              validators={{
                onSubmit: z
                  .string()
                  .min(3, 'نام نمایشی نباید کمتر از 3 کاراکتر باشد'),
              }}
              children={({ state, handleBlur, handleChange }) => (
                <Input
                  label="نام نمایشی"
                  name="displayName"
                  value={state.value}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  error={state.meta.errors}
                  helperText={state.meta.errors[0]}
                  placeholder="نام خود را وارد کنید"
                />
              )}
            />
          </Modal.Body>
          <Modal.Actions className="gap-2">
            <Button color="ghost" onClick={onClose}>
              انصراف
            </Button>
            <Button
              loading={form.state.isSubmitting}
              disabled={form.state.isSubmitting}
              type="submit"
              color="primary"
            >
              ویرایش
            </Button>
          </Modal.Actions>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeDisplayNameDialog;
