import { Button } from '@/components';
import { isCreateOrganizationDisable } from '@/env';
import { useGetAuthInfoQuery, useSignToOrganizationMutation } from '@/modules';
import { getAuthInfoQueryOptions } from '@/modules/auth/services/use-get-auth-info.query';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';
import { Card } from 'react-daisyui';
import { BiPlus } from 'react-icons/bi';
import { FcOrganization } from 'react-icons/fc';

export const Route = createFileRoute('/home/')({
  component: Home,
  loader: ({ context }) => {
    context.queryClient.ensureQueryData(getAuthInfoQueryOptions());
  },
});

function Home() {
  const navigate = useNavigate();
  const { data: user } = useGetAuthInfoQuery();
  const { mutate: signToOrganization } = useSignToOrganizationMutation();

  const singIntoOrganizationHandler = (organizationId: number) => {
    signToOrganization(organizationId, {
      onSuccess: () => {
        navigate({ to: '/dashboard/home' });
      },
    });
  };
  return (
    <div>
      <Card className="shadow-md ">
        <Card.Body>
          <Card.Title className="grid grid-cols-1 sm:grid-cols-2 justify-between items-center font-bold text-2xl">
            <span>سازمان ها</span>
            {!isCreateOrganizationDisable && (
              <Link
                to="/home/register-organization"
                className="btn btn-primary sm:btn-wide  justify-self-end btn-block"
              >
                ساخت سازمان جدید <BiPlus size={20} />
              </Link>
            )}
          </Card.Title>
          <div className="mt-5 flex flex-col gap-10">
            {user?.userOrganizations.map((organization) => (
              <Card className="shadow-xl">
                <Card.Body>
                  <Card.Title>{organization.organization.name}</Card.Title>

                  <div className="grid grid-cols-1 sm:grid-cols-2 justify-center  sm:justify-between sm:items-center  my-5 gap-5 ">
                    <div className="w-24 h-24 border-2 rounded-full p-3 mx-auto sm:mx-0">
                      {organization.organization.logo ? (
                        <img
                          className="w-full h-full rounded-full"
                          src={`${import.meta.env.VITE_API_BASE_URL}/${organization.organization.logo}`}
                        />
                      ) : (
                        <FcOrganization className="w-full h-full" />
                      )}
                    </div>

                    <Button
                      onClick={() =>
                        singIntoOrganizationHandler(
                          organization.organization.id
                        )
                      }
                      className="sm:btn-wide btn-block sm:justify-self-end"
                      color="primary"
                      variant="outline"
                    >
                      ورود
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
