import { api } from '@/config/apiCliant';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { User } from './use-get-users.query';

export interface UpdateUserPayload {
  firstName?: string;
  lastName?: string;
  nationalCode?: string;
  mobile?: string;
  fatherName?: string | null;
  birthDate?: Date | null;
  insuranceNumber?: string | null;
  avatarId?: number | null;
  personnelCode?: string | null;
  gender?: 'male' | 'female' | null;
  childrenCount?: number | null;
  maritalStatus?: 'single' | 'married' | null;
}

const updateUser = (id: number, payload: UpdateUserPayload) =>
  api.patch<User>(`/organization-users/${id}`, payload);

const useUpdateUser = (id?: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['update-user', id],
    mutationFn: (payload: UpdateUserPayload) => updateUser(id!, payload),
    onSuccess: (res) => {
      queryClient.setQueriesData({ queryKey: ['user', id] }, () => res);
      toast.success('کاربر با موفقیت ویرایش شد');
    },
    onError: (e: AxiosError<{ message?: string; messages?: string[] }>) => {
      if (e.response?.data.messages) toast.error(e.response?.data.messages[0]);
      else toast.error('ویرایش کاربر با مشکل مواجه شد');
    },
  });
};

export default useUpdateUser;
