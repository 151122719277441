import { api } from '@/config/apiCliant';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

const registerNewOrganization = (name: string) =>
  api.post('/organizations/register', { name });

const useRegisterOrganizationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, string>({
    mutationFn: registerNewOrganization,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['authInfo'],
      });
    },
  });
};

export default useRegisterOrganizationMutation;
