import { Button, DatePicker, Input, Select, UploadAvatar } from '@/components';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import {
  useGetOrganizationUserProfileQuery,
  useUpdateOrganizationProfileMutation,
} from '../../services';
import { useState } from 'react';
import { Image } from '@/services/use-upload-image.mutation';
import { nationalCodeValidator } from '@/utils';

const userSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  nationalCode: z.string(),
  mobile: z.string(),
  fatherName: z.string().nullable(),
  birthDate: z.date().nullable(),
  insuranceNumber: z.string().nullable(),
  avatarId: z.number().nullable(),
  personnelCode: z.string().nullable(),
  gender: z.enum(['male', 'female']).nullable(),
  childrenCount: z.number().nullable(),
  maritalStatus: z.enum(['single', 'married']).nullable(),
  phone: z.string().nullable(),
});

type UserFormData = z.infer<typeof userSchema>;

const OrganizationUserInfoForm = () => {
  const { data: user } = useGetOrganizationUserProfileQuery();

  const { mutateAsync: updateOrganizationProfile } =
    useUpdateOrganizationProfileMutation();

  const [uploadedImage, setUploadedImage] = useState<Image>();

  const form = useForm({
    defaultValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      nationalCode: user?.nationalCode,
      fatherName: user?.fatherName ?? null,
      birthDate: user?.birthDate ? new Date(user?.birthDate) : null,
      personnelCode: user?.personnelCode ?? null,
      mobile: user?.mobile || '',
      insuranceNumber: user?.insuranceNumber || '',
      avatarId: null,
      childrenCount: user?.childrenCount ?? null,
      maritalStatus: user?.maritalStatus ?? null,
      gender: user?.gender ?? null,
      phone: user?.phone ?? null,
    } as UserFormData,
    onSubmit: ({ value }) => {
      return updateOrganizationProfile(value);
    },
    validatorAdapter: zodValidator({
      transformErrors: (errors) => errors[0].message,
    }),
    validators: {
      onSubmit: userSchema,
    },
  });

  const isAdmin = user?.role === 'admin';

  return (
    <>
      <div>
        <span className="label form-control gap-2">
          <span className="label-text">تصویر پروفایل</span>
          <UploadAvatar
            onUploaded={(image) => {
              setUploadedImage(image);
              form.setFieldValue('avatarId', image.id);
            }}
            src={
              form.getFieldValue('avatarId')
                ? uploadedImage?.path
                  ? `${import.meta.env.VITE_API_BASE_URL}/${uploadedImage?.path}`
                  : ''
                : `${import.meta.env.VITE_API_BASE_URL}/${user?.avatar}`
            }
          />
        </span>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <form.Field
            name="firstName"
            validators={{
              onSubmit: z
                .string()
                .min(1, 'نام خود را وارد کنید')
                .min(3, 'نام خود را بیشتر از 3 کاراکتر وارد کنید'),
            }}
            children={({ handleChange, handleBlur, state }) => (
              <Input
                label="نام"
                name="firstname"
                placeholder="نام خود را وارد کنید"
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
                error={state.meta.errors}
                helperText={state.meta.errors?.[0]}
                value={state.value}
                disabled={!isAdmin}
              />
            )}
          />
          <form.Field
            validators={{
              onSubmit: z
                .string()
                .min(1, 'نام خانوادگی خود را وارد کنید')
                .min(3, 'نام خانوادگی خود را بیشتر از 3 کاراکتر وارد کنید'),
            }}
            name="lastName"
            children={({ state, handleBlur, handleChange }) => (
              <Input
                label="نام خانوادگی"
                name="lastname"
                placeholder="نام خانوادگی خود را وارد کنید"
                error={state.meta.errors}
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
                helperText={state.meta.errors?.[0]}
                disabled={!isAdmin}
              />
            )}
          />
          <form.Field
            validators={{
              onSubmit: z
                .string()
                .min(3, 'نام پدر باید بیشتر از 3 کاراکتر باشد')
                .nullable(),
            }}
            name="fatherName"
            children={({ state, handleBlur, handleChange }) => (
              <Input
                label="نام پدر"
                name="fatherName"
                placeholder="نام پدر خود را وارد کنید"
                error={state.meta.errors}
                value={state.value ?? ''}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
                helperText={state.meta.errors?.[0]}
                disabled={!isAdmin}
              />
            )}
          />
          <form.Field
            name="birthDate"
            validators={{
              onSubmit: z.date().nullable(),
            }}
            children={({ state, handleBlur, handleChange }) => (
              <DatePicker
                onChange={handleChange}
                onBlur={handleBlur}
                value={state.value}
              />
            )}
          />
          <form.Field
            name="gender"
            validators={{
              onSubmit: z.string().nullable(),
            }}
            children={({ state, handleBlur, handleChange }) => (
              <Select
                size="sm"
                label="جنسیت"
                options={[
                  { value: '', label: 'انتخاب کنید' },
                  { value: 'male', label: 'مرد' },
                  { value: 'female', label: 'زن' },
                ]}
                value={state.value ?? ''}
                onBlur={handleBlur}
                onChange={(e) =>
                  handleChange(e.target.value as 'male' | 'female')
                }
              />
            )}
          />
          <form.Field
            name="maritalStatus"
            validators={{
              onSubmit: z.string().nullable(),
            }}
            children={({ state, handleBlur, handleChange }) => (
              <Select
                size="sm"
                label="وضعیت تاهل"
                options={[
                  { value: '', label: 'انتخاب کنید' },
                  { value: 'single', label: 'مجرد' },
                  { value: 'married', label: 'متاهل' },
                ]}
                value={state.value ?? ''}
                onBlur={handleBlur}
                onChange={(e) =>
                  handleChange(e.target.value as 'single' | 'married')
                }
              />
            )}
          />
          <form.Field
            name="childrenCount"
            validators={{
              onSubmit: z.number().nullable(),
            }}
            children={({ state, handleBlur, handleChange }) => (
              <Input
                error={!!state.meta.errors.length}
                helperText={state.meta.errors.join(',')}
                label="تعداد فرزندان"
                type="number"
                value={state.value || ''}
                onBlur={handleBlur}
                onChange={(e) => handleChange(+e.target.value)}
              />
            )}
          />

          <form.Field
            validators={{
              onSubmit: z
                .string()
                .min(1, 'کدملی خود را وارد کنید')
                .refine(nationalCodeValidator, 'کدملی وارد شده معتبر نمیباشد'),
            }}
            name="nationalCode"
            children={({ state, handleBlur, handleChange }) => (
              <Input
                label="کدملی"
                name="nationalCode"
                placeholder=" کدملی خود را وارد کنید"
                error={state.meta.errors}
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
                helperText={state.meta.errors?.[0]}
                disabled={!isAdmin}
              />
            )}
          />
          {/* TODO: implement this feature in the future when we have a backend for email verification*/}
          {/* <div className="col-start-1">
          <form.Field
            name="email"
            validators={{
              onSubmit: z
                .string()
                .min(1, 'ایمیل خود را وارد کنید')
                .email('ایمیل وارد شده معتبر نمیباشد'),
            }}
            children={({ state, handleBlur, handleChange }) => (
              <Input
                label="ایمیل"
                name="email"
                placeholder="ایمیل خود را وارد کنید"
                error={state.meta.errors}
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
                helperText={state.meta.errors?.[0]}
              />
            )}
          />
        </div> */}

          <div className="col-start-1">
            <form.Field
              validators={{
                onSubmit: z
                  .string()
                  .min(1, 'موبایل خود را وارد کنید')
                  .min(11, 'موبایل وارد شده معتبر نمیباشد'),
              }}
              name="mobile"
              children={({ state, handleBlur, handleChange }) => (
                <Input
                  label="موبایل"
                  name="mobile"
                  placeholder="موبایل خود را وارد کنید"
                  error={state.meta.errors}
                  value={state.value}
                  helperText={state.meta.errors?.[0]}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                />
              )}
            />
          </div>
          <form.Field
            validators={{
              onSubmit: z.string().optional().nullable(),
            }}
            name="phone"
            children={({ state, handleBlur, handleChange }) => (
              <Input
                label="تلفن (ثابت)"
                name="phone"
                placeholder="تلفن (ثابت) خود را وارد کنید"
                error={state.meta.errors}
                value={state.value ?? ''}
                helperText={state.meta.errors?.[0]}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
              />
            )}
          />

          <div className=" col-start-1">
            <form.Field
              validators={{
                onSubmit: z.string().optional().nullable(),
              }}
              name="personnelCode"
              children={({ state, handleBlur, handleChange }) => (
                <Input
                  label="کد پرسنلی"
                  name="personnelCode"
                  placeholder="کد پرسنلی خود را وارد کنید"
                  error={state.meta.errors}
                  value={state.value ?? ''}
                  helperText={state.meta.errors?.[0]}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  disabled={!isAdmin}
                />
              )}
            />
          </div>
          <form.Field
            validators={{
              onSubmit: z.string().optional().nullable(),
            }}
            name="insuranceNumber"
            children={({ state, handleBlur, handleChange }) => (
              <Input
                label="شماره بیمه"
                name="insuranceNumber"
                placeholder="شماره بیمه خود را وارد کنید"
                error={state.meta.errors}
                value={state.value ?? ''}
                helperText={state.meta.errors?.[0]}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
                disabled={!isAdmin}
              />
            )}
          />
        </div>
        <Button
          loading={form.state.isSubmitting}
          disabled={form.state.isSubmitting}
          type="submit"
          className="w-28 float-end mt-10"
          color="primary"
        >
          ویرایش
        </Button>
      </form>
    </>
  );
};

export default OrganizationUserInfoForm;
