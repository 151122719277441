import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const resetPassword = (payload: {
  mobile: string;
  token: string;
  password: string;
}) => api.post('/auth/reset-password', payload);

const useResetPasswordMutation = () => {
  const navigate = useNavigate();

  const { mutate, isPending, error } = useMutation({
    mutationFn: (payload: {
      mobile: string;
      token: string;
      password: string;
    }) => resetPassword(payload),
    onSuccess: (res) => {
      const data = res.data;
      toast.success('تغییر رمز با موفقیت انجام شد');
      Cookies.set('token', data.data.accessToken);
      navigate({ to: '/home' });
    },
    onError: () => {
      toast.error('تغییر رمز با مشکل مواجه شد، لطفا دوباره تلاش کنید');
    },
  });

  return { mutate, isPending, error };
};

export default useResetPasswordMutation;
