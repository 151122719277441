import { api } from '@/config/apiCliant';
import { queryOptions, useQuery } from '@tanstack/react-query';
import * as _ from 'lodash';

const getOrganizationSettings = () =>
  api.get<
    {
      id: number;
      name:
        | 'send-payslips-sms'
        | 'preview-mode'
        | 'send-update-payslips-sms'
        | 'update-payslips'
        | 'show-logo';
      value: string;
      section: string;
    }[]
  >('/organization-settings');

export const getOrganizationSettingsQueryOptions = () =>
  queryOptions({
    queryKey: ['organizationSettings'],
    queryFn: getOrganizationSettings,
    select: (res) => {
      const data = res.data ?? [];
      return data.reduce(
        (acc, cur) => ({
          ...acc,
          [_.camelCase(cur.name)]: { value: cur.value === 'true', id: cur.id },
        }),
        {}
      ) as {
        sendPayslipsSms: { value: boolean; id: number };
        previewMode: { value: boolean; id: number };
        sendUpdatePayslipsSms: { value: boolean; id: number };
        updatePayslips: { value: boolean; id: number };
        showLogo: { value: boolean; id: number };
      };
    },
  });

const useGetOrganizationSettings = () => {
  return useQuery(getOrganizationSettingsQueryOptions());
};

export default useGetOrganizationSettings;
