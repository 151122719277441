import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { SortableItem } from './components/SortableItem/SortableItem';
import { useEffect, useState } from 'react';
import {
  useGetPayslipFieldOrdersQuery,
  useUpdatePayloadFieldsOrdersMutation,
} from '../../services';
import { Loading } from 'react-daisyui';
import { CgCheckO } from 'react-icons/cg';
import clsx from 'clsx';
import { Button } from '@/components';
import * as XLSX from 'xlsx';

const PayslipFieldOrders = () => {
  const {
    data: payslipFieldsOrders,
    isLoading: isGetPayslipFieldOrdersLoading,
  } = useGetPayslipFieldOrdersQuery();

  const {
    mutate: updateFieldOrders,
    isPending: isUpdateFieldOrdersPending,
    isSuccess: isUpdateFieldOrdersSuccess,
    isIdle: isUpdateFieldOrdersIdle,
  } = useUpdatePayloadFieldsOrdersMutation();

  const [items, setItems] = useState<
    { id: number; name: string; index: number; type: string }[]
  >([]);

  useEffect(() => {
    if (payslipFieldsOrders) {
      setItems(
        payslipFieldsOrders.map((pfo) => ({
          id: pfo.id,
          type: pfo.payslipFieldOrderableType,
          name: '',
          index: pfo.index,
          ...(pfo.payslipFieldOrderableType === 'user_field_type' && {
            name: pfo.userField?.name,
          }),
          ...(pfo.payslipFieldOrderableType === 'base_field_type' && {
            name: pfo.baseField?.name,
          }),
          ...(pfo.payslipFieldOrderableType === 'earning_field_type' && {
            name: pfo.earningField?.name,
          }),
          ...(pfo.payslipFieldOrderableType === 'deduction_field_type' && {
            name: pfo.deductionField?.name,
          }),
          ...(pfo.payslipFieldOrderableType === 'general_field_type' && {
            name: pfo.generalField?.name,
          }),
        }))
      );
    }
  }, [payslipFieldsOrders]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);
        const newArrayOrder = arrayMove(items, oldIndex, newIndex);
        updateFieldOrders({
          orders: newArrayOrder.map((item, index) => ({
            id: item.id,
            index: index,
          })),
        });
        return newArrayOrder;
      });
    }
  }

  const generateExcel = () => {
    const wb: XLSX.WorkBook = {
      ...XLSX.utils.book_new(),
      Workbook: {
        Views: [{ RTL: true }],
      },
    };
    const ws = XLSX.utils.aoa_to_sheet([items.map((item) => item.name)]);
    ws['!cols'] = items.map(() => ({ wpx: 100, wch: 10 }));

    XLSX.utils.book_append_sheet(wb, ws, 'فیش حقوق');
    XLSX.writeFile(wb, 'نمونه فیلدهای فیش حقوق.xlsx', {});
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between ">
        <h2>
          تنظیم جایگاه فیلدهای فیش حقوق در هنگام آپلود داده اکسل (
          <Button
            onClick={() => generateExcel()}
            disabled={
              isUpdateFieldOrdersPending || isGetPayslipFieldOrdersLoading
            }
            className="btn-link"
          >
            فایل نمونه اکسل
          </Button>
          ){' '}
        </h2>
        <div className="h-1">
          <Loading
            className={clsx({
              'opacity-0 hidden': !isUpdateFieldOrdersPending,
              'transition-opacity duration-1000 delay-150':
                isUpdateFieldOrdersPending,
            })}
            color="success"
          />

          <CgCheckO
            className={clsx('text-2xl text-success  opacity-0', {
              'opacity-100 transition-opacity duration-1000 delay-150':
                (isUpdateFieldOrdersSuccess || isUpdateFieldOrdersIdle) &&
                !isUpdateFieldOrdersPending,
            })}
          />
        </div>
      </div>
      <div className="mx-auto mt-5 min-h-52">
        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
          <div
            className="flex flex-wrap flex-row lg:flex-col max-lg:h-full gap-1 items-center overflow-ellipsis"
            style={{
              height: items.length * 20 + 'px',
            }}
          >
            <SortableContext items={items} id="index">
              {items.map((item, index) => (
                <SortableItem
                  type={item.type}
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  index={index + 1}
                />
              ))}
            </SortableContext>
          </div>
        </DndContext>
      </div>
    </div>
  );
};

export default PayslipFieldOrders;
