import { Button, Input, OtpInput } from '@/components';
import { Countdown } from '@/modules/auth/components';
import {
  useChangeAuthMobileMutation,
  useVerifyAuthMobileMutation,
} from '@/modules/profile/services';
import { mobileRegex } from '@/utils';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { useState } from 'react';
import { Modal } from 'react-daisyui';
import { z } from 'zod';

const changeMobileSchema = z.object({
  mobile: z.string(),
  verificationCode: z.string(),
});

type ChangeMobileForm = z.infer<typeof changeMobileSchema>;

const ChangeMobileDialog = ({
  open,
  onClose,
  defaultValue,
}: {
  open: boolean;
  onClose: () => void;
  defaultValue: string;
}) => {
  const { mutateAsync: changeAuthMobile, isPending: isChangeMobilePending } =
    useChangeAuthMobileMutation();
  const { mutateAsync: verifyAuthMobile } = useVerifyAuthMobileMutation();

  const [step, setStep] = useState<'MOBILE' | 'VERIFICATION'>('MOBILE');
  const [resendVerificationCode, setResendVerificationCode] = useState(false);

  const form = useForm({
    validators: { onSubmit: changeMobileSchema },
    defaultValues: {
      mobile: defaultValue,
      verificationCode: '',
    } as ChangeMobileForm,
    validatorAdapter: zodValidator({
      transformErrors: (errors) => errors[0].message,
    }),

    onSubmit: ({ value }) => {
      if (step === 'MOBILE') {
        return changeAuthMobile(value.mobile, {
          onSuccess: () => setStep('VERIFICATION'),
        });
      } else {
        if (!value.verificationCode) return;
        return verifyAuthMobile(value.verificationCode, {
          onSuccess: () => {
            form.reset();
            onClose();
          },
        });
      }
    },
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <Modal.Header> تغییر موبایل</Modal.Header>
          <Modal.Body>
            {step === 'MOBILE' && (
              <form.Field
                name="mobile"
                validators={{
                  onSubmit: z
                    .string()
                    .min(1, 'لطفا موبایل خود را وارد کنید')
                    .regex(mobileRegex, 'شماره موبایل صحیح نمی باشد'),
                }}
                children={({ state, handleBlur, handleChange }) => (
                  <Input
                    label="موبایل"
                    name="mobile"
                    value={state.value}
                    onChange={(e) => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    error={state.meta.errors}
                    helperText={state.meta.errors[0]}
                    placeholder="نام خود را وارد کنید"
                  />
                )}
              />
            )}
            {step === 'VERIFICATION' && (
              <form.Field
                name="verificationCode"
                validators={{
                  onSubmit: z
                    .string()
                    .min(1, 'لطفا کد تایید را وارد کنید')
                    .min(4, 'کد تایید صحیح نمی باشد'),
                }}
                children={({ state, handleChange }) => (
                  <label className="label flex flex-col w-full">
                    <span className="label-text-alt mb-2">
                      کد تایید ارسال شده به شماره {state.value} ارسال شد
                    </span>
                    <OtpInput
                      error={state.meta.errors[0]}
                      value={state.value}
                      onChange={handleChange}
                    />
                    {state.meta.errors && (
                      <label className="label pt-0">
                        <span className="label-text-alt text-error">
                          {state.meta.errors[0]}
                        </span>
                      </label>
                    )}
                  </label>
                )}
              />
            )}
            <div className="flex flex-col justify-center items-center ">
              {step === 'VERIFICATION' && !resendVerificationCode && (
                <Countdown
                  start
                  onEnd={() => setResendVerificationCode(true)}
                />
              )}
              {step === 'VERIFICATION' && resendVerificationCode && (
                <Button
                  color="ghost"
                  className="btn-wide"
                  disabled={isChangeMobilePending}
                  loading={isChangeMobilePending}
                  onClick={() => {
                    changeAuthMobile(form.getFieldValue('mobile'), {
                      onSuccess: () => setResendVerificationCode(false),
                    });
                  }}
                >
                  ارسال مجدد کد تایید
                </Button>
              )}
            </div>
          </Modal.Body>
          <Modal.Actions className="gap-2">
            <Button
              color="ghost"
              onClick={() => {
                form.reset();
                onClose();
              }}
            >
              انصراف
            </Button>
            <form.Subscribe
              selector={(state) => [
                state.isSubmitting,
                state.isTouched,
                state.values.mobile,
              ]}
              children={([isSubmitting, isTouched, mobile]) => (
                <Button
                  loading={!!isSubmitting}
                  disabled={
                    !!isSubmitting || !isTouched || mobile === defaultValue
                  }
                  type="submit"
                  color="primary"
                >
                  تایید
                </Button>
              )}
            />
          </Modal.Actions>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeMobileDialog;
