import { Button, Input, UploadAvatar } from '@/components';
import { useGetOrgAuthQuery } from '@/modules/auth';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { useState } from 'react';
import { Modal } from 'react-daisyui';
import { FcOrganization } from 'react-icons/fc';
import { PiPencil } from 'react-icons/pi';
import { z } from 'zod';
import {
  useUpdateOrganizationLogoMutation,
  useUpdateOrganizationMutation,
} from '../../services';

const OrganizationProfileFeat = () => {
  const { data } = useGetOrgAuthQuery();
  const { mutateAsync: updateOrganization } = useUpdateOrganizationMutation();
  const {
    mutate: updateOrganizationLogo,
    isPending: isUpdateOrganizationLogoPending,
  } = useUpdateOrganizationLogoMutation();

  const form = useForm({
    defaultValues: {
      name: '',
    },
    validatorAdapter: zodValidator(),
    onSubmit: ({ value }) => {
      updateOrganization(value, {
        onSuccess: () => {
          form.reset();
          setEdit(false);
        },
      });
    },
  });

  const { data: org } = useGetOrgAuthQuery();

  const [edit, setEdit] = useState(false);

  return (
    <>
      <Modal open={edit}>
        <Modal.Body>
          <Modal.Header>ویرایش نام سازمان</Modal.Header>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.handleSubmit();
            }}
          >
            <form.Field
              name="name"
              validators={{
                onSubmit: z
                  .string()
                  .min(3, 'نام سازمان باید حداقل 3 کاراکتر باشد'),
              }}
              children={({ state, handleBlur, handleChange }) => (
                <Input
                  autoComplete="off"
                  label="نام سازمان"
                  placeholder="نام سازمان را وارد کنید"
                  value={state.value}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  helperText={state.meta.errors[0]}
                  error={state.meta.errors.length > 0}
                  name="name"
                />
              )}
            />
            <Modal.Actions className="gap-5">
              <Button onClick={() => setEdit(false)}>انصراف</Button>
              <Button
                loading={form.state.isSubmitting}
                disabled={form.state.isSubmitting}
                type="submit"
                color="primary"
              >
                ویرایش
              </Button>
            </Modal.Actions>
          </form>
        </Modal.Body>
      </Modal>
      <form>
        <div className="grid grid-cols-4 gap-5">
          <div className="col-span-4 flex flex-col gap-2">
            <span
              style={{
                width: 100,
              }}
              className="text-center"
            >
              لوگو سازمان
            </span>
            <UploadAvatar
              onUploaded={(data) =>
                updateOrganizationLogo({ imageId: data.id })
              }
              loading={isUpdateOrganizationLogoPending}
              src={
                data?.logo
                  ? `${import.meta.env.VITE_API_BASE_URL}/${data.logo}`
                  : ''
              }
              emptyLabel={
                <div>
                  <FcOrganization size={'100%'} />
                </div>
              }
            />
          </div>

          <label className="form-control w-full max-w-xs">
            <div className="label">
              <span className="label-text">نام سازمان</span>
              {/* <div className="join">
              <Button className="join-item self-end" shape="square">
                <PiPencil size={16} />
              </Button>
            </div> */}
            </div>
            <div className="join">
              <input
                disabled
                className="input input-bordered join-item input-sm"
                value={org?.name}
              />
              <Button
                onClick={() => setEdit(true)}
                className="join-item"
                shape="square"
              >
                <PiPencil size={16} />
              </Button>
            </div>
          </label>
        </div>
      </form>
    </>
  );
};

export default OrganizationProfileFeat;
