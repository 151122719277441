import { InputProps as ReactDaisyUiInputProps } from 'react-daisyui';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { ValidationError } from '@tanstack/react-form';

interface InputProps extends ReactDaisyUiInputProps {
  label?: string;
  topAltLabel?: string;
  downAltLabel?: string;
  helperText?: string | null | ValidationError;
  error?: boolean | Array<never> | string | ValidationError[];
  wrapperClassName?: React.HtmlHTMLAttributes<HTMLDivElement>['className'];
  joinAfter?: React.ReactNode;
  joinBefore?: React.ReactNode;
}

const Input = (props: InputProps) => {
  const {
    label,
    topAltLabel,
    downAltLabel,
    helperText,
    error,
    joinAfter,
    joinBefore,
    wrapperClassName,
    size = 'sm',
    color,
    // borderOffset,
    bordered = true,
    className,
    ...restProps
  } = props;

  const hasError = useMemo<boolean>((): boolean => {
    if (Array.isArray(error)) {
      return !!error.length;
    } else {
      return !!error;
    }
  }, [error]);

  return (
    <div className={clsx(`form-control ${wrapperClassName}`)}>
      <label className="label flex-col gap-2  items-start">
        {label && (
          <span className="label-text w-full">
            {label}
            {restProps.required ? <span className="text-error"> * </span> : ''}
          </span>
        )}
        {topAltLabel && <span className="label-text-alt">{topAltLabel}</span>}
        <div
          className={clsx('input  flex w-full', {
            'pl-0': joinAfter,
            'pr-0': joinBefore,
            'input-lg': size === 'lg',
            'input-md': size === 'md',
            'input-sm': size === 'sm',
            'input-xs': size === 'xs',
            'input-error': hasError || color === 'error',
            'input-primary': color === 'primary',
            'input-secondary': color === 'secondary',
            'input-accent': color === 'accent',
            'input-info': color === 'info',
            'input-success': color === 'success',
            'input-ghost': color === 'ghost',
            'input-warning': color === 'warning',
            'input-bordered': bordered,
            className: className,
          })}
        >
          {joinBefore}
          <input
            color={hasError ? 'error' : color || 'neutral'}
            className={clsx('grow w-full', {
              'pl-2': joinAfter,
              'pr-2': joinBefore,
            })}
            {...restProps}
          />
          {joinAfter}
        </div>
      </label>

      {(downAltLabel || helperText) && (
        <label className="label pt-0">
          {downAltLabel && (
            <span className="label-text-alt">{downAltLabel}</span>
          )}
          {helperText && (
            <span
              className={clsx('label-text-alt', { 'text-error': hasError })}
            >
              {helperText}
            </span>
          )}
        </label>
      )}
    </div>
  );
};

export default Input;
