import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';

export interface UpdatePayslipFieldsOrdersPayload {
  orders: { id: number; index: number }[];
}

const updatePayslipFieldsOrders = (payload: UpdatePayslipFieldsOrdersPayload) =>
  api.put('/payslip-field-orders/orders', payload);

const useUpdatePayloadFieldsOrdersMutation = () => {
  return useMutation({
    mutationFn: (payload: UpdatePayslipFieldsOrdersPayload) =>
      updatePayslipFieldsOrders(payload),
  });
};

export default useUpdatePayloadFieldsOrdersMutation;
