import { Breadcrumbs } from 'react-daisyui';
import { UsersTable } from '../features';

const UsersPage = () => {
  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item>کاربران</Breadcrumbs.Item>
      </Breadcrumbs>
      <UsersTable />
    </div>
  );
};
export default UsersPage;
