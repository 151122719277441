import { Button, OtpInput } from '@/components';
import { useForm } from '@tanstack/react-form';
import { useNavigate } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-form-adapter';
import clsx from 'clsx';
import { useState } from 'react';
import { Card, Input } from 'react-daisyui';
import { BiArrowBack } from 'react-icons/bi';
import { z } from 'zod';
import { Countdown } from '../../components';
import { CgChevronLeft } from 'react-icons/cg';
import {
  useResetPasswordMutation,
  useSendResetPasswordOtpMutation,
  useVerifyResetPasswordOtpMutation,
} from '../../services';
import { mobileRegex } from '@/utils';

const ResetPassword = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState<'OTP' | 'MOBILE' | 'PASSWORD'>('MOBILE');

  const [token, setToken] = useState('');

  const { mutate: resetPassword, isPending: resetPasswordPending } =
    useResetPasswordMutation();
  const {
    mutate: sendResetPasswordOtp,
    isPending: sendResetPasswordOtpPending,
  } = useSendResetPasswordOtpMutation();
  const {
    mutate: verifyResetPasswordOtp,
    isPending: verifyResetPasswordOtpPending,
  } = useVerifyResetPasswordOtpMutation();

  const form = useForm({
    defaultValues: {
      mobile: '',
      otp: '',
      password: '',
      confirmPassword: '',
    },
    validatorAdapter: zodValidator({
      transformErrors: (errors) => {
        return errors[0].message;
      },
    }),
    onSubmit: ({ value }) => {
      if (step === 'MOBILE') {
        sendResetPasswordOtp(
          { mobile: value.mobile },
          { onSuccess: () => setStep('OTP') }
        );
      } else if (step === 'OTP') {
        verifyResetPasswordOtp(
          {
            code: value.otp,
            mobile: value.mobile,
          },
          {
            onSuccess: (res) => {
              const data = res.data;
              setToken(data.data.token);
              setStep('PASSWORD');
            },
          }
        );
      } else if (step === 'PASSWORD') {
        resetPassword({
          mobile: value.mobile,
          token,
          password: value.password,
        });
      }
    },
  });

  return (
    <div className=" h-screen   flex flex-col  justify-center w-full lg:w-96  ">
      <Card className="prose">
        <Card.Body>
          <Card.Title className="text-center w-full block relative">
            ParsHR
            <Button
              onClick={() => navigate({ to: '/auth/login' })}
              color="ghost"
              shape="circle"
              className="absolute right-0 -top-1/2 "
            >
              <BiArrowBack size={20} className="rotate-180" />
            </Button>
          </Card.Title>

          <div
            className="divider font-semibold text-lg
      mt-10 mb-5"
          >
            تغییر رمز عبور
          </div>
          {step === 'OTP' && (
            <small>کد به شماره {form.getFieldValue('mobile')} ارسال شد.</small>
          )}
          <p className="font-semibold text-justify">
            برای تغییر رمز عبور، شماره موبایل خود را وارد کنید
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.handleSubmit();
            }}
          >
            {step === 'MOBILE' && (
              <form.Field
                name="mobile"
                validators={{
                  onSubmit: z
                    .string()
                    .min(1, ' شماره موبایل نمیتواند خالی باشد')
                    .regex(mobileRegex, 'شماره موبایل صحیح نمی باشد'),
                }}
                children={(field) => (
                  <label className="label-text flex flex-col gap-2">
                    <Input
                      autoFocus
                      onChange={(e) => field.handleChange(e.target.value)}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      name={field.name}
                      placeholder="شماره موبایل"
                      type="text"
                      className={clsx('input-bordered', {
                        'input-error': field.state.meta.errors.length,
                      })}
                    />
                    {!!field.state.meta.errors.length && (
                      <span className="label-text-alt text-error">
                        {field.state.meta.errors[0]}
                      </span>
                    )}
                  </label>
                )}
              />
            )}

            {step === 'OTP' && (
              <form.Field
                name="otp"
                validators={{
                  onSubmit: z
                    .string()
                    .min(1, 'کد را وارد کنید')
                    .min(4, 'کد صحیح نمی باشد'),
                }}
                children={({ state, handleChange }) => (
                  <OtpInput
                    error={state.meta.errors[0]}
                    onChange={handleChange}
                    value={state.value}
                  />
                )}
              />
            )}

            {step === 'PASSWORD' && (
              <div className="flex flex-col gap-5">
                <form.Field
                  name="password"
                  validators={{
                    onSubmit: z
                      .string()
                      .min(1, 'رمز عبور را وارد کنید')
                      .min(8, 'رمز عبور باید حداقل 8 کاراکتر باشد'),
                  }}
                  children={(field) => (
                    <label className="label-text flex flex-col gap-2">
                      <Input
                        onChange={(e) => field.handleChange(e.target.value)}
                        value={field.state.value}
                        onBlur={field.handleBlur}
                        name={field.name}
                        placeholder="رمز عبور"
                        type="password"
                        className={clsx('input-bordered', {
                          'input-error': field.state.meta.errors.length,
                        })}
                      />
                      {!!field.state.meta.errors.length && (
                        <span className="label-text-alt text-error">
                          {field.state.meta.errors[0]}
                        </span>
                      )}
                    </label>
                  )}
                />

                <form.Field
                  name="confirmPassword"
                  validators={{
                    onSubmit: z
                      .string()
                      .refine(
                        (value) => value === form.getFieldValue('password'),
                        'رمز عبور با تکرار آن یکسان نیست'
                      ),
                  }}
                  children={(field) => (
                    <label className="label-text flex flex-col gap-2">
                      <Input
                        onChange={(e) => field.handleChange(e.target.value)}
                        value={field.state.value}
                        onBlur={field.handleBlur}
                        name={field.name}
                        placeholder="تکرار رمز عبور"
                        type="password"
                        className={clsx('input-bordered', {
                          'input-error': field.state.meta.errors.length,
                        })}
                      />
                      {!!field.state.meta.errors.length && (
                        <span className="label-text-alt text-error">
                          {field.state.meta.errors[0]}
                        </span>
                      )}
                    </label>
                  )}
                />
              </div>
            )}

            {step === 'OTP' && (
              <Countdown start={step === 'OTP'} time={2 * 60 * 1000} />
            )}

            <Button
              className="mt-5 float-end btn-block"
              color="primary"
              size="md"
              type="submit"
              loading={
                verifyResetPasswordOtpPending ||
                sendResetPasswordOtpPending ||
                resetPasswordPending
              }
            >
              {step === 'MOBILE' && (
                <>
                  تایید و ادامه <CgChevronLeft size={16} />
                </>
              )}
              {(step === 'OTP' || step === 'PASSWORD') && <>تایید</>}
            </Button>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ResetPassword;
