import { Breadcrumbs, Card } from 'react-daisyui';
import {
  PayslipFields,
  PayslipFieldOrders,
  PayslipGeneralSettings,
} from '../features';

const PayslipSettingsPage = () => {
  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item>حقوق و دستمزد</Breadcrumbs.Item>
        <Breadcrumbs.Item>تنظیمات</Breadcrumbs.Item>
      </Breadcrumbs>
      <Card className="mt-5">
        <Card.Body>
          <Card.Title> عمومی</Card.Title>
          <div className="mt-5">
            <PayslipGeneralSettings />
          </div>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Card.Title>
            فیلدهای فیش حقوق (اطلاعات پایه، اضافات و کسورات)
          </Card.Title>
          <div className="mt-5">
            <PayslipFields />
            <PayslipFieldOrders />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PayslipSettingsPage;
