import { api } from '@/config/apiCliant';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { PayslipField } from '../types';

const getPayslipBaseFields = () =>
  api.get<PayslipField[]>('/payslip-bases/fields');

export const getPayslipBaseFieldsOptions = () => {
  return queryOptions({
    queryKey: ['payslipBaseFields'],
    queryFn: () => getPayslipBaseFields(),
    select: (res) => res.data,
  });
};

const useGetPayslipBaseFieldsQuery = () => {
  return useQuery(getPayslipBaseFieldsOptions());
};

export default useGetPayslipBaseFieldsQuery;
