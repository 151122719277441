import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const loginByOtp = (payload: { mobile: string; otp: string }) =>
  api.post('/auth/login-by-otp', payload);

const useLoginByOtpMutation = () => {
  const navigate = useNavigate();
  const { mutate, isPending, error } = useMutation({
    mutationFn: (payload: { mobile: string; otp: string }) =>
      loginByOtp(payload),

    onSuccess: (res) => {
      const accessToken = res.data.data.accessToken;
      Cookies.set('token', accessToken);
      navigate({ to: '/home' });
    },
    onError: (
      e: AxiosError<{ status: 'code_expired' | 'wrong_code' | 'conflict' }>
    ) => {
      if (e.response?.data.status === 'code_expired')
        toast.error('کد منقضی شده است');
      else if (e.response?.data.status === 'wrong_code')
        toast.error('کد اشتباه است');
      else toast.error('ورود با مشکل مواجه شد');
    },
  });

  return { mutate, isPending, error };
};

export default useLoginByOtpMutation;
