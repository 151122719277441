import { PinInput } from '@ark-ui/react';

const OtpInput = ({
  error,
  value,
  onChange,
  onValueComplete,
}: {
  onChange?: (value: string) => void;
  onValueComplete?: (value: string) => void;
  value?: string;
  error?: string | null | undefined | boolean;
}) => {
  return (
    <>
      <PinInput.Root
        autoFocus
        otp
        onValueChange={(e) => onChange?.(e.valueAsString)}
        onValueComplete={(e) => onValueComplete?.(e.valueAsString)}
        value={
          value?.length
            ? [
                ...value.split(''),
                ...new Array(4 - value.split('').length).fill(''),
              ]
            : ['', '', '', '']
        }
        className="text-center"
        defaultValue={['', '', '', '']}
      >
        <PinInput.Label className="label-text mb-2 block">
          کد تایید
        </PinInput.Label>
        <PinInput.Control className="flex gap-1 items-center justify-center flex-row-reverse">
          {[0, 1, 2, 3].map((id, index) => (
            <>
              <PinInput.Input
                className="input input-bordered input-sm w-10 h-10 text-center"
                key={id}
                index={index}
              />
              {index !== 3 && <span>-</span>}
            </>
          ))}
        </PinInput.Control>
        <PinInput.HiddenInput />
      </PinInput.Root>
      {error && (
        <label className="label pt-0">
          <span className="label-text-alt text-error">{error}</span>
        </label>
      )}
    </>
  );
};

export default OtpInput;
