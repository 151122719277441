import { Dialog } from '@/components';
import { Loading } from 'react-daisyui';
import PayrollStatement from '../../../PayrollStatement';
import { Payslip } from '@/modules/payslip/types';

const PayrollDialog = ({
  onClose,
  open,
  user,
  payslip,
}: {
  payslip?: Payslip;
  open: boolean;
  onClose: () => void;
  user: { firstName: string; lastName: string };
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="[&>div]:max-w-screen-md"
      title={`فیش حقوق${user.firstName + ' ' + user.lastName}`}
    >
      {payslip ? (
        <PayrollStatement
          {...payslip}
          bases={
            payslip.bases?.map((bs) => ({
              label: bs.payslipBaseField.name,
              value: bs.value,
            })) || []
          }
          deductions={
            payslip.deductions?.map((d) => ({
              amount: d.amount,
              label: d.payslipDeductionField.name,
            })) || []
          }
          earnings={
            payslip.earnings?.map((e) => ({
              amount: e.amount,
              label: e.payslipEarningField.name,
            })) || []
          }
          totalDeduction={payslip.totalDeductions}
          payPeriod={payslip.payPeriod}
        />
      ) : (
        <Loading />
      )}
    </Dialog>
  );
};

export default PayrollDialog;
