import { api } from '@/config/apiCliant';
import { queryClient } from '@/main';
import { getAuthInfoQueryOptions } from '@/modules/auth/services/use-get-auth-info.query';
import { getOrgAuthQueryOptions } from '@/modules/auth/services/use-get-org-auth.query';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const updateOrganization = (payload: { name: string }) =>
  api.patch('/organization', payload);

const useUpdateOrganizationMutation = () => {
  return useMutation({
    mutationKey: ['update-organization'],
    mutationFn: (payload: { name: string }) => updateOrganization(payload),
    onSuccess: () => {
      queryClient.refetchQueries(getOrgAuthQueryOptions());
      queryClient.refetchQueries(getAuthInfoQueryOptions());
      toast.success('ویرایش با موفقیت انجام شد');
    },
    onError: () => {
      toast.error('ویرایش با مشکل مواجه شد');
    },
  });
};

export default useUpdateOrganizationMutation;
