import { Button, LoadingOverlay } from '@/components';
import { logout } from '@/modules';
import useGetAuthInfoQuery, {
  getAuthInfoQueryOptions,
} from '@/modules/auth/services/use-get-auth-info.query';
import {
  createFileRoute,
  Link,
  Outlet,
  redirect,
} from '@tanstack/react-router';
import Cookies from 'js-cookie';
import { BiLogOut } from 'react-icons/bi';

export const Route = createFileRoute('/home')({
  beforeLoad: () => {
    const token = Cookies.get('token');
    if (!token) {
      return redirect({ to: '/auth/login' });
    }
  },
  loader: ({ context }) => {
    context.queryClient.ensureQueryData(getAuthInfoQueryOptions());
  },

  component: Home,
});

function Home() {
  const { isLoading: isAuthInfoLoading, data: authInfo } =
    useGetAuthInfoQuery();

  if (isAuthInfoLoading || !authInfo) return <LoadingOverlay />;

  return (
    <>
      <div className="navbar bg-base-100 shadow-sm h-24">
        <div className="flex-1">
          <Link to="/home" className="btn btn-ghost text-xl">
            ParsHR
          </Link>
        </div>
        <div className="flex-none">
          <Button onClick={logout} color="ghost">
            خروج <BiLogOut size={16} />
          </Button>
          {/* <Dropdown end>
            <Button
              tabIndex={0}
              color="ghost"
              className="avatar"
              shape="circle"
            >
              <div className="w-10 rounded-full">
                <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp" />
              </div>
            </Button>
            <Dropdown.Menu className="mt-3 z-[1] w-52 menu-sm">
              <li>
                <Link to="/dashboard/profile" className="justify-between">
                  پروفایل
                </Link>
              </li>
              <Dropdown.Item onClick={logout}>خروج</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </div>
      <div className="m-5 ">
        <Outlet />
      </div>
    </>
  );
}
