import { Button } from '@/components';
import { useDeletePayslipFieldMutation } from '@/modules/payslip/services';
import { FieldType, PayslipField } from '@/modules/payslip/types';
import { Popover } from '@ark-ui/react';
import { useState } from 'react';

function DeleteFieldPopover({
  children,
  field,
  type,
}: {
  children: React.ReactNode;
  field: PayslipField;
  type: FieldType;
}) {
  const { mutate: deletePayslipField, isPending: isDeletePayslipFieldLoading } =
    useDeletePayslipFieldMutation(type);

  const [open, setOpen] = useState(false);

  return (
    <Popover.Root
      positioning={{
        offset: { crossAxis: -20, mainAxis: -20 },
      }}
      open={open}
      onOpenChange={({ open }) => setOpen(open)}
    >
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Positioner>
        <Popover.Content className="shadow-md rounded-md bg-base-100 p-2">
          <Popover.Title className="px-2">آیا مطمئن هستید؟</Popover.Title>
          <Popover.Description className="w-52 text-base-content text-pretty px-2">
            در صورتی که این فیلد در یک یا بیشتر فیش حقوق استفاده شده، فیلد حذف
            نخواهد شد.
          </Popover.Description>
          <div className="mt-2 flex justify-end gap-2">
            <Popover.CloseTrigger>
              <Button size="xs">انصراف</Button>
            </Popover.CloseTrigger>
            <Button
              loading={isDeletePayslipFieldLoading}
              disabled={isDeletePayslipFieldLoading}
              onClick={() =>
                deletePayslipField(field.id, {
                  onSuccess: () => {
                    setOpen(false);
                  },
                })
              }
              color="error"
              size="xs"
            >
              حذف
            </Button>
          </div>
          <Popover.Arrow>
            <Popover.ArrowTip />
          </Popover.Arrow>
        </Popover.Content>
      </Popover.Positioner>
    </Popover.Root>
  );
}

export default DeleteFieldPopover;
