import { LoginPage } from '@/modules/auth/pages';
import { createFileRoute, redirect } from '@tanstack/react-router';
import Cookies from 'js-cookie';

export const Route = createFileRoute('/auth/login')({
  component: Login,
  beforeLoad: () => {
    const token = Cookies.get('token');
    const org_token = Cookies.get('org_token');

    if (token) {
      if (org_token) {
        return redirect({ to: '/dashboard/home' });
      } else {
        return redirect({ to: '/home' });
      }
    }
  },
});

function Login() {
  return <LoginPage />;
}
