import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  RowData,
  RowSelectionState,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { Loading, Table as TableDaisyui } from 'react-daisyui';
import Input from '../Input';
import { Pagination } from '@ark-ui/react/pagination';
import {
  CgChevronDown,
  CgChevronLeft,
  CgChevronRight,
  CgChevronUp,
} from 'react-icons/cg';
import clsx from 'clsx';
import { useDebounce } from 'use-debounce';
import { useEffect, useState } from 'react';
import Button from '../Button';
import { Menu } from '@ark-ui/react';
import { BiSelectMultiple } from 'react-icons/bi';
import { ButtonProps } from 'node_modules/react-daisyui/dist/Button';
import { type ConfirmPopoverProps } from '../ConfirmPopover';
import { ConfirmPopover } from '..';

export interface TableProps<TData extends RowData> {
  onSearch?: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];
  data: TData[];
  actions?: React.ReactNode;
  total?: number;
  loading?: boolean;
  sorting?: Array<{ id: string; desc: boolean }>;
  onChangeSorting?: OnChangeFn<SortingState>;
  enableRowSelection?: boolean;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  rowSelection?: RowSelectionState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  pagination?: PaginationState;
  onColumnVisibilityChange?: OnChangeFn<VisibilityState>;
  columnVisibility?: VisibilityState;
  selectedActions?: Array<{
    label: string;
    onClick?: () => void;
    icon: React.ReactNode;
    buttonProps?: ButtonProps;
    confirmPopover?: boolean;
    confirmPopoverProps?: Omit<ConfirmPopoverProps, 'children'>;
  }>;
}

type Unpacked<T> = T extends (infer U)[] ? U : T;

const Table = <
  Data extends RowData[],
  TData extends RowData = Array<Unpacked<Data>>,
>({
  columns,
  data,
  onSearch,
  actions,
  total = 0,
  loading,
  onChangeSorting,
  sorting,
  enableRowSelection,
  onRowSelectionChange,
  rowSelection,
  onPaginationChange,
  columnVisibility,
  onColumnVisibilityChange,
  pagination = {
    pageIndex: 0,
    pageSize: 10,
  },
  selectedActions,
}: TableProps<TData>) => {
  const table = useReactTable<TData>({
    columns: columns,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    manualPagination: true,
    rowCount: total,
    state: {
      pagination,
      sorting,
      rowSelection,
      columnVisibility,
    },
    onSortingChange: onChangeSorting,
    enableRowSelection,
    onRowSelectionChange,
    onPaginationChange: onPaginationChange,
    onColumnVisibilityChange: onColumnVisibilityChange,
  });

  const [searchValue, setSearchValue] = useState('');

  const [debouncedValue] = useDebounce(searchValue, 500);

  useEffect(() => {
    onSearch?.(debouncedValue);
  }, [debouncedValue, onSearch]);

  return (
    <>
      <div className="w-full p-4">
        <div className="flex justify-between w-full items-center">
          <div>
            <Input
              onChange={(e) => setSearchValue(e.target.value)}
              size="sm"
              type="text"
              placeholder="جستجو ..."
            />
          </div>
          <div>{actions}</div>
        </div>
        {enableRowSelection && (
          <div
            className={clsx(
              'flex items-center rounded-lg justify-between text-xs text-slate-700 bg-gray-50 py-1 px-2',
              {
                'transition-all duration-1000 opacity-100 h-full':
                  table.getSelectedRowModel().rows.length,
                'transition duration-200 opacity-0 h-0 ':
                  !table.getSelectedRowModel().rows.length,
              }
            )}
          >
            <div>
              <Menu.Root>
                <Menu.Trigger asChild>
                  <Button>
                    عملیات گروهی <BiSelectMultiple />
                  </Button>
                </Menu.Trigger>
                <Menu.Positioner>
                  <Menu.Content asChild>
                    <ul className="dropdown-content bg-base-100 p-1 rounded-box  w-28  shadow z-20 ">
                      {selectedActions?.map((action) => (
                        <Menu.Item
                          key={action.label}
                          asChild
                          value={action.label}
                          closeOnSelect={!action.confirmPopover}
                        >
                          <li>
                            {action.confirmPopover ? (
                              <ConfirmPopover {...action.confirmPopoverProps}>
                                <Button
                                  color="ghost"
                                  className="btn-block"
                                  {...action.buttonProps}
                                  onClick={action.onClick}
                                >
                                  {action.icon}
                                  {action.label}
                                </Button>
                              </ConfirmPopover>
                            ) : (
                              <Button
                                color="ghost"
                                className="btn-block"
                                {...action.buttonProps}
                                onClick={action.onClick}
                              >
                                {action.icon}
                                {action.label}
                              </Button>
                            )}
                          </li>
                        </Menu.Item>
                      ))}
                    </ul>
                  </Menu.Content>
                </Menu.Positioner>
              </Menu.Root>
            </div>
            <div>
              {table.getSelectedRowModel().rows.length} از {data.length} آیتم
              انتخاب شده
            </div>
          </div>
        )}
      </div>

      <div className="overflow-x-auto">
        <TableDaisyui pinRows zebra size="xs" className="min-h-32 ">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={clsx({
                        hidden: !header.getContext().column.getIsVisible(),
                      })}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={clsx('', {
                            'flex gap-2 items-center cursor-pointer':
                              header.column.getCanSort(),
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === 'asc'
                                ? 'Sort ascending'
                                : header.column.getNextSortingOrder() === 'desc'
                                  ? 'Sort descending'
                                  : 'Clear sort'
                              : undefined
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <CgChevronUp className="text-gray-800" />,
                            desc: <CgChevronDown className="text-gray-800" />,
                          }[header.column.getIsSorted() as string] ??
                            (header.column.getCanSort() && (
                              <CgChevronDown className="text-gray-300" />
                            ))}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
            {data.length === 0 && !loading && (
              <tr className="text-center">
                <td className="h-20" colSpan={columns.length}>
                  داده ای وجود ندارد
                </td>
              </tr>
            )}
          </thead>
          <tbody className="relative">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className={clsx({})}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            {loading && (
              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50">
                <Loading />
              </div>
            )}
          </tbody>
        </TableDaisyui>
      </div>
      {total > 0 && (
        <Pagination.Root
          page={pagination.pageIndex + 1}
          className="join flex justify-center items-center mt-4"
          count={total}
          pageSize={pagination.pageSize}
          siblingCount={2}
          onPageChange={(value) =>
            table.setPagination({
              pageIndex: value.page - 1,
              pageSize: value.pageSize,
            })
          }
        >
          <Pagination.PrevTrigger className="join-item btn btn-square btn-sm">
            <CgChevronRight />
          </Pagination.PrevTrigger>
          <Pagination.Context>
            {(pagination) =>
              pagination.pages.map((page, index) =>
                page.type === 'page' ? (
                  <Pagination.Item
                    className="join-item btn btn-square aria-[current]:btn-primary  btn-sm"
                    key={index}
                    {...page}
                  >
                    {page.value}
                  </Pagination.Item>
                ) : (
                  <Pagination.Ellipsis
                    className="join-item btn btn-square btn-disabled  btn-sm"
                    key={index}
                    index={index}
                  >
                    ...
                  </Pagination.Ellipsis>
                )
              )
            }
          </Pagination.Context>
          <Pagination.NextTrigger className="join-item btn btn-square  btn-sm">
            <CgChevronLeft />
          </Pagination.NextTrigger>
        </Pagination.Root>
      )}
    </>
  );
};

export default Table;
