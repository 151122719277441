import { isDisableSignup } from '@/env';
import { RegisterPage } from '@/modules';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/auth/register')({
  beforeLoad: () => {
    if (isDisableSignup) {
      return redirect({ to: '/auth/login' });
    }
  },
  component: RegisterPage,
});
