import { Button, Table } from '@/components';
import { BiPlus } from 'react-icons/bi';
import { useGetUsersQuery } from '../../services';
import { useState } from 'react';
import useUserColumns from './use-user-columns';
import { PaginationState } from '@tanstack/react-table';

const UsersTable = () => {
  const columns = useUserColumns();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState<Array<{ id: string; desc: boolean }>>(
    []
  );

  const {
    data: usersData,
    isFetching,
    isPlaceholderData,
    isPending,
  } = useGetUsersQuery({
    limit: pagination.pageSize,
    page: pagination.pageIndex + 1,
    search,
    sort: sorting[0]?.desc ? 'desc' : 'asc',
    sortBy: sorting[0]?.id,
  });

  return (
    <>
      <Table
        columns={columns}
        data={usersData?.data || []}
        pagination={pagination}
        total={usersData?.meta.total}
        onPaginationChange={setPagination}
        loading={isPending || (isFetching && isPlaceholderData)}
        onSearch={setSearch}
        sorting={sorting}
        onChangeSorting={setSorting}
        actions={
          <Button to="/dashboard/users/new" size="sm">
            کاربر جدید <BiPlus />
          </Button>
        }
      />
    </>
  );
};

export default UsersTable;
