import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

const singInToOrganization = (organizationId: number) =>
  api.post('/org-auth/sign', {
    orgId: organizationId,
  });

const useSignToOrganizationMutation = () => {
  return useMutation<AxiosResponse<{ org_token: string }>, AxiosError, number>({
    mutationFn: singInToOrganization,
    onSuccess: (res) => {
      const { data } = res;
      Cookies.set('org_token', data.org_token);
    },
  });
};

export default useSignToOrganizationMutation;
