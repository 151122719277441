import { api } from '@/config/apiCliant';
import { useQuery } from '@tanstack/react-query';

const getPayslipAuthUserCount = () => api.get<number>('/payslips/count/me');

const useGetPayslipAuthUserCount = () => {
  return useQuery({
    queryKey: ['payslip-auth-user-count'],
    queryFn: getPayslipAuthUserCount,
    select: (res) => res.data,
  });
};

export default useGetPayslipAuthUserCount;
