import { Button, Input, DatePicker, UploadAvatar } from '@/components';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { useState } from 'react';
import { z } from 'zod';
import { BiChevronLeft } from 'react-icons/bi';
import { nationalCodeValidator } from '@/utils';
import clsx from 'clsx';
import { useCreateUserMutation } from '../../services';
import { useNavigate } from '@tanstack/react-router';
import { User } from '../../services/use-get-users.query';
import useUpdateUser from '../../services/use-update-user.mutation';
import Select from '@/components/Select';
import { Image } from '@/services/use-upload-image.mutation';

const userSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  nationalCode: z.string(),
  mobile: z.string(),
  fatherName: z.string().nullable(),
  birthDate: z.date().nullable(),
  insuranceNumber: z.string().nullable(),
  avatarId: z.number().nullable(),
  personnelCode: z.string().nullable(),
  gender: z.enum(['male', 'female']).nullable(),
  childrenCount: z.number().nullable(),
  maritalStatus: z.enum(['single', 'married']).nullable(),
});

type UserFormData = z.infer<typeof userSchema>;

const UserForm = ({
  mode = 'create',
  user,
}: {
  mode?: 'create' | 'update';
  user?: User;
}) => {
  const { mutate: createUser, isPending: isCreateUserPending } =
    useCreateUserMutation();

  const { mutate: updateUser, isPending: isUpdateUserPending } = useUpdateUser(
    user?.id
  );

  const [uploadedImage, setUploadedImage] = useState<Image>();

  const navigate = useNavigate();

  const form = useForm({
    defaultValues:
      mode === 'update'
        ? ({
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            insuranceNumber: user?.insuranceNumber || null,
            avatarId: null,
            mobile: user?.mobile || '',
            nationalCode: user?.nationalCode || '',
            birthDate: user?.birthDate ? new Date(user?.birthDate) : null,
            fatherName: user?.fatherName || null,
            childrenCount: user?.childrenCount || null,
            maritalStatus: user?.maritalStatus || null,
            gender: user?.gender || null,
            personnelCode: user?.personnelCode || null,
          } as UserFormData)
        : ({
            firstName: '',
            lastName: '',
            nationalCode: '',
            mobile: '',
            fatherName: null,
            birthDate: null,
            insuranceNumber: null,
            avatarId: null,
            childrenCount: null,
            gender: null,
            maritalStatus: null,
            personnelCode: null,
          } as UserFormData),
    validatorAdapter: zodValidator({
      transformErrors: (errors) => errors[0].message,
    }),
    validators: {
      onSubmit: userSchema,
    },

    onSubmit: ({ value }) => {
      if (mode === 'create') {
        createUser(
          {
            ...value,
            avatarId: value.avatarId ?? undefined,
          },
          {
            onSuccess: (res) => {
              navigate({
                to: '/dashboard/users/$user',
                params: { user: res.data.data.id.toString() },
              });
            },
          }
        );
      } else {
        updateUser(value);
      }
    },
  });

  return (
    <>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <div className="grid grid-cols-3 gap-5 mt-5">
          <div className="col-span-3">
            <span className="label form-control gap-2">
              <span className="label-text">تصویر پروفایل</span>
              <UploadAvatar
                src={
                  mode === 'create'
                    ? uploadedImage?.path
                      ? `${import.meta.env.VITE_API_BASE_URL}/${uploadedImage?.path}`
                      : ''
                    : form.getFieldValue('avatarId')
                      ? uploadedImage?.path
                        ? `${import.meta.env.VITE_API_BASE_URL}/${uploadedImage?.path}`
                        : ''
                      : `${import.meta.env.VITE_API_BASE_URL}/${user?.avatar}`
                }
                onUploaded={(image) => {
                  setUploadedImage(image);
                  form.setFieldValue('avatarId', image.id);
                }}
              />
            </span>
          </div>
          <form.Field
            name="firstName"
            validators={{
              onSubmit: z
                .string()
                .min(1, 'نام را وارد کنید')
                .min(3, 'نام باید حداقل 3 کاراکتر باشد'),
            }}
            children={({ state, handleBlur, handleChange }) => (
              <Input
                {...state}
                required
                error={!!state.meta.errors.length}
                helperText={state.meta.errors.join(',')}
                label="نام"
                placeholder="نام"
                onBlur={handleBlur}
                onChange={(e) => handleChange(e.target.value)}
              />
            )}
          />
          {mode === 'update' && (
            <form.Field
              name="fatherName"
              validators={{
                onSubmit: z
                  .string()
                  .min(3, 'نام پدر باید حداقل 3 کاراکتر باشد')
                  .nullable(),
              }}
              children={({ state, handleBlur, handleChange }) => (
                <Input
                  error={!!state.meta.errors.length}
                  helperText={state.meta.errors.join(',')}
                  label="نام پدر"
                  placeholder="نام پدر"
                  onBlur={handleBlur}
                  value={state.value || ''}
                  onChange={(e) => handleChange(e.target.value)}
                />
              )}
            />
          )}
          <form.Field
            name="lastName"
            validators={{
              onSubmit: z
                .string()
                .min(1, 'نام خانوادگی را وارد کنید')
                .min(3, 'نام خانوادگی باید حداقل 3 کاراکتر باشد'),
            }}
            children={({ state, handleBlur, handleChange }) => (
              <Input
                required
                label="نام خانوادگی"
                placeholder="نام خانوادگی"
                error={state.meta.errors}
                helperText={state.meta.errors[0]}
                {...state}
                onBlur={handleBlur}
                onChange={(e) => handleChange(e.target.value)}
              />
            )}
          />
          {mode === 'update' && (
            <>
              <form.Field
                name="birthDate"
                validators={{
                  onSubmit: z.date().nullable(),
                }}
                children={({ state, handleBlur, handleChange }) => (
                  <DatePicker
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={state.value}
                  />
                )}
              />
            </>
          )}
          <form.Field
            name="nationalCode"
            validators={{
              onSubmit: z
                .string()
                .min(1, 'کد ملی را وارد کنید')
                .refine(
                  (value) => nationalCodeValidator(value),
                  'کد ملی نامعتبر است'
                ),
            }}
            children={({ state, handleBlur, handleChange }) => (
              <Input
                required
                label="کد ملی"
                error={state.meta.errors}
                helperText={state.meta.errors[0]}
                placeholder="مثال: 1234567890"
                {...state}
                onBlur={handleBlur}
                onChange={(e) => handleChange(e.target.value)}
              />
            )}
          />

          {mode === 'update' && (
            <>
              <form.Field
                name="gender"
                validators={{
                  onSubmit: z.string().nullable(),
                }}
                children={({ state, handleBlur, handleChange }) => (
                  <Select
                    size="sm"
                    required
                    label="جنسیت"
                    options={[
                      { value: '', label: 'انتخاب کنید' },
                      { value: 'male', label: 'مرد' },
                      { value: 'female', label: 'زن' },
                    ]}
                    value={state.value ?? ''}
                    onBlur={handleBlur}
                    onChange={(e) =>
                      handleChange(e.target.value as 'male' | 'female')
                    }
                  />
                )}
              />
              <form.Field
                name="maritalStatus"
                validators={{
                  onSubmit: z.string().nullable(),
                }}
                children={({ state, handleBlur, handleChange }) => (
                  <Select
                    size="sm"
                    required
                    label="وضعیت تاهل"
                    options={[
                      { value: '', label: 'انتخاب کنید' },
                      { value: 'single', label: 'مجرد' },
                      { value: 'married', label: 'متاهل' },
                    ]}
                    value={state.value ?? ''}
                    onBlur={handleBlur}
                    onChange={(e) =>
                      handleChange(e.target.value as 'single' | 'married')
                    }
                  />
                )}
              />
              <form.Field
                name="childrenCount"
                validators={{
                  onSubmit: z.number().nullable(),
                }}
                children={({ state, handleBlur, handleChange }) => (
                  <Input
                    error={!!state.meta.errors.length}
                    helperText={state.meta.errors.join(',')}
                    label="تعداد فرزندان"
                    type="number"
                    value={state.value || ''}
                    onBlur={handleBlur}
                    onChange={(e) => handleChange(+e.target.value)}
                  />
                )}
              />
            </>
          )}

          <form.Field
            name="mobile"
            validators={{
              onSubmit: z
                .string()
                .min(1, 'شماره موبایل را وارد کنید')
                .regex(
                  new RegExp(
                    /((0?9)|(\+?989))((14)|(13)|(12)|(19)|(18)|(17)|(15)|(16)|(11)|(10)|(90)|(91)|(92)|(93)|(94)|(95)|(96)|(32)|(30)|(33)|(35)|(36)|(37)|(38)|(39)|(00)|(01)|(02)|(03)|(04)|(05)|(41)|(20)|(21)|(22)|(23)|(31)|(34)|(9910)|(9911)|(9913)|(9914)|(9999)|(999)|(990)|(9810)|(9811)|(9812)|(9813)|(9814)|(9815)|(9816)|(9817)|(998))\d{7}/g
                  ),
                  'شماره موبایل نامعتبر است'
                ),
            }}
            children={({ state, handleBlur, handleChange }) => (
              <Input
                required
                type="tel"
                error={state.meta.errors}
                helperText={state.meta.errors[0]}
                label="شماره موبایل"
                placeholder="مثال: 09123456789"
                {...state}
                onBlur={handleBlur}
                onChange={(e) => handleChange(e.target.value)}
              />
            )}
          />
          {mode === 'update' && (
            <>
              <form.Field
                name="insuranceNumber"
                validators={{
                  onSubmit: z.string().nullable(),
                }}
                children={({ state, handleBlur, handleChange }) => (
                  <Input
                    error={!!state.meta.errors.length}
                    helperText={state.meta.errors.join(',')}
                    label="شماره بیمه"
                    type="text"
                    value={state.value || ''}
                    onBlur={handleBlur}
                    onChange={(e) => handleChange(e.target.value)}
                  />
                )}
              />
              <form.Field
                name="personnelCode"
                validators={{
                  onSubmit: z.string().nullable(),
                }}
                children={({ state, handleBlur, handleChange }) => (
                  <Input
                    error={!!state.meta.errors.length}
                    helperText={state.meta.errors.join(',')}
                    label="کد پرسنلی"
                    type="text"
                    value={state.value ?? ''}
                    onBlur={handleBlur}
                    onChange={(e) => handleChange(e.target.value)}
                  />
                )}
              />
            </>
          )}
        </div>
        <div className="mt-5 flex gap-5 justify-end">
          {mode === 'update' && (
            <Button
              onClick={form.reset}
              color="ghost"
              className="w-36"
              type="button"
            >
              بازنشانی اطلاعات
            </Button>
          )}
          <Button
            type="submit"
            color="primary"
            loading={isCreateUserPending || isUpdateUserPending}
            className={clsx('w-36', { 'w-auto': mode === 'create' })}
          >
            {mode === 'create' ? (
              <>
                ثبت اطلاعات اولیه و ادامه <BiChevronLeft size={20} />
              </>
            ) : (
              'ویرایش'
            )}
          </Button>
        </div>
      </form>
    </>
  );
};

export default UserForm;
