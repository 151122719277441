import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';

const previewPayslips = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post<PayslipPreview[]>('/payslips/preview', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const usePreviewPayslipsMutation = () => {
  return useMutation({
    mutationFn: (file: File) => previewPayslips(file),
  });
};

export default usePreviewPayslipsMutation;

export interface PayslipPreview {
  base: Base[];
  deductions: Deduction[];
  earnings: Earning[];
  user: User;
  userId: number;
  baseSalary: number;
  netPay: number;
  totalDeductions: number;
  totalEarnings: number;
}

interface User {
  personnelCode: number;
  nationalCode: string;
  firstName: string;
  lastName: string;
  fatherName: string;
  mobile: string;
}

interface Earning {
  amount: number;
  payslipId: number;
  payslipEarningField: PayslipBaseField;
  payslipEarningFieldId: number;
}

interface Deduction {
  amount: number;
  payslipId: number;
  payslipDeductionField: PayslipBaseField;
  payslipDeductionFieldId: number;
}

interface Base {
  value: number;
  payslipId: number;
  payslipBaseField: PayslipBaseField;
  payslipBaseFieldId: number;
}

interface PayslipBaseField {
  name: string;
}
