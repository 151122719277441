import PayrollStatement from '../../../PayrollStatement';
import { Pagination } from '@ark-ui/react';
import { CgChevronLeft, CgChevronRight } from 'react-icons/cg';
import { Button, Dialog } from '@/components';
import { PayslipPreview } from '@/modules/payslip/services/use-preview-payslips.mutation';
import { useEffect, useMemo, useState } from 'react';
import { PayrollStatementProps } from '../../../PayrollStatement/PayrollStatement';

const PayrollStatementPreviewDialog = ({
  onClose,
  open,
  payslips,
  onSubmit,
  isSubmitting,
  payPeriod,
}: {
  open: boolean;
  onClose: () => void;
  payslips: PayslipPreview[];
  onSubmit: () => void;
  isSubmitting: boolean;
  payPeriod: {
    month: number;
    year: number;
  };
}) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [open]);

  const payrollStatement = useMemo<PayrollStatementProps | null>(() => {
    if (payslips.length === 0) {
      return null;
    }
    const payslip = payslips[page - 1];
    if (!payslip) {
      return null;
    }
    return {
      bases: payslip.base.map((base) => ({
        label: base.payslipBaseField.name,
        value: base.value,
      })),
      baseSalary: payslip.baseSalary,
      deductions: payslip.deductions.map((deduction) => ({
        label: deduction.payslipDeductionField.name,
        amount: deduction.amount,
      })),
      earnings: payslip.earnings.map((earning) => ({
        label: earning.payslipEarningField.name,
        amount: earning.amount,
      })),
      payPeriod,
      netPay: payslip.netPay,
      totalDeduction: payslip.totalDeductions,
      totalEarnings: payslip.totalEarnings,
      user: {
        ...payslip.user,
        personnelCode: `${payslip.user.personnelCode}`,
      },
    } as PayrollStatementProps;
  }, [page, payPeriod, payslips]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="پیش نمایش"
      className="modal z-[998] [&_>div]:max-w-screen-md"
      actions={
        <>
          <Button onClick={onClose}>بستن</Button>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={onSubmit}
            color="primary"
          >
            ثبت نهایی
          </Button>
        </>
      }
    >
      {payrollStatement && <PayrollStatement {...payrollStatement} />}
      <Pagination.Root
        onPageChange={(details) => setPage(details.page)}
        page={page}
        className="join flex justify-center items-center mt-4"
        count={payslips.length}
        pageSize={1}
        siblingCount={2}
      >
        <Pagination.PrevTrigger className="join-item btn btn-square btn-sm">
          <CgChevronRight />
        </Pagination.PrevTrigger>
        <Pagination.Context>
          {(pagination) =>
            pagination.pages.map((page, index) =>
              page.type === 'page' ? (
                <Pagination.Item
                  className="join-item btn btn-square aria-[current]:btn-primary  btn-sm"
                  key={index}
                  {...page}
                >
                  {page.value}
                </Pagination.Item>
              ) : (
                <Pagination.Ellipsis
                  className="join-item btn btn-square btn-disabled  btn-sm"
                  key={index}
                  index={index}
                >
                  ...
                </Pagination.Ellipsis>
              )
            )
          }
        </Pagination.Context>
        <Pagination.NextTrigger className="join-item btn btn-square  btn-sm">
          <CgChevronLeft />
        </Pagination.NextTrigger>
      </Pagination.Root>
    </Dialog>
  );
};

export default PayrollStatementPreviewDialog;
