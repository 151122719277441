import { Link, useLocation } from '@tanstack/react-router';
import { IoExit, IoHome, IoPerson } from 'react-icons/io5';
import { MdPayments } from 'react-icons/md';
import { logout } from '@/modules';
import clsx from 'clsx';

const BottomNavigation = () => {
  const location = useLocation();
  return (
    <div className="btm-nav z-20 btm-nav-md drop-shadow-2xl sm:hidden text-sm  text-gray-500">
      <Link
        to="/dashboard/home"
        className={clsx('', {
          active: location.pathname === '/dashboard/home',
        })}
      >
        <IoHome />
        <span>خانه</span>
      </Link>
      <Link
        to="/dashboard/payslips"
        search={{ me: true }}
        className={clsx('', {
          active: location.pathname === '/dashboard/payslips',
        })}
      >
        <MdPayments />
        <span>فیش حقوق</span>
      </Link>
      <Link to="/dashboard/profile">
        <IoPerson />

        <span>پروفایل</span>
      </Link>
      <button onClick={logout}>
        <IoExit />
        <span>خروج</span>
      </button>
    </div>
  );
};

export default BottomNavigation;
