import { Breadcrumbs, Card } from 'react-daisyui';
import { UploadPayslipsData } from '../features';
import { Link } from '@tanstack/react-router';

const NewPayslipsPage = () => {
  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item>حقوق و دستمزد</Breadcrumbs.Item>
        <Breadcrumbs.Item>
          <Link to="/dashboard/payslips">فیش های حقوق </Link>
        </Breadcrumbs.Item>
        <Breadcrumbs.Item>ثبت فیش های حقوق</Breadcrumbs.Item>
      </Breadcrumbs>
      <Card className="mt-5">
        <Card.Body>
          <Card.Title className="mb-5">ثبت فیش های حقوق</Card.Title>
          <UploadPayslipsData />
        </Card.Body>
      </Card>
    </div>
  );
};

export default NewPayslipsPage;
