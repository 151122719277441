import { api } from '@/config/apiCliant';
import { queryClient } from '@/main';
import { UpdateUserPayload } from '@/modules/user/services/use-update-user.mutation';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { getOrganizationUserProfileOptions } from './use-get-organization-user-profile.query';

const updateOrganizationProfile = (data: UpdateUserPayload) =>
  api.patch('/org-auth/profile/user', data);

const useUpdateOrganizationProfileMutation = () => {
  return useMutation({
    mutationKey: ['update-organization-profile'],
    mutationFn: (data: UpdateUserPayload) => updateOrganizationProfile(data),
    onSuccess: () => {
      toast.success('ویرایش با موفقیت انجام شد');
      queryClient.refetchQueries(getOrganizationUserProfileOptions());
    },
    onError: (e: AxiosError<{ message?: string; messages?: string[] }>) => {
      if (e.response?.data.messages) toast.error(e.response?.data.messages[0]);
      else toast.error('ویرایش کاربر با مشکل مواجه شد');
    },
  });
};

export default useUpdateOrganizationProfileMutation;
