import { User } from '@/modules/user/services/use-get-users.query';
import { months, years } from '../UploadPayslipsData/constants';
import { Button } from '@/components';
import { FcPrint } from 'react-icons/fc';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { useGetOrgAuthQuery } from '@/modules/auth';
import { useGetOrganizationSettings } from '@/modules/organization-settings';
import { apiBaseUrl } from '@/env';
export interface PayrollStatementProps {
  user: Partial<User>;
  bases: {
    value: string | number;
    label: string;
  }[];
  deductions: {
    amount: number;
    label: string;
  }[];
  earnings: {
    amount: number;
    label: string;
  }[];
  payPeriod: {
    month: string | number;
    year: string | number;
  };
  netPay: number;
  baseSalary: number;
  totalEarnings: number;
  totalDeduction: number;
}

const PayrollStatement = ({
  user,
  bases,
  deductions,
  earnings,
  payPeriod,
  netPay,
  baseSalary,
  totalEarnings,
  totalDeduction,
}: PayrollStatementProps) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const { data: org } = useGetOrgAuthQuery();
  const { data: settings } = useGetOrganizationSettings();

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: `فیش حقوقی ${user?.firstName} ${user?.lastName}-${payPeriod.month} ${payPeriod.year}`,
  });

  return (
    <div>
      <div className="flex justify-end gap-2">
        <Button onClick={() => handlePrint()} shape="circle" color="ghost">
          <FcPrint size={20} />
        </Button>
      </div>
      <div
        dir="rtl"
        ref={componentRef}
        className="flex  flex-col  w-full a4-page bg-white  shadow-md print:shadow-none print:border-0"
      >
        <div className="flex items-center justify-between gap-2 border-t border-x text-sm text-center py-1 px-3 font-bold border-black">
          {org?.name}{' '}
          {settings?.showLogo.value && org?.logo && (
            <img
              className="w-12 h-12"
              src={`${apiBaseUrl}/${org?.logo}`}
              alt={org?.name}
            />
          )}
        </div>
        <div className="grid grid-cols-3  text-right  text-xs">
          <div className="border border-black p-2 flex flex-col gap-2">
            <p>
              <strong>نام و نام خانوادگی:</strong> {user?.firstName}{' '}
              {user?.lastName}
            </p>
            {user.maritalStatus && (
              <p>
                <strong>وضعیت تأهل:</strong>{' '}
                {user?.maritalStatus === 'married' ? 'مجرد' : 'متاهل'}
              </p>
            )}
            {user.childrenCount && (
              <p>
                <strong>تعداد فرزندان:</strong> {user?.childrenCount}
              </p>
            )}
          </div>
          <div className="border border-black p-2 flex flex-col gap-2">
            <p>
              <strong>کد ملی:</strong> {user.nationalCode}
            </p>
            {user.personnelCode && (
              <p>
                <strong>شماره پرسنلی:</strong> {user?.personnelCode}
              </p>
            )}
            {/* <p>
            <strong>سمت:</strong> {user?.position}
          </p> */}
          </div>
          <div className="border border-black p-2 flex flex-col gap-2">
            <p>
              <strong>فیش حقوقی:</strong>{' '}
              {months.find((m) => m.value === payPeriod.month)?.label}{' '}
              {years.find((y) => y.value === payPeriod.year)?.label}
            </p>
            {user.insuranceNumber && (
              <p>
                <strong>شماره بیمه:</strong> {user?.insuranceNumber}
              </p>
            )}
            {user.bankAccount && (
              <p>
                <strong>شماره حساب:</strong> {user?.bankAccount}
              </p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 text-sm mt-1  grid-rows-subgrid items-stretch">
          <div className="flex flex-col border border-black">
            <h2 className="border-b border-black text-xs font-bold p-1 text-center ">
              اطلاعات پایه
            </h2>
            <div className="flex flex-col justify-between flex-1">
              <ul className="space-y-2 p-2 text-xs">
                {bases
                  .filter((b) => b.value)
                  .map((base) => (
                    <li className="flex justify-between" key={base.label}>
                      <span>{base.label}</span> <span>{base.value}</span>
                    </li>
                  ))}
              </ul>
              <div className="flex justify-between border-t border-black text-xs mb-0 p-3"></div>
            </div>
          </div>
          <div className="flex flex-col border border-black">
            <h2 className="border-b border-black text-xs font-bold p-1 text-center ">
              اضافات (ریال)
            </h2>
            <div className="flex flex-col justify-between flex-1">
              <ul className="space-y-2 p-2 text-xs">
                {
                  <li className="flex justify-between">
                    <span>حقوق پایه</span>{' '}
                    <span>{baseSalary.toLocaleString('fa-IR')}</span>
                  </li>
                }
                {earnings
                  .filter((e) => e.amount)
                  .map((earning) => (
                    <li className="flex justify-between" key={earning.label}>
                      <span>{earning.label}</span>{' '}
                      <span>{earning.amount.toLocaleString('fa-IR')}</span>
                    </li>
                  ))}
              </ul>
              <div className="flex justify-between border-t border-black text-xs mb-0">
                <span className="flex-1 p-1 border-l border-black">
                  جمع اضافات:
                </span>
                <span className="flex-1 p-1 text-center">
                  {totalEarnings.toLocaleString('fa-IR')} ریال
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col border border-black">
            <h2 className="border-b border-black text-xs font-bold p-1 text-center ">
              کسورات (ریال)
            </h2>
            <div className="flex flex-col justify-between flex-1">
              <ul className="space-y-2 p-2 text-xs">
                {deductions
                  .filter((d) => d.amount)
                  .map((deduction) => (
                    <li className="flex justify-between" key={deduction.label}>
                      <span>{deduction.label}</span>{' '}
                      <span>{deduction.amount.toLocaleString('fa-IR')}</span>
                    </li>
                  ))}
              </ul>
              <div className="flex justify-between border-t border-black text-xs mb-0">
                <span className="flex-1 p-1 border-l border-black">
                  جمع کسورات:
                </span>
                <span className="flex-1 p-1 text-center">
                  {totalDeduction.toLocaleString('fa-IR')} ریال
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="border-x border-b p-1 border-black">
          <p className="text-xs font-bold text-center">
            خالص پرداختی: {netPay.toLocaleString('fa-IR')} ریال
          </p>
        </div>
      </div>
    </div>
  );
};

export default PayrollStatement;
