import { useState } from 'react';
import Cookies from 'js-cookie';
import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'react-toastify';

export interface LoginPayload {
  mobile: string;
  password: string;
}

export const login = (credentials: LoginPayload) =>
  api.post<{ access_token: string }>('/auth/login', credentials);

const useLoginMutation = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const { mutate, isPending, error } = useMutation({
    mutationKey: ['login'],
    mutationFn: (payload: LoginPayload) => login(payload),
    onSuccess: (res) => {
      Cookies.set('token', res.data.access_token);
      navigate({ to: '/home' });
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 401) {
        toast.error('شماره موبایل یا رمز عبور اشتباه است');
      }
      const message =
        (error.response?.data as { message?: string })?.message ||
        'An unexpected error occurred';

      setErrorMessage(message); // ذخیره پیام خطا
    },
  });

  return {
    mutate,
    isPending,
    error,
    errorMessage,
  };
};

export default useLoginMutation;
