export const months = [
  {
    value: 1,
    label: 'فروردین',
  },
  {
    value: 2,
    label: 'اردیبهشت',
  },
  {
    value: 3,
    label: 'خرداد',
  },
  {
    value: 4,
    label: 'تیر',
  },
  {
    value: 5,
    label: 'مرداد',
  },
  {
    value: 6,
    label: 'شهریور',
  },
  {
    value: 7,
    label: 'مهر',
  },
  {
    value: 8,
    label: 'آبان',
  },
  {
    value: 9,
    label: 'آذر',
  },
  {
    value: 10,
    label: 'دی',
  },
  {
    value: 11,
    label: 'بهمن',
  },
  {
    value: 12,
    label: 'اسفند',
  },
];

export const years = [
  {
    value: 1390,
    label: '1390',
  },
  {
    value: 1391,
    label: '1391',
  },
  {
    value: 1392,
    label: '1392',
  },
  {
    value: 1393,
    label: '1393',
  },
  {
    value: 1394,
    label: '1394',
  },
  {
    value: 1395,
    label: '1395',
  },
  {
    value: 1396,
    label: '1396',
  },
  {
    value: 1397,
    label: '1397',
  },
  {
    value: 1398,
    label: '1398',
  },
  {
    value: 1399,
    label: '1399',
  },
  {
    value: 1400,
    label: '1400',
  },
  {
    value: 1401,
    label: '1401',
  },
  {
    value: 1402,
    label: '1402',
  },
  {
    value: 1403,
    label: '1403',
  },
  {
    value: 1404,
    label: '1404',
  },
];
