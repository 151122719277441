import { api } from '@/config/apiCliant';
import { Payslip } from '../types';
import { useQuery } from '@tanstack/react-query';

const getPayslipAuthUser = (payslipId: number) =>
  api.get<Payslip>(`/payslips/me/${payslipId}`);

const useGetPayslipAuhUserQuery = (payslipId?: number) => {
  return useQuery({
    queryKey: ['payslip-auth-user', payslipId],
    queryFn: () => getPayslipAuthUser(payslipId!),
    select: (res) => res.data,
    enabled: !!payslipId,
  });
};

export default useGetPayslipAuhUserQuery;
