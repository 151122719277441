import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/auth')({
  component: Auth,
});

function Auth() {
  return (
    <div className="flex flex-col md:flex-row h-screen">
      <div className="flex flex-col justify-center items-center w-full md:w-1/2 bg-white p-8">
        <Outlet />
      </div>
      <div className="w-full md:w-4/5 bg-primary flex justify-center items-center p-8">
        <div className="text-white text-center">
          <h2 className="text-4xl font-bold">به ParsHR خوش آمدید</h2>
        </div>
      </div>
    </div>
  );
}
