import { createFileRoute, redirect } from '@tanstack/react-router';
import Cookies from 'js-cookie';

export const Route = createFileRoute('/')({
  component: () => <div>Hello /!</div>,
  beforeLoad: () => {
    const token = Cookies.get('token');
    if (token) {
      return redirect({ to: '/dashboard/home' });
    } else {
      return redirect({ to: '/auth/login' });
    }
  },
});
