import { api } from '@/config/apiCliant';
import { queryClient } from '@/main';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { getOrganizationSettingsQueryOptions } from './use-get-organization-settings.query';

const updateOrganizationSetting = (payload: {
  value: string | boolean;
  id: number;
}) =>
  api.patch(`/organization-settings/${payload.id}`, {
    value: payload.value,
  });

const useUpdateOrganizationSetting = () => {
  return useMutation({
    mutationKey: ['update-organization-setting'],
    mutationFn: (payload: { value: string | boolean; id: number }) =>
      updateOrganizationSetting(payload),
    onSuccess: () => {
      toast.success('تنظیمات با موفقیت ویرایش شد');
      queryClient.refetchQueries(getOrganizationSettingsQueryOptions());
    },
    onError: () => {
      toast.error('ویرایش تنظیمات با مشکل مواجه شد');
    },
  });
};

export default useUpdateOrganizationSetting;
