export function nationalCodeValidator(val: string) {
  const allDigitEqual = [
    '0000000000',
    '1111111111',
    '2222222222',
    '3333333333',
    '4444444444',
    '5555555555',
    '6666666666',
    '7777777777',
    '8888888888',
    '9999999999',
  ];
  const codeMelliPattern = /^([0-9]{10})+$/;
  if (allDigitEqual.indexOf(val) != -1 || !codeMelliPattern.test(val)) {
    return false;
  }
  const chArray = Array.from(val);
  const num0 = parseInt(chArray[0]) * 10;
  const num2 = parseInt(chArray[1]) * 9;
  const num3 = parseInt(chArray[2]) * 8;
  const num4 = parseInt(chArray[3]) * 7;
  const num5 = parseInt(chArray[4]) * 6;
  const num6 = parseInt(chArray[5]) * 5;
  const num7 = parseInt(chArray[6]) * 4;
  const num8 = parseInt(chArray[7]) * 3;
  const num9 = parseInt(chArray[8]) * 2;
  const a = parseInt(chArray[9]);
  const b = num0 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9;
  const c = b % 11;
  return (c < 2 && a == c) || (c >= 2 && 11 - c == a);
}

export const mobileRegex =
  /((0?9)|(\+?989))((14)|(13)|(12)|(19)|(18)|(17)|(15)|(16)|(11)|(10)|(90)|(91)|(92)|(93)|(94)|(95)|(96)|(32)|(30)|(33)|(35)|(36)|(37)|(38)|(39)|(00)|(01)|(02)|(03)|(04)|(05)|(41)|(20)|(21)|(22)|(23)|(31)|(34)|(9910)|(9911)|(9913)|(9914)|(9999)|(999)|(990)|(9810)|(9811)|(9812)|(9813)|(9814)|(9815)|(9816)|(9817)|(998))\d{7}/g;
