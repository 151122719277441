import { FileRoutesByPath, useNavigate } from '@tanstack/react-router';
import {
  Button as ButtonDaisyui,
  Loading,
  ButtonProps as DaisyuiButtonProps,
} from 'react-daisyui';

export interface ButtonProps extends DaisyuiButtonProps {
  loading?: boolean;
  to?: FileRoutesByPath[keyof FileRoutesByPath]['fullPath'];
}

const Button = (props: ButtonProps) => {
  const { loading, children, onClick, to, ...restProps } = props;
  const navigate = useNavigate();

  return (
    <ButtonDaisyui
      size="sm"
      type="button"
      onClick={(e) => {
        if (to) {
          navigate({ to });
        }
        onClick?.(e);
      }}
      {...restProps}
    >
      {children}
      {loading && <Loading />}
    </ButtonDaisyui>
  );
};

export default Button;
