import { api } from '@/config/apiCliant';
import { createPayslipFieldPayload } from './use-create-payslip-field.mutation';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FieldType } from '../types';
import { queryClient } from '@/main';
import { getPayslipBaseFieldsOptions } from './use-get-payslip-base-fields.query';
import { getPayslipDeductionFieldsOptions } from './use-get-payslip-deduction-fields.query';
import { getPayslipEarningFieldsOptions } from './use-get-payslip-earning-fields.query';
import { getPayslipFieldOrdersOptions } from './use-get-payslip-field-orders.query';

const updatePayslipField = async (
  payload: Partial<createPayslipFieldPayload>,
  id: number,
  type: FieldType
) => api.patch(`/payslip-${type}s/fields/${id}`, payload);

const useUpdatePayslipFieldMutation = (type: FieldType, id: number) => {
  return useMutation({
    mutationFn: (payload: Partial<createPayslipFieldPayload>) =>
      updatePayslipField(payload, id, type),
    mutationKey: ['update-payslip-field', id, type],
    onSuccess: () => {
      toast.success('فیلد با موفقیت ویرایش شد');
      if (type === 'base')
        queryClient.refetchQueries(getPayslipBaseFieldsOptions());
      if (type === 'earning')
        queryClient.refetchQueries(getPayslipEarningFieldsOptions());
      if (type === 'deduction')
        queryClient.refetchQueries(getPayslipDeductionFieldsOptions());
      queryClient.refetchQueries(getPayslipFieldOrdersOptions());
    },
  });
};

export default useUpdatePayslipFieldMutation;
