import { Card } from 'react-daisyui';
import { Button, Input, OtpInput } from '@/components';

import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import { CgChevronLeft } from 'react-icons/cg';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { Countdown } from '../../components';
import {
  useRegisterMutation,
  useVerifyRegisterOtpMutation,
} from '../../services';
import { mobileRegex } from '@/utils';

const Register = () => {
  const { mutate: register, isPending: isRegisterPending } =
    useRegisterMutation();
  const { mutate: verifyRegisterOtp, isPending } =
    useVerifyRegisterOtpMutation();
  const [otpStep, setOtpStep] = useState(false);
  const [canResend, setCanResend] = useState(false);

  const form = useForm({
    defaultValues: {
      mobile: '',
      password: '',
      otp: '',
    },
    validatorAdapter: zodValidator({
      transformErrors: (errors) => {
        return errors[0].message;
      },
    }),
    onSubmit: ({ value }) => {
      if (!otpStep) {
        register(
          {
            mobile: value.mobile,
            password: value.password,
          },
          {
            onSuccess: () => {
              setOtpStep(true);
            },
          }
        );
      } else {
        verifyRegisterOtp({
          verificationCode: value.otp,
          mobile: value.mobile,
        });
      }
    },
  });

  const backHandler = () => {
    setOtpStep(false);
    form.deleteField('otp');
  };

  return (
    <div className=" h-screen   flex flex-col  justify-center w-full lg:w-96  ">
      <Card className="prose">
        <Card.Body>
          <Card.Title className="text-center w-full block relative">
            ParsHR
            {otpStep && (
              <Button
                onClick={backHandler}
                color="ghost"
                shape="circle"
                className="absolute right-0 -top-1/2 "
              >
                <BiArrowBack size={20} className="rotate-180" />
              </Button>
            )}
          </Card.Title>

          <div
            className="divider font-semibold text-lg
          mt-10 mb-5"
          >
            ثبت نام
          </div>
          {otpStep && (
            <small>کد به شماره {form.getFieldValue('mobile')} ارسال شد.</small>
          )}
          <p className="font-semibold text-justify">
            {!otpStep && (
              <>
                برای استفاده از خدمات ParsHR، ثبت نام کنید. در صورتی که ثبت نام
                کرده اید{' '}
                <Link to="/auth/login" className="btn-link">
                  <span className="mx-1">ورود</span>
                </Link>{' '}
                کنید.
              </>
            )}
            {otpStep && <>کد ارسال شده را وارد کنید</>}
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.handleSubmit();
            }}
          >
            {!otpStep && (
              <div className="flex flex-col">
                <form.Field
                  name="mobile"
                  validators={{
                    onSubmit: z
                      .string()
                      .min(1, ' شماره موبایل نمیتواند خالی باشد')
                      .regex(mobileRegex, 'شماره موبایل صحیح نمی باشد'),
                  }}
                  children={(field) => (
                    <Input
                      autoFocus
                      label="شماره موبایل"
                      onChange={(e) => field.handleChange(e.target.value)}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      name={field.name}
                      placeholder="شماره موبایل"
                      type="text"
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors[0]}
                    />
                  )}
                />
                <form.Field
                  name="password"
                  validators={{
                    onSubmit: z
                      .string({
                        required_error: 'رمز عبور نمی تواند خالی باشد',
                      })
                      .min(1, 'رمز عبور را وارد کنید')
                      .min(8, 'رمز عبور باید حداقل 8 کاراکتر باشد'),
                  }}
                  children={(field) => (
                    <Input
                      label="رمز عبور"
                      placeholder="رمز عبور"
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.target.value)}
                      onBlur={field.handleBlur}
                      name={field.name}
                      type="password"
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors[0]}
                    />
                  )}
                />
              </div>
            )}
            {otpStep && (
              <form.Field
                name="otp"
                validators={{
                  onSubmit: z
                    .string()
                    .min(1, 'کد را وارد کنید')
                    .min(4, 'کد صحیح نمی باشد'),
                }}
                children={({ handleChange, state }) => (
                  <OtpInput
                    error={state.meta.errors[0]}
                    onChange={handleChange}
                    value={state.value}
                  />
                )}
              />
            )}

            {otpStep && (
              <div className="flex justify-center">
                {canResend ? (
                  <Button
                    color="ghost"
                    loading={isRegisterPending}
                    disabled={isRegisterPending}
                    onClick={() =>
                      register(
                        {
                          mobile: form.getFieldValue('mobile'),
                          password: form.getFieldValue('password'),
                        },
                        {
                          onSuccess: () => {
                            setCanResend(false);
                          },
                        }
                      )
                    }
                  >
                    ارسال مجدد کد
                  </Button>
                ) : (
                  <Countdown
                    onEnd={() => {
                      setCanResend(true);
                    }}
                    start={otpStep && !canResend}
                    time={2 * 60 * 1000}
                  />
                )}
              </div>
            )}
            <Button
              className="mt-5 float-end btn-block"
              color="primary"
              type="submit"
              loading={isRegisterPending || isPending}
              disabled={isRegisterPending || isPending}
              size="md"
            >
              {!otpStep && (
                <>
                  تایید و ادامه <CgChevronLeft size={16} />
                </>
              )}
              {otpStep && <>تایید</>}
            </Button>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Register;
