import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FieldType } from '../types';
import { queryClient } from '@/main';
import { getPayslipBaseFieldsOptions } from './use-get-payslip-base-fields.query';
import { getPayslipEarningFieldsOptions } from './use-get-payslip-earning-fields.query';
import { getPayslipDeductionFieldsOptions } from './use-get-payslip-deduction-fields.query';
import { getPayslipFieldOrdersOptions } from './use-get-payslip-field-orders.query';

export interface createPayslipFieldPayload {
  name: string;
  description?: string;
}

const createPayslipField = (
  payload: createPayslipFieldPayload,
  type: FieldType
) => api.post(`/payslip-${type}s/fields`, payload);

const useCreatePayslipFieldMutation = (type: FieldType) => {
  return useMutation({
    mutationFn: (payload: createPayslipFieldPayload) =>
      createPayslipField(payload, type),
    mutationKey: ['create-payslip-field', type],
    onSuccess: () => {
      toast.success('فیلد با موفقیت ایجاد شد');
      if (type === 'base')
        queryClient.refetchQueries(getPayslipBaseFieldsOptions());
      if (type === 'earning')
        queryClient.refetchQueries(getPayslipEarningFieldsOptions());
      if (type === 'deduction')
        queryClient.refetchQueries(getPayslipDeductionFieldsOptions());
      queryClient.refetchQueries(getPayslipFieldOrdersOptions());
    },
  });
};

export default useCreatePayslipFieldMutation;
