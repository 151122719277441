import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';

export function SortableItem({
  id,
  name,
  index,
  type,
}: {
  id: number;
  name: string;
  index: number;
  type: string;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={clsx(
        'flex min-w-52 flex-1 lg:flex-none h-10  justify-between items-center p-2 border-b text-gray-100',
        {
          'bg-success': type === 'earning_field_type',
          'bg-error': type === 'deduction_field_type',
          'bg-primary': type === 'base_field_type',
          'bg-secondary': type === 'user_field_type',
          'bg-info': type === 'general_field_type',
        }
      )}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <span className="w-48 overflow-ellipsis line-clamp-1">{name}</span>
      <span>{index}</span>
    </div>
  );
}
