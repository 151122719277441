import { Card, Input } from 'react-daisyui';
import { Button, OtpInput } from '@/components';
import {
  useLoginByOtpMutation,
  useLoginMutation,
  useSendOtpMutation,
} from '../../services';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import clsx from 'clsx';
import { CgChevronLeft } from 'react-icons/cg';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { Countdown } from '../../components';
import { mobileRegex } from '@/utils';
import { isDisableSignup } from '@/env';

const Login = () => {
  const { mutate: login, isPending: isLoginPending } = useLoginMutation();
  const { mutate: sendOtp, isPending: isSendOtpPending } = useSendOtpMutation();
  const { mutate: loginByOtp } = useLoginByOtpMutation();

  const [step, setStep] = useState<'MOBILE' | 'PASSWORD' | 'OTP'>('MOBILE');

  const [resendOtp, setResendOtp] = useState(false);

  const form = useForm({
    defaultValues: {
      mobile: '',
      password: '',
      otp: '',
    },
    validatorAdapter: zodValidator({
      transformErrors: (errors) => {
        return errors[0].message;
      },
    }),
    onSubmit: ({ value }) => {
      if (step === 'MOBILE') {
        setStep('PASSWORD');
      } else if (step === 'PASSWORD') {
        login(value);
      } else if (step === 'OTP') {
        loginByOtp(value);
      }
    },
  });

  const backHandler = () => {
    if (step === 'PASSWORD') {
      setStep('MOBILE');
      form.deleteField('password');
    }
    if (step === 'OTP') {
      form.deleteField('password');
      form.deleteField('otp');
      setStep('MOBILE');
    }
  };

  const sendOtpHandler = () => {
    setResendOtp(false);
    sendOtp(
      { mobile: form.getFieldValue('mobile') },
      {
        onSuccess: () => {
          form.deleteField('password');
          setStep('OTP');
        },
      }
    );
  };

  return (
    <div className=" h-screen   flex flex-col  justify-center w-full lg:w-96  ">
      <Card className="prose">
        <Card.Body>
          <Card.Title className="text-center w-full block relative">
            ParsHR
            {step !== 'MOBILE' && (
              <Button
                onClick={backHandler}
                color="ghost"
                shape="circle"
                className="absolute right-0 -top-1/2 "
              >
                <BiArrowBack size={20} className="rotate-180" />
              </Button>
            )}
          </Card.Title>

          <div
            className="divider font-semibold text-lg
          mt-10 mb-5"
          >
            ورود
          </div>
          {step === 'OTP' && (
            <small>کد به شماره {form.getFieldValue('mobile')} ارسال شد.</small>
          )}
          <p className="font-semibold text-justify">
            {step === 'MOBILE' && (
              <>
                برای استفاده از خدمات ParsHR، وارد حساب کاربری خود شوید.{' '}
                {!isDisableSignup && (
                  <>
                    در صورت نداشتن حساب کاربری{' '}
                    <Link to="/auth/register" className="btn-link">
                      <span className="mx-1">ثبت نام</span>
                    </Link>{' '}
                    کنید.
                  </>
                )}
              </>
            )}
            {step === 'PASSWORD' && <>رمز عبور را وارد کنید</>}
            {step === 'OTP' && <>کد ارسال شده را وارد کنید</>}
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.handleSubmit();
            }}
          >
            {step === 'MOBILE' && (
              <form.Field
                name="mobile"
                validators={{
                  onSubmit: z
                    .string()
                    .min(1, ' شماره موبایل نمیتواند خالی باشد')
                    .regex(mobileRegex, 'شماره موبایل صحیح نمی باشد'),
                }}
                children={(field) => (
                  <label className="label-text flex flex-col gap-2">
                    <Input
                      autoFocus
                      onChange={(e) => field.handleChange(e.target.value)}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      name={field.name}
                      placeholder="شماره موبایل"
                      type="text"
                      className={clsx('input-bordered', {
                        'input-error': field.state.meta.errors.length,
                      })}
                    />
                    {!!field.state.meta.errors.length && (
                      <span className="label-text-alt text-error">
                        {field.state.meta.errors[0]}
                      </span>
                    )}
                  </label>
                )}
              />
            )}
            {step === 'PASSWORD' && (
              <form.Field
                name="password"
                validators={{
                  onSubmit: z
                    .string({
                      required_error: 'رمز عبور نمی تواند خالی باشد',
                    })
                    .min(1, 'رمز عبور را وارد کنید')
                    .min(8, 'رمز عبور باید حداقل 8 کاراکتر باشد'),
                }}
                children={(field) => (
                  <label className="label-text flex flex-col gap-2">
                    <Input
                      autoFocus
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.target.value)}
                      onBlur={field.handleBlur}
                      name={field.name}
                      type="password"
                      className={clsx('input-bordered', {
                        'input-error': field.state.meta.errors.length,
                      })}
                    />
                    {!!field.state.meta.errors.length && (
                      <span className="label-text-alt text-error">
                        {field.state.meta.errors[0]}
                      </span>
                    )}
                  </label>
                )}
              />
            )}

            {step === 'PASSWORD' && (
              <div className="flex flex-col items-start mt-5">
                <Button
                  type="button"
                  size="xs"
                  color="ghost"
                  className="text-secondary"
                  onClick={sendOtpHandler}
                  loading={isSendOtpPending}
                  disabled={isSendOtpPending}
                >
                  ورود با رمز یک‌بار‌مصرف
                  <CgChevronLeft size={12} />
                </Button>
                <Link
                  className="text-secondary btn btn-ghost btn-xs"
                  to="/auth/reset-password"
                >
                  فراموشی رمز عبور
                  <CgChevronLeft size={12} />
                </Link>
              </div>
            )}

            {step === 'OTP' && (
              <form.Field
                name="otp"
                validators={{
                  onSubmit: z
                    .string()
                    .min(1, 'کد را وارد کنید')
                    .min(4, 'کد صحیح نمی باشد'),
                }}
                children={({ state, handleChange }) => (
                  <OtpInput
                    error={state.meta.errors[0]}
                    onChange={handleChange}
                    value={state.value}
                  />
                )}
              />
            )}
            {step === 'OTP' && (
              <div className="flex flex-col items-start mt-5">
                <Button
                  type="button"
                  size="xs"
                  color="ghost"
                  className="text-secondary"
                  onClick={() => {
                    form.deleteField('otp');
                    setStep('PASSWORD');
                  }}
                >
                  ورود با رمز عبور
                  <CgChevronLeft size={12} />
                </Button>
              </div>
            )}

            {step === 'OTP' && !resendOtp && (
              <Countdown
                start={step === 'OTP'}
                time={2 * 60 * 1000}
                onEnd={() => setResendOtp(true)}
              />
            )}
            {step === 'OTP' && resendOtp && (
              <div className="flex justify-center mt-2">
                <Button
                  type="button"
                  size="xs"
                  color="ghost"
                  className="text-secondary"
                  onClick={sendOtpHandler}
                  loading={isSendOtpPending}
                  disabled={isSendOtpPending}
                >
                  ارسال مجدد
                </Button>
              </div>
            )}

            <Button
              className="mt-5 float-end btn-block"
              loading={isLoginPending}
              color="primary"
              type="submit"
              size="md"
            >
              {step === 'MOBILE' && (
                <>
                  تایید و ادامه <CgChevronLeft size={16} />
                </>
              )}
              {(step === 'PASSWORD' || step === 'OTP') && <>تایید</>}
            </Button>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
