import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export interface Image {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  originalname: string;
  filename: string;
  path: string;
  size: number;
  mimetype: string;
}
const uploadImage = (image: File) => {
  const formData = new FormData();
  formData.append('file', image);
  return api.post<Image>('/images/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const useUploadImageMutation = () => {
  return useMutation({
    mutationFn: (image: File) => uploadImage(image),
    onError: (
      err: AxiosError<{ error: string; message: string; statusCode: number }>
    ) => {
      if (
        err.response?.status === 413 &&
        err.response.data.message === 'File too large'
      ) {
        toast.error('حجم فایل بیشتر از حجم مجاز است');
      } else {
        toast.error(err.response?.data.message || 'مشکلی پیش آمده است');
      }
    },
  });
};

export default useUploadImageMutation;
