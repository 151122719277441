import { Link, useParams } from '@tanstack/react-router';
import { Breadcrumbs } from 'react-daisyui';
import { UserInfo } from '../features';
import { useGetUserQuery } from '../services';

const UserPage = () => {
  const params = useParams({
    from: '/dashboard/users/$user',
    select: (params) => params.user,
  });

  const { data: user } = useGetUserQuery(Number(params));

  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item>
          <Link to="/dashboard/users">کاربران</Link>
        </Breadcrumbs.Item>
        <Breadcrumbs.Item>
          {user?.firstName + ' ' + user?.lastName}
        </Breadcrumbs.Item>
      </Breadcrumbs>
      <UserInfo />
    </div>
  );
};

export default UserPage;
