import { api } from '@/config/apiCliant';
import { useQuery } from '@tanstack/react-query';

const gePayslipCount = () => api.get<number>('payslips/count');

const useGetPayslipCountQuery = () => {
  return useQuery({
    queryKey: ['payslip-count'],
    queryFn: gePayslipCount,
    select: (res) => res.data,
  });
};

export default useGetPayslipCountQuery;
