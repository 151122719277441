import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { queryClient } from '@/main';

const deletePayslips = async (ids: number[]) => {
  return await api.delete('/payslips/bulk', { params: { ids } });
};

const useDeletePayslipsMutation = () => {
  return useMutation({
    mutationKey: ['delete-payslips'],
    mutationFn: (ids: number[]) => deletePayslips(ids),
    onSuccess: () => {
      toast.success('فیش های حقوق با موفقیت حذف شد');
      queryClient.refetchQueries({ queryKey: ['payslips'] });
    },
    onError: () => {
      toast.error('خطا در حذف فیش های حقوق');
    },
  });
};

export default useDeletePayslipsMutation;
