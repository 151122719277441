import { Tabs } from 'react-daisyui';
import UserForm from '../UserForm';
import { useGetUserQuery } from '../../services';
import { useParams } from '@tanstack/react-router';

const UserInfo = () => {
  const params = useParams({
    from: '/dashboard/users/$user',
    select: (params) => params.user,
  });

  const { data: user } = useGetUserQuery(Number(params));

  return (
    <div className="mt-5 h-full">
      <Tabs variant="lifted">
        <Tabs.RadioTab
          name="user-info"
          defaultChecked
          label="اطلاعات عمومی"
          contentClassName="bg-base-100 border-base-300 rounded-box p-6"
        >
          <UserForm mode="update" user={user} />
        </Tabs.RadioTab>
        <Tabs.RadioTab
          disabled
          contentClassName="bg-base-100 border-base-300 rounded-box p-6"
          name="user-info"
          label=" پرونده پرسنلی (به زودی)"
        ></Tabs.RadioTab>
      </Tabs>

      {/* <Tabs variant="lifted">
        <Tabs.RadioTab
          name="user-info"
          defaultChecked
          label="اطلاعات عمومی"
          contentClassName="bg-base-100 border-base-300 rounded-box p-6"
        >
          <UserForm mode="update" user={user} />
        </Tabs.RadioTab>
        <Tabs.RadioTab
          contentClassName="bg-base-100 border-base-300 rounded-box p-6"
          name="user-info"
          label="اطلاعات بانکی"
        >
           <div className="grid grid-cols-2 gap-5">
            <Input label="شماره کارت" />
            <Input label="شماره شبا" value={user?.IBAN || ''} />
            <Input label="شماره حساب" value={user?.bankAccount || ''} />
            <Select
              value={user?.bankName || ''}
              label="بانک"
              options={[
                {
                  label: 'بانک صادرات',
                  value: 'bank_saderat',
                },
                {
                  label: 'بانک ملی',
                  value: 'bank_meli',
                },

                {
                  label: 'بانک کارآفرین',
                  value: 'bank_karafarin',
                },
                {
                  label: 'بانک پارسیان',
                  value: 'bank_parsian',
                },
                {
                  label: 'بانک ملت',
                  value: '',
                },

                {
                  label: 'بانک سرمایه',
                  value: 'bank_sarmaye',
                },

                {
                  label: 'بانک تجارت',
                  value: 'bank_tejarat',
                },
              ]}
            />
          </div> 
          <div className="flex justify-end mt-5 gap-5">
            <Button color="ghost" className="w-36" type="button">
              بازنشانی اطلاعات
            </Button>
            <Button type="submit" color="primary" className="w-36">
              ویرایش
            </Button>
          </div>
        </Tabs.RadioTab>
      </Tabs> */}
    </div>
  );
};

export default UserInfo;
