import { PayslipsPage } from '@/modules/payslip';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/dashboard/payslips/')({
  component: PayslipsPage,
  validateSearch: (search: Record<string, unknown>): { me?: boolean } => {
    // validate and parse the search params into a typed state
    return {
      ...search,
    };
  },
});
