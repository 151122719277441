import { api } from '@/config/apiCliant';
import { PaginationResponse } from '@/modules/user/services/use-get-users.query';
import {
  keepPreviousData,
  queryOptions,
  useQuery,
} from '@tanstack/react-query';
import { Payslip } from '../types';

export interface GetPayslipsParams {
  page?: number;
  limit?: number;
  search?: string;
  sort?: string;
  sortBy?: string;
}

const getPayslips = (params: GetPayslipsParams) =>
  api.get<PaginationResponse<Payslip>>('/payslips', {
    params: {
      ...params,
      order: params?.sort,
      orderBy: params?.sortBy,
    },
  });

export const getPayslipsOptions = (
  params: GetPayslipsParams,
  options?: {
    enabled?: boolean;
  }
) =>
  queryOptions({
    queryKey: ['payslips', params],
    queryFn: () => getPayslips(params),
    placeholderData: keepPreviousData,
    select: (res) => res.data,
    ...options,
  });

const useGetPayslipsQuery = (
  params: GetPayslipsParams,
  options?: {
    enabled?: boolean;
  }
) => {
  return useQuery(getPayslipsOptions(params, options));
};

export default useGetPayslipsQuery;
