import CropAvatarDialog from '../CropAvatarDialog';
import { useState } from 'react';
import { useUploadImageMutation } from '@/services';
import { Image } from '@/services/use-upload-image.mutation';
import { BsPersonSquare } from 'react-icons/bs';
import { Avatar } from '@files-ui/react';

const UploadAvatar = ({
  onUploaded,
  loading,
  src,
  emptyLabel,
}: {
  onUploaded: (image: Image) => void;
  loading?: boolean;
  src?: string;
  emptyLabel?: React.ReactNode;
}) => {
  const [cropAvatarDialogOpen, setCropAvatarDialogOpen] = useState(false);

  const [imageFile, setImageFile] = useState<File>();

  const { mutate: uploadImage, isPending: isUploadImagePending } =
    useUploadImageMutation();

  const handleUpload = (file: File) => {
    uploadImage(file, {
      onSuccess: (res) => {
        onUploaded(res.data);
      },
    });
  };

  const handleChangeSource = (selectedFile: File) => {
    setImageFile(selectedFile);
    setCropAvatarDialogOpen(true);
  };

  return (
    <>
      <CropAvatarDialog
        image={imageFile ? URL.createObjectURL(imageFile) : ''}
        open={cropAvatarDialogOpen}
        onClose={() => setCropAvatarDialogOpen(false)}
        onCrop={(croppedImageFile) => {
          handleUpload(croppedImageFile);
          setCropAvatarDialogOpen(false);
        }}
      />
      <Avatar
        style={{
          width: 100,
          height: 100,
          fontFamily: 'IranSansX',
        }}
        changeLabel="ویرایش تصویر"
        isLoading={isUploadImagePending || loading}
        src={src}
        onChange={handleChangeSource}
        emptyLabel={
          emptyLabel || (
            <div>
              <BsPersonSquare size={80} />
            </div>
          )
        }
        variant="square"
      />
    </>
  );
};

export default UploadAvatar;
