const isCreateOrganizationDisable =
  import.meta.env.VITE_DISABLE_CREATE_ORGANIZATION === 'true';
const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;
const sentryEnabled = import.meta.env.VITE_SENTRY_ENABLED === 'true';
const sentryEnvironment = import.meta.env.VITE_SENTRY_ENVIRONMENT;
const sentryDns = import.meta.env.VITE_SENTRY_DNS;
const isDisableSignup = import.meta.env.VITE_DISABLE_SIGN_UP === 'true';

export {
  isCreateOrganizationDisable,
  apiBaseUrl,
  sentryEnabled,
  sentryEnvironment,
  sentryDns,
  isDisableSignup,
};
