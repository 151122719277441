import { BottomNavigation, LoadingOverlay, ThemeToggler } from '@/components';
import { apiBaseUrl, isCreateOrganizationDisable } from '@/env';
import {
  logout,
  useGetAuthInfoQuery,
  useGetOrgAuthQuery,
  useSignToOrganizationMutation,
} from '@/modules';
import { getAuthInfoQueryOptions } from '@/modules/auth/services/use-get-auth-info.query';
import { getOrgAuthQueryOptions } from '@/modules/auth/services/use-get-org-auth.query';
import { useGetOrganizationUserProfileQuery } from '@/modules/profile';
import {
  Link,
  Outlet,
  createFileRoute,
  redirect,
  useLocation,
  useNavigate,
} from '@tanstack/react-router';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { Navbar, Button, Dropdown, Menu, Tooltip } from 'react-daisyui';
import { BiPlus, BiSolidUserAccount } from 'react-icons/bi';
import { BsFillPeopleFill, BsPerson } from 'react-icons/bs';
import { CgOrganisation } from 'react-icons/cg';
import { FcOrganization } from 'react-icons/fc';
import { GrMoney } from 'react-icons/gr';
import { IoHome } from 'react-icons/io5';
import { FaChartLine } from 'react-icons/fa';

export const Route = createFileRoute('/dashboard')({
  component: Dashboard,
  beforeLoad: () => {
    const org_token = Cookies.get('org_token');
    if (!Cookies.get('token')) {
      throw redirect({ to: '/auth/login' });
    }
    if (!org_token) {
      throw redirect({ to: '/home' });
    }
  },
  loader: (opts) => {
    opts.context.queryClient.ensureQueryData(getAuthInfoQueryOptions());
    opts.context.queryClient.ensureQueryData(getOrgAuthQueryOptions());
  },
});

function Dashboard() {
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { data: authInfo, isLoading: isAuthInfoLoading } =
    useGetAuthInfoQuery();
  const { data: organizationUserAuth } = useGetOrganizationUserProfileQuery();
  const toggleVisible = useCallback(() => {
    setVisible((visible) => !visible);
  }, []);

  const isAdmin = organizationUserAuth?.role === 'admin';

  const [salaryAndWagesOpen, setSalaryAndWagesOpen] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('/dashboard/payslips')) {
      setSalaryAndWagesOpen(true);
    }
  }, [location.pathname]);

  const { data: orgAuth, isLoading: isLoadingOrgAuth } = useGetOrgAuthQuery();

  const { mutate: signToOrganization } = useSignToOrganizationMutation();

  if (isAuthInfoLoading || !authInfo || isLoadingOrgAuth || !orgAuth)
    return <LoadingOverlay />;

  return (
    <div className="relative">
      <div
        className="hidden absolute top-0 py-3 shadow-2xl outline-2 outline-primary
       outline-offset-2 outline w-20 h-screen bg-neutral z-10 md:block"
      >
        <div className="bg-neutral h-screen fixed top-0 flex flex-col items-center gap-2  w-20 overflow-hidden">
          {authInfo?.userOrganizations.map((organization) => (
            <div
              className={clsx(
                'cursor-pointer flex flex-col w-full items-center tooltip tooltip-left text-ellipsis',
                {
                  'cursor-default':
                    organization.organization.id === orgAuth?.id,
                }
              )}
              data-tip={organization.organization.name}
              key={organization.organization.id}
              onClick={() => {
                if (organization.organization.id !== orgAuth?.id) {
                  signToOrganization(organization.organization.id, {
                    onSuccess() {
                      window.location.reload();
                    },
                  });
                }
              }}
            >
              {organization.organization.logo ? (
                <div
                  className={clsx('p-2', {
                    'rounded-md': organization.organization.id === orgAuth?.id,
                    'border-2': organization.organization.id === orgAuth?.id,
                  })}
                >
                  <img
                    className={clsx('w-12 h-12 rounded-md', {
                      'rounded-md':
                        organization.organization.id === orgAuth?.id,
                    })}
                    alt={organization.organization.name}
                    src={`${apiBaseUrl}/${organization.organization.logo}`}
                  />
                </div>
              ) : (
                <FcOrganization
                  className={clsx('p-2', {
                    'rounded-md': organization.organization.id === orgAuth?.id,
                    'border-2': organization.organization.id === orgAuth?.id,
                  })}
                  size={60}
                />
              )}

              <span className="line-clamp-1 break-words w-full font-bold text-lg px-2 overflow-ellipsis">
                {organization.organization.name}
              </span>
            </div>
          ))}
          {!isCreateOrganizationDisable && (
            <Tooltip message="ساخت سازمان جدید" position="left">
              <Button
                onClick={() => navigate({ to: '/home/register-organization' })}
                shape="square"
                color="secondary"
                className="mt-2"
              >
                <BiPlus size={20} />
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
      <div
        className={clsx('drawer md:pr-20', {
          'drawer-open': visible,
        })}
      >
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="hidden sm:drawer-side">
          <label
            htmlFor="my-drawer"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>

          <Menu className="p-4 w-60 h-full bg-base-200 text-base-content">
            <Menu.Item>
              <Link to="/dashboard/home" className="flex items-center">
                <IoHome /> خانه
              </Link>
              {isAdmin && (
                <Link to="/dashboard/users" className="flex items-center">
                  <BsFillPeopleFill /> پرسنل
                </Link>
              )}
              <Menu.Details
                open={salaryAndWagesOpen}
                label={
                  <>
                    <GrMoney />
                    حقوق و دستمزد
                  </>
                }
              >
                {isAdmin && (
                  <Menu.Item>
                    <Link
                      to="/dashboard/payslips/new"
                      className={clsx('flex items-center')}
                      activeOptions={{
                        exact: true,
                      }}
                    >
                      ثبت فیش حقوق
                    </Link>
                  </Menu.Item>
                )}
                {isAdmin && (
                  <Menu.Item>
                    <Link
                      to="/dashboard/payslips"
                      className={clsx('flex items-center')}
                      activeOptions={{
                        exact: true,
                      }}
                    >
                      فیش های حقوق
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item>
                  <Link
                    to="/dashboard/payslips"
                    search={{ me: true }}
                    className="flex items-center"
                    activeOptions={{ exact: true }}
                  >
                    فیش های حقوق من
                  </Link>
                </Menu.Item>
                {isAdmin && (
                  <Menu.Item>
                    <Link
                      to="/dashboard/payslips/settings"
                      className="flex items-center"
                      activeOptions={{ exact: true }}
                    >
                      تنظیمات
                    </Link>
                  </Menu.Item>
                )}
              </Menu.Details>
              {isAdmin && (
                <Menu.Details
                  className="disabled cursor-none btn-disabled opacity-50"
                  aria-disabled={true}
                  style={{}}
                  open={salaryAndWagesOpen}
                  label={
                    <div className="flex gap-1">
                      <FaChartLine />
                      ارزیابی عملکرد <small>(به زودی)</small>
                    </div>
                  }
                ></Menu.Details>
              )}
            </Menu.Item>
            {isAdmin && (
              <Menu.Item>
                <Link
                  to="/dashboard/organization-profile"
                  className="flex items-center"
                >
                  <CgOrganisation /> پروفایل سازمان
                </Link>
              </Menu.Item>
            )}
            {/*TODO: This feature disabled for now, will be implemented in the future */}
            {/* <Menu.Item>
              <Link to="/dashboard/settings" className="flex items-center">
                <IoMdSettings /> تنظیمات
              </Link>
            </Menu.Item> */}
            <Menu.Item>
              <Link to="/dashboard/profile" className="flex items-center">
                <BiSolidUserAccount />
                پروفایل
              </Link>
            </Menu.Item>
          </Menu>
        </div>
        <div className="drawer-content">
          <Navbar className="shadow-md rounded-lg sticky top-0 z-10 bg-base-100">
            <div className="flex-1">
              <Button
                onClick={toggleVisible}
                shape="square"
                color="ghost"
                className="hidden sm:block"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block w-5 h-5 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </Button>

              {orgAuth?.name}
            </div>
            <div className="mx-5">
              <ThemeToggler />
            </div>
            <div className="flex-none">
              <Dropdown end>
                <Button
                  tag="label"
                  tabIndex={0}
                  className="avatar"
                  shape="circle"
                >
                  {organizationUserAuth?.avatar ? (
                    <div className="w-10 rounded-full">
                      <img
                        src={`${apiBaseUrl}/${organizationUserAuth?.avatar}`}
                        alt="avatar"
                      />
                    </div>
                  ) : (
                    <BsPerson size={24} />
                  )}
                </Button>
                <Dropdown.Menu className="mt-3 z-[1] w-52 menu-sm">
                  <li>
                    <Link to="/dashboard/profile" className="justify-between">
                      پروفایل
                    </Link>
                  </li>
                  <Dropdown.Item onClick={logout}>خروج</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Navbar>
          <div className="m-5 mb-20 ">
            <Outlet />
          </div>
          <BottomNavigation />
        </div>
      </div>
    </div>
  );
}
