import { DialogHTMLAttributes, PropsWithChildren, useId } from 'react';
import Button from '../Button';
import { IoClose } from 'react-icons/io5';
import clsx from 'clsx';

export interface DialogProps
  extends PropsWithChildren,
    DialogHTMLAttributes<HTMLDialogElement> {
  actions?: React.ReactNode;
  scroll?: boolean;
  width?: HTMLDivElement['style']['width'];
  maxWidth?: HTMLDivElement['style']['maxWidth'];
}

const Dialog = ({
  title,
  children,
  actions,
  scroll,
  width,
  className,
  ...props
}: DialogProps) => {
  const dialogId = useId();

  return (
    <dialog
      id={dialogId}
      className={clsx('modal modal-bottom sm:modal-middle', className)}
      {...props}
    >
      <div
        className={clsx('modal-box', {
          'modal-scroll': scroll,
        })}
        style={{ width }}
      >
        <form method="dialog">
          <Button
            className="absolute top-2 left-2"
            shape="circle"
            color="ghost"
            type="submit"
          >
            <IoClose size={20} />
          </Button>
        </form>
        <h3 className="font-bold text-lg">{title}</h3>
        {children}
        <div className="modal-action gap-2">{actions}</div>
      </div>
    </dialog>
  );
};

export default Dialog;
