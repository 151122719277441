import { api } from '@/config/apiCliant';
import { useQuery } from '@tanstack/react-query';
import { User } from './use-get-users.query';

const getUser = (id: number) => api.get<User>(`/organization-users/${id}`);

const useGetUser = (id: number) => {
  return useQuery({
    queryKey: ['user', id],
    queryFn: () => getUser(id),
    select: (res) => res.data,
  });
};

export default useGetUser;
