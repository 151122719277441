import { ChangeEvent, useRef, useState } from 'react';
import {
  CalendarState,
  CalendarStateOptions,
  useCalendarState,
} from '@react-stately/calendar';
import { useCalendar } from '@react-aria/calendar';
import { useDateFormatter, useLocale } from '@react-aria/i18n';
import {
  CalendarDate,
  parseDate,
  PersianCalendar,
} from '@internationalized/date';
import CalendarGrid from './CalendarGrid';
import { CgChevronLeft, CgChevronRight } from 'react-icons/cg';
import CalendarButton from './CalendarButton';
import { Button } from '@/components';
import Select from '@/components/Select';
import dayjs from 'dayjs';

function Calendar({
  onCancel,
  onConfirm,
  value,
  ...props
}: Partial<Omit<CalendarStateOptions, 'value'>> & {
  onCancel: () => void;
  onConfirm: (value: CalendarDate) => void;
  value?: Date | null;
}) {
  const { locale } = useLocale();
  const [calendarValue, setCalendarValue] = useState<CalendarDate | null>(null);

  const [focusedDate, setFocusedDate] = useState<CalendarDate | null>();

  const state = useCalendarState({
    ...props,
    locale,
    createCalendar: () => new PersianCalendar(),
    focusedValue:
      focusedDate ??
      parseDate(dayjs(!value ? new Date() : value).format('YYYY-MM-DD')),
    value:
      calendarValue ??
      parseDate(dayjs(!value ? new Date() : value).format('YYYY-MM-DD')),
    onFocusChange(date) {
      setFocusedDate(date);
    },
    onChange(date) {
      setCalendarValue(date as CalendarDate);
    },
  });

  const ref = useRef<HTMLDivElement | null>(null);
  const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
    props,
    state
  );

  return (
    <div {...calendarProps} ref={ref}>
      <div className="flex justify-between items-center gap-2">
        <CalendarButton {...prevButtonProps}>
          <CgChevronRight />
        </CalendarButton>
        <div className="flex gap-2">
          <MonthDropdown state={state} />
          <YearDropdown state={state} />
        </div>
        <CalendarButton {...nextButtonProps}>
          <CgChevronLeft />
        </CalendarButton>
      </div>
      <CalendarGrid state={state} />
      <div className="flex justify-end gap-2 m-2">
        <Button onClick={onCancel} size="sm" className="text-xs">
          لغو
        </Button>
        <Button
          onClick={() => state.value && onConfirm(state.value)}
          color="primary"
          size="sm"
          className="text-xs"
        >
          تایید
        </Button>
      </div>
    </div>
  );
}

function MonthDropdown({ state }: { state: CalendarState }) {
  const months = [];
  const formatter = useDateFormatter({
    month: 'long',
    timeZone: state.timeZone,
  });

  // Format the name of each month in the year according to the
  // current locale and calendar system. Note that in some calendar
  // systems, such as the Hebrew, the number of months may differ
  // between years.
  const numMonths = state.focusedDate.calendar.getMonthsInYear(
    state.focusedDate
  );
  for (let i = 1; i <= numMonths; i++) {
    const date = state.focusedDate.set({ month: i });
    months.push(formatter.format(date.toDate(state.timeZone)));
  }

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    const date = state.focusedDate.set({ month: value });
    state.setFocusedDate(date);
  };

  return (
    <Select
      size="sm"
      aria-label="Month"
      onChange={onChange}
      value={state.focusedDate.month}
      options={months.map((month, i) => ({ value: i + 1, label: month }))}
    />
  );
}

function YearDropdown({ state }: { state: CalendarState }) {
  const years: { value: CalendarDate; formatted: string }[] = [];
  const formatter = useDateFormatter({
    year: 'numeric',
    timeZone: state.timeZone,
  });

  // Format 100 years on each side of the current year according
  // to the current locale and calendar system.
  for (let i = -100; i <= 0; i++) {
    const date = state.focusedDate.add({ years: i });
    years.push({
      value: date,
      formatted: formatter.format(date.toDate(state.timeZone)),
    });
  }

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const index = Number(e.target.value);
    const date = years[index].value;
    state.setFocusedDate(date);
  };

  return (
    <Select
      aria-label="Year"
      onChange={onChange}
      value={100}
      size="sm"
      options={years.map((year, i) => ({
        value: i,
        label: year.formatted,
      }))}
    />
  );
}

export default Calendar;
