import { Popover, PopoverOpenChangeDetails } from '@ark-ui/react';
import Button, { ButtonProps } from '../Button';
import { PropsWithChildren, useEffect, useState } from 'react';

export interface ConfirmPopoverProps extends PropsWithChildren {
  confirmButtonProps?: Omit<ButtonProps, 'onClick'>;
  abortButtonProps?: Omit<ButtonProps, 'onClick'>;
  confirmLabel?: string;
  abortLabel?: string;
  description?: string;
  title?: string;
  onConfirm?: () => void | Promise<unknown>;
  onAbort?: () => void;
  open?: boolean;
  onOpenChange?: (details: PopoverOpenChangeDetails) => void;
}
const ConfirmPopover = ({
  confirmButtonProps,
  abortButtonProps,
  children,
  confirmLabel = 'تایید',
  abortLabel = 'انصراف',
  description,
  title = 'آیا مطمئن هستید؟',
  onAbort,
  onConfirm,
  onOpenChange,
  open,
}: ConfirmPopoverProps) => {
  const [innerOpen, setInnerOpen] = useState<boolean>(false);

  useEffect(() => {
    if (open !== undefined && open !== innerOpen) setInnerOpen(open);
  }, [innerOpen, open]);

  useEffect(() => {
    if (onOpenChange) onOpenChange({ open: innerOpen });
  }, [innerOpen, onOpenChange]);

  const [loading, setLoading] = useState(false);

  return (
    <Popover.Root
      positioning={{
        offset: { crossAxis: -20, mainAxis: -20 },
      }}
      open={innerOpen}
      onOpenChange={({ open }) => setInnerOpen(open)}
    >
      <Popover.Trigger asChild>{children}</Popover.Trigger>
      <Popover.Positioner>
        <Popover.Content className="shadow-md rounded-md bg-base-100 p-2">
          <Popover.Title className="px-2">{title}</Popover.Title>
          {description && (
            <Popover.Description className="w-52 text-base-content text-pretty px-2">
              {description}
            </Popover.Description>
          )}
          <div className="mt-2 flex justify-end gap-2">
            <Popover.CloseTrigger>
              <Button onClick={onAbort} size="xs" {...abortButtonProps}>
                {abortLabel}
              </Button>
            </Popover.CloseTrigger>
            <Button
              onClick={() => {
                const confirm = onConfirm?.()
                  ?.then(() => {
                    setInnerOpen(false);
                  })
                  .finally(() => setLoading(false));
                if (confirm?.then) setLoading(true);
              }}
              loading={loading}
              disabled={loading}
              {...confirmButtonProps}
              color="error"
              size="xs"
            >
              {confirmLabel}
            </Button>
          </div>
          <Popover.Arrow>
            <Popover.ArrowTip />
          </Popover.Arrow>
        </Popover.Content>
      </Popover.Positioner>
    </Popover.Root>
  );
};

export default ConfirmPopover;
