import { api } from '@/config/apiCliant';
import { queryClient } from '@/main';
import { getAuthInfoQueryOptions } from '@/modules/auth/services/use-get-auth-info.query';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const verifyAuthMobile = (verificationCode: string) =>
  api.patch<{ accessToken: string }>('/auth/profile/mobile', {
    verificationCode,
  });

const useVerifyAuthMobileMutation = () => {
  return useMutation({
    mutationKey: ['verify-auth-mobile'],
    mutationFn: (verificationCode: string) =>
      verifyAuthMobile(verificationCode),
    onSuccess: (res) => {
      if (res.data.accessToken) Cookies.set('token', res.data.accessToken);
      toast.success('موبایل با موفقیت ویرایش شد');
      queryClient.refetchQueries(getAuthInfoQueryOptions());
    },
    onError: (
      e: AxiosError<{ status: 'code_expired' | 'wrong_code' | 'conflict' }>
    ) => {
      if (e.response?.data.status === 'code_expired')
        toast.error('کد منقضی شده است');
      else if (e.response?.data.status === 'wrong_code')
        toast.error('کد اشتباه است');
      else if (e.response?.data.status === 'conflict')
        toast.error('شماره موبایل تکراری است');
      else toast.error('ویرایش موبایل با مشکل مواجه شد');
    },
  });
};

export default useVerifyAuthMobileMutation;
