import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface CreatePayslipsByExcelPayload {
  file: File;
  sms: boolean;
  updatePayslip: boolean;
  updateSms?: boolean;
  month: number;
  year: number;
}

const createPayslipsByExcel = (payload: CreatePayslipsByExcelPayload) => {
  const formData = new FormData();

  formData.append('file', payload.file);
  formData.append('year', payload.year.toString());
  formData.append('month', payload.month.toString());
  formData.append('sms', payload.sms.toString());
  formData.append('updatePayslip', payload.updatePayslip.toString());
  if (payload.updateSms) {
    formData.append('updateSms', payload.updateSms.toString());
  }

  return api.post('/payslips/by-excel', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const useCreatePayslipsByExcelMutation = () => {
  const [rowsErrors, setRowsErrors] = useState<
    Array<{ index: number; fields: Array<{ field: string; message: string }> }>
  >([]);

  return {
    ...useMutation({
      onMutate: () => {
        setRowsErrors([]);
      },
      mutationFn: createPayslipsByExcel,
      mutationKey: ['create-payslips-by-excel'],
      onSuccess: () => {
        toast.success('فیش های حقوق با موفقیت ثبت شد');
      },
      onError: (
        e: AxiosError<{
          message?: string;
          status?: 'validation_error' | string;
          errors?: Array<{
            index: number;
            fields: Array<{ field: string; message: string }>;
          }>;
        }>
      ) => {
        const message = e.response?.data.message || 'خطا در ثبت فیش های حقوق';
        if (e.status === 422) {
          if (e.response?.data.status === 'validation_error') {
            if (e.response?.data.errors) {
              setRowsErrors(e.response?.data.errors);
            }
          }
        }
        toast.error(message);
      },
    }),
    rowsErrors,
  };
};

export default useCreatePayslipsByExcelMutation;
