import { api } from '@/config/apiCliant';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

interface User {
  id: number;
  mobile: string;
  email: null;
  displayName: null;
  registerStatus: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  userOrganizations: UserOrganization[];
}

interface UserOrganization {
  id: number;
  userId: number;
  organizationId: number;
  role: string;
  createdAt: string;
  updatedAt: string;
  organization: Organization;
}

interface Organization {
  id: number;
  name: string;
  database: string;
  logo: null | string;
  createdAt: string;
  updatedAt: string;
}

const getAuthInfo = () => api.get<User>('/auth/profile');

export const getAuthInfoQueryOptions = () => {
  const token = Cookies.get('token');
  return queryOptions<AxiosResponse<User>, AxiosError, User>({
    queryKey: ['authInfo', token],
    queryFn: () => getAuthInfo(),
    select: (res) => res.data,
  });
};
const useGetAuthInfoQuery = () => {
  const query = useQuery(getAuthInfoQueryOptions());

  useEffect(() => {
    if (
      query.isError &&
      (query.error?.status === 401 ||
        query.error?.status === 403 ||
        query.error?.status === 404)
    ) {
      Cookies.remove('token');
      Cookies.remove('org_token');
      window.location.reload();
    }
  }, [query.isError, query.error]);
  return query;
};

export default useGetAuthInfoQuery;
