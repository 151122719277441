import {
  useGetOrganizationSettings,
  useUpdateOrganizationSetting,
} from '@/modules/organization-settings';
import { useEffect, useState } from 'react';
import { Loading, Toggle } from 'react-daisyui';

const PayslipGeneralSettings = () => {
  const { data: settings, isLoading: isSettingsLoading } =
    useGetOrganizationSettings();
  const { mutate: updateSetting } = useUpdateOrganizationSetting();

  const [payrollSettings, setPayrollSettings] = useState<{
    showLogo: boolean;
  }>();

  const [submitPayslipSettings, setSubmitPayslipSettings] = useState<{
    showPreview: boolean;
    sendSms: boolean;
    updatePayslips: boolean;
    sendSmsUpdatePayslips: boolean;
  }>();

  useEffect(() => {
    if (
      settings &&
      (settings.showLogo.value !== payrollSettings?.showLogo ||
        settings.previewMode.value !== submitPayslipSettings?.showPreview ||
        settings.sendPayslipsSms.value !== submitPayslipSettings?.sendSms ||
        settings.updatePayslips.value !==
          submitPayslipSettings.updatePayslips ||
        settings.sendUpdatePayslipsSms.value !==
          submitPayslipSettings.sendSmsUpdatePayslips)
    ) {
      setPayrollSettings({
        showLogo: settings.showLogo.value,
      });
      setSubmitPayslipSettings({
        showPreview: settings.previewMode.value,
        sendSms: settings.sendPayslipsSms.value,
        updatePayslips: settings.updatePayslips.value,
        sendSmsUpdatePayslips: settings.sendUpdatePayslipsSms.value,
      });
    }
  }, [settings]);

  if (isSettingsLoading || !payrollSettings || !submitPayslipSettings)
    return <Loading />;

  const updateSettingHandler = (
    name:
      | 'sendPayslipsSms'
      | 'previewMode'
      | 'sendUpdatePayslipsSms'
      | 'updatePayslips'
      | 'showLogo',
    value: boolean
  ) => {
    if (!settings) return;

    updateSetting({ id: settings[name].id, value: value ? 'true' : 'false' });
  };

  return (
    <>
      <h3 className="mb-2 font-bold text-md">فیش حقوق</h3>
      <div className="grid grid-cols-2 gap-5">
        <label className="form-control col-span-2">
          <div className="label">
            <span className="label-text">نمایش لوگو در فیش حقوق</span>
          </div>
          <Toggle
            size="sm"
            checked={payrollSettings.showLogo}
            onChange={(e) => {
              updateSettingHandler('showLogo', e.target.checked);

              setPayrollSettings({
                ...payrollSettings,
                showLogo: e.target.checked,
              });
            }}
          />
        </label>
      </div>
      {/*TODO: impleent this feature in the future*/}
      {/* <label className="form-control">
        <div className="label">
          <span className="label-text">نمایش امضا در فیش حقوق</span>
        </div>
        <Toggle />
      </label> */}
      <div className="divider "></div>
      <h4 className="mb-2 font-bold text-md">تنظیمات پیش فرض ثبت فیش حقوق</h4>
      <div className="grid grid-cols-2 gap-5">
        <label className="form-control">
          <div className="label">
            <span className="label-text">
              پیش نمایش
              <small className="block max-w-72">
                فیش های حقوق بعد از بارگذاری به صورت پیش نمایش نمایش داده می
                شود، در صورت تایید فیش های حقوق ثبت خواهد شد.
              </small>
            </span>
          </div>
          <Toggle
            size="sm"
            checked={submitPayslipSettings.showPreview}
            onChange={(e) => {
              updateSettingHandler('previewMode', e.target.checked);
              setSubmitPayslipSettings({
                ...submitPayslipSettings,
                showPreview: e.target.checked,
              });
            }}
          />
        </label>
        <label className="form-control">
          <div className="label">
            <span className="label-text">
              ارسال پیامک بعد از ثبت فیش حقوق
              <small className="block max-w-72">
                بعد از ثبت فیش حقوق برای کاربران مرتبط پیامک ثبت فیش حقوق ارسال
                خواهد شد
              </small>
            </span>
          </div>
          <Toggle
            size="sm"
            checked={submitPayslipSettings.sendSms}
            onChange={(e) => {
              updateSettingHandler('sendPayslipsSms', e.target.checked);

              setSubmitPayslipSettings({
                ...submitPayslipSettings,
                sendSms: e.target.checked,
              });
            }}
          />
        </label>
        <label className="form-control">
          <div className="label">
            <span className="label-text">
              به روزرسانی فیش حقوق
              <small className="block max-w-72">
                در صورتی که فیش حقوق کاربری برای (ماه و سال) انتخابی ثبت شده
                باشد، اطلاعات جدید جایگزین خواهد شد.
              </small>
            </span>
          </div>
          <Toggle
            size="sm"
            checked={submitPayslipSettings.updatePayslips}
            onChange={(e) => {
              updateSettingHandler('updatePayslips', e.target.checked);
              setSubmitPayslipSettings({
                ...submitPayslipSettings,
                updatePayslips: e.target.checked,
              });
            }}
          />
        </label>
        <label className="form-control">
          <div className="label">
            <span className="label-text">
              ارسال پیامک بعد از به روز رسانی فیش حقوق
              <small className="block max-w-72">
                بعد از ثبت فیش حقوق برای کاربران مرتبط پیامک ثبت فیش حقوق ارسال
                خواهد شد
              </small>
            </span>
          </div>
          <Toggle
            size="sm"
            checked={submitPayslipSettings.sendSmsUpdatePayslips}
            onChange={(e) => {
              updateSettingHandler('sendUpdatePayslipsSms', e.target.checked);
              setSubmitPayslipSettings({
                ...submitPayslipSettings,
                sendSmsUpdatePayslips: e.target.checked,
              });
            }}
            disabled={!submitPayslipSettings.updatePayslips}
          />
        </label>
      </div>
    </>
  );
};

export default PayslipGeneralSettings;
