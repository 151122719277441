import { Breadcrumbs, Card } from 'react-daisyui';
import { OrganizationSettingsFeat } from '../features';

const OrganizationSettingsPage = () => {
  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item>تنظیمات</Breadcrumbs.Item>
      </Breadcrumbs>
      <Card className="mt-5">
        <Card.Body>
          <Card.Title>تنظیمات سازمان</Card.Title>
          <div className="mt-5">
            <OrganizationSettingsFeat />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default OrganizationSettingsPage;
