import { useRef } from 'react';
import { AriaButtonOptions, useButton } from '@react-aria/button';

function CalendarButton(
  props: AriaButtonOptions<'button'> & { children: React.ReactNode }
) {
  const ref = useRef<HTMLButtonElement | null>(null);
  const { buttonProps } = useButton(props, ref);
  return (
    <button className="btn btn-circle btn-ghost" {...buttonProps} ref={ref}>
      {props.children}
    </button>
  );
}

export default CalendarButton;
