import { Button } from '@/components';
import { Toggle, Tooltip } from 'react-daisyui';
import { BsExclamationCircle } from 'react-icons/bs';
import { MdDangerous } from 'react-icons/md';

const OrganizationSettingsFeat = () => {
  return (
    <div>
      <form>
        <h2 className="font-bold text-md mt-5 mb-5">تنظیمات عمومی</h2>
        <div className="grid grid-cols-4 gap-5">
          <div className="form-control">
            <label className="label">
              <Tooltip message="در صورت غیر فعال سازی سازمان، دسترسی سازمان برای کاربران به غیر از صاحب سازمان بسته می شود، و سازمان نمایش داده نمی شود">
                <BsExclamationCircle />
              </Tooltip>
              <span className="label-text">غیر فعال کردن سازمان</span>

              <Toggle />
            </label>
          </div>
        </div>
      </form>
      <div className="divider"></div>
      <div className="mb-5">
        <h2 className="text-md font-bold text-error">حذف سازمان</h2>

        <p className="text-xs text-gray-500">
          در صورت حذف سازمان، تمام اطلاعات سازمان به طور کامل حذف می شود.
        </p>
        <p className="text-xs text-gray-500">
          سازمان حذف شده غیر قابل بازیابی می باشد
        </p>

        <Button color="error" className="mt-5">
          حذف سازمان <MdDangerous size={20} />{' '}
        </Button>
      </div>
    </div>
  );
};

export default OrganizationSettingsFeat;
