import { api } from '@/config/apiCliant';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { PayslipField } from '../types';

const getPayslipEarningFields = () =>
  api.get<PayslipField[]>('/payslip-earnings/fields');

export const getPayslipEarningFieldsOptions = () => {
  return queryOptions({
    queryKey: ['payslipEarningFields'],
    queryFn: () => getPayslipEarningFields(),
    select: (res) => res.data,
  });
};

const useGetPayslipEarningFieldsQuery = () => {
  return useQuery(getPayslipEarningFieldsOptions());
};

export default useGetPayslipEarningFieldsQuery;
