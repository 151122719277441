import axios, { AxiosError } from 'axios';
import { baseURL } from './baseUrl';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = Cookies.get('token');
  const orgToken = Cookies.get('org_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (orgToken) {
    config.headers['org_token'] = orgToken;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.code === 'ERR_NETWORK') {
      toast.error('لطفا اتصال اینترنت خود را بررسی کنید.', {
        toastId: 'network-error',
        autoClose: 10000,
      });
    }
    if (error.status === 500) {
      toast.error('خطای سرور', {
        toastId: 'server-error',
        autoClose: 10000,
      });
    }
    if (error.status === 429) {
      toast.error(
        'تعداد دفعات ورود بیش از حد مجاز است لطفا بعدا دوباره تلاش کنید'
      );
    }
    return Promise.reject(error);
  }
);
