import { api } from '@/config/apiCliant';
import { User } from '@/modules/user/services/use-get-users.query';
import { queryOptions, useQuery } from '@tanstack/react-query';

const getOrgAuthUserInfo = () => api.get<User>('/org-auth/profile/user');

export const getOrganizationUserProfileOptions = () =>
  queryOptions({
    queryKey: ['orgAuthUserInfo'],
    queryFn: () => getOrgAuthUserInfo(),
    select: (res) => res.data,
  });

const useGetOrganizationUserProfileQuery = () => {
  return useQuery(getOrganizationUserProfileOptions());
};

export default useGetOrganizationUserProfileQuery;
