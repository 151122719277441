import { api } from '@/config/apiCliant';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

const verifyResetPasswordOtp = (payload: { code: string; mobile: string }) =>
  api.post('/auth/verify-reset-password', payload);

const useVerifyResetPasswordOtpMutation = () => {
  const { mutate, isPending, error } = useMutation({
    mutationFn: (payload: { code: string; mobile: string }) =>
      verifyResetPasswordOtp(payload),
    onError: (err: AxiosError) => {
      if (err.status === 422) {
        toast.error('کد وارد شده اشتباه است');
      }
    },
  });

  return { mutate, isPending, error };
};

export default useVerifyResetPasswordOtpMutation;
