import { createColumnHelper } from '@tanstack/react-table';
import { User } from '../../services/use-get-users.query';
// import { Checkbox } from 'react-daisyui';
import { Link } from '@tanstack/react-router';
import { BsEye } from 'react-icons/bs';

const useUserColumns = () => {
  const columnHelper = createColumnHelper<User>();

  const columns = [
    // columnHelper.accessor((row) => row.id, {
    //   id: 'checkbox',
    //   cell: () => <Checkbox size="sm" />,
    //   header: () => <Checkbox size="sm" />,
    //   footer: (info) => info.column.id,
    // }),
    columnHelper.accessor('personnelCode', {
      id: 'personnelCode',
      cell: (info) => (
        <Link
          to="/dashboard/users/$user"
          className="link link-hover link-primary"
          params={{ user: info.row.original.id.toString() }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>کد پرسنلی</span>,
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor('firstName', {
      header: () => 'نام',
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('lastName', {
      header: () => <span>نام خانوادگی</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('fatherName', {
      header: () => <span>نام پدر</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.nationalCode, {
      id: 'nationalCode',
      cell: (info) => (
        <Link
          to="/dashboard/users/$user"
          className="link link-hover link-primary"
          params={{ user: info.row.original.id.toString() }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>کد ملی</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('mobile', {
      header: 'موبایل',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.id, {
      id: 'actions',
      cell: (info) => (
        <div className="flex gap-2">
          <Link
            to="/dashboard/users/$user"
            params={{ user: info.row.original.id.toString() }}
            className="btn btn-ghost btn-sm btn-square"
          >
            <BsEye />
          </Link>
        </div>
      ),
      header: () => '',
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
  ];

  return columns;
};

export default useUserColumns;
