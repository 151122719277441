import { api } from '@/config/apiCliant';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export interface GetUserParams {
  page?: number;
  limit?: number;
  search?: string;
  sort?: string;
  sortBy?: string;
}

export interface PaginationMeta {
  page: number;
  limit: number;
  total: number;
}

export interface PaginationResponse<T> {
  data: T[];
  meta: PaginationMeta;
}

export interface User {
  id: number;
  pId: number;
  firstName: null | string;
  lastName: null | string;
  fatherName: null | string;
  birthDate: null | string;
  nationalCode: string;
  personnelCode: null | string;
  mobile: string;
  email: null | string;
  phone: null | string;
  IBAN: null | string;
  bankName: null | string;
  bankBranch: null | string;
  bankAccount: null | string;
  avatar: null | string;
  createdAt: string;
  updatedAt: string;
  insuranceNumber: null | string;
  gender: null | 'male' | 'female';
  childrenCount: null | number;
  maritalStatus: null | 'single' | 'married';
  role: null | string;
}

const getUsers = (params?: GetUserParams) =>
  api.get<PaginationResponse<User>>('/organization-users', {
    params: {
      ...params,
      order: params?.sort,
      orderBy: params?.sortBy,
    },
  });

const useGetUsersQuery = (params?: GetUserParams) => {
  return useQuery({
    queryKey: ['users', params],
    select: (res) => res.data,
    queryFn: () => getUsers(params),
    placeholderData: keepPreviousData,
  });
};

export default useGetUsersQuery;
